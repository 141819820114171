@charset "UTF-8";
/*
  This boilerplate is based in ITCSS and SMACSS
*/
.relative {
  position: relative;
}

.inline-block {
  display: inline-block;
}

.centrador {
  display: table-cell;
  vertical-align: middle;
}

@font-face {
  src: url("../fonts/Roboto/Roboto-Light.ttf");
  font-family: Roboto-Light;
}

@font-face {
  src: url("../fonts/Roboto/Roboto-Regular.ttf");
  font-family: Roboto-Regular;
}

@font-face {
  src: url("../fonts/Roboto/Roboto-Black.ttf");
  font-family: Roboto-Black;
}

@font-face {
  src: url("../fonts/Roboto/Roboto-Bold.ttf");
  font-family: Roboto-Bold;
}

@font-face {
  src: url("../fonts/Roboto/Roboto-Medium.ttf");
  font-family: Roboto-Medium;
}

@font-face {
  src: url("../fonts/Roboto/Roboto-Thin.ttf");
  font-family: Roboto-Thin;
}

/*
*,
*::before,
*:after {
  box-sizing: border-box;
}

body {
  margin : 0;
  font-family : $font-family;
}
*/
* {
  line-height: 1.2;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  height: 100%;
}

@media (min-width: 768px) {
  body {
    height: initial;
  }
}

html {
  min-height: 480px;
}

@media (min-width: 768px) {
  html {
    min-height: 1024px;
  }
}

@media (min-width: 992px) {
  html {
    min-height: 768px;
  }
}

@media (min-width: 1200px) {
  html {
    min-height: 720px;
  }
}

@media (min-width: 2400px) {
  html {
    min-height: 1440px;
  }
}

@media (min-width: 2400px) {
  .container {
    width: 2370px;
  }
}

.boton {
  width: 148px;
  height: 31px;
  background-color: transparent;
  font-size: 12.67px;
  line-height: 15px;
  font-family: Gotham-Light;
  color: #ffffff;
  display: block;
  margin: 5px auto 0;
  border: 1px solid #ffffff;
  border-radius: 0;
}

@media (min-width: 360px) {
  .boton {
    width: 166px;
    height: 34px;
    font-size: 14.27px;
    line-height: 17.13px;
    margin: 15px auto 0px;
  }
}

@media (min-width: 768px) {
  .boton {
    width: 148px;
    height: 31px;
    font-size: 12.67px;
    line-height: 15px;
    margin-top: 25px;
  }
}

@media (min-width: 1200px) {
  .boton {
    width: 185px;
    height: 38px;
    font-size: 15.84px;
    line-height: 19px;
    margin-top: 50px;
  }
}

@media (min-width: 2400px) {
  .boton {
    width: 370px;
    height: 76px;
    font-size: 31.68px;
    line-height: 38px;
    margin-top: 100px;
  }
}

.boton:hover {
  background-color: rgba(140, 193, 72, 0.7);
  color: #ffffff;
  border: none;
}

.boton_home {
  width: 148px;
  height: 31px;
  background-color: #efefef;
  font-size: 12.67px;
  line-height: 15px;
  font-family: Gotham-Light;
  color: #ffffff;
  display: block;
  margin: 5px auto 0;
  border: 1px solid #ffffff;
  border-radius: 0;
  background-color: rgba(140, 193, 72, 0.7);
  color: #ffffff;
  border: none;
}

@media (min-width: 360px) {
  .boton_home {
    width: 166px;
    height: 34px;
    font-size: 14.27px;
    line-height: 17.13px;
    margin: 15px auto 0px;
  }
}

@media (min-width: 768px) {
  .boton_home {
    width: 148px;
    height: 31px;
    font-size: 12.67px;
    line-height: 15px;
    margin-top: 25px;
  }
}

@media (min-width: 1200px) {
  .boton_home {
    width: 185px;
    height: 38px;
    font-size: 15.84px;
    line-height: 19px;
    margin-top: 50px;
  }
}

@media (min-width: 2400px) {
  .boton_home {
    width: 370px;
    height: 76px;
    font-size: 31.68px;
    line-height: 38px;
    margin-top: 100px;
  }
}

.boton_home:hover, .boton_home.active {
  background-color: rgba(140, 193, 72, 0.7);
  color: #ffffff;
  border: none;
}

.carousel-servicios {
  width: 290px;
  margin: 0 auto;
}

@media (min-width: 360px) {
  .carousel-servicios {
    width: 330px;
  }
}

@media (min-width: 768px) {
  .carousel-servicios {
    width: 645px;
  }
}

@media (min-width: 992px) {
  .carousel-servicios {
    width: 720px;
  }
}

@media (min-width: 1200px) {
  .carousel-servicios {
    width: 900px;
  }
}

@media (min-width: 2400px) {
  .carousel-servicios {
    width: 1800px;
  }
}

@media (min-width: 768px) {
  .carousel-servicios .carousel-inner {
    width: 584px;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .carousel-servicios .carousel-inner {
    width: 658px;
  }
}

@media (min-width: 1200px) {
  .carousel-servicios .carousel-inner {
    width: 820px;
  }
}

@media (min-width: 2400px) {
  .carousel-servicios .carousel-inner {
    width: 1640px;
  }
}

.carousel-servicios .servicio {
  width: 165px;
  height: 165px;
  margin: 23px auto 0;
  cursor: pointer;
  position: relative;
}

@media (min-width: 360px) {
  .carousel-servicios .servicio {
    width: 194px;
    height: 194px;
    margin: 22px auto 0;
  }
}

@media (min-width: 768px) {
  .carousel-servicios .servicio {
    width: 165px;
    height: 165px;
    margin: 40px auto 0;
  }
}

@media (min-width: 992px) {
  .carousel-servicios .servicio {
    width: 185px;
    height: 185px;
    margin: 50px auto 0;
  }
}

@media (min-width: 1200px) {
  .carousel-servicios .servicio {
    width: 232px;
    height: 232px;
    margin: 65px auto 0;
  }
}

@media (min-width: 2400px) {
  .carousel-servicios .servicio {
    width: 464px;
    height: 464px;
    margin: 130px auto 0;
  }
}

.carousel-servicios .servicio::before, .carousel-servicios .servicio::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

.carousel-servicios .servicio::before {
  width: 165px;
  height: 165px;
  border: 9px solid #efefef;
}

@media (min-width: 360px) {
  .carousel-servicios .servicio::before {
    width: 194px;
    height: 194px;
    border: 11px solid #efefef;
  }
}

@media (min-width: 768px) {
  .carousel-servicios .servicio::before {
    width: 165px;
    height: 165px;
    border: 9px solid #efefef;
  }
}

@media (min-width: 992px) {
  .carousel-servicios .servicio::before {
    width: 185px;
    height: 185px;
    border: 10px solid #efefef;
  }
}

@media (min-width: 1200px) {
  .carousel-servicios .servicio::before {
    width: 232px;
    height: 232px;
    border: 13px solid #efefef;
  }
}

@media (min-width: 2400px) {
  .carousel-servicios .servicio::before {
    width: 464px;
    height: 464px;
    border: 26px solid #efefef;
  }
}

.carousel-servicios .servicio::after {
  width: 100px;
  height: 100px;
  background-color: #efefef;
}

@media (min-width: 360px) {
  .carousel-servicios .servicio::after {
    width: 116px;
    height: 116px;
  }
}

@media (min-width: 768px) {
  .carousel-servicios .servicio::after {
    width: 100px;
    height: 100px;
  }
}

@media (min-width: 992px) {
  .carousel-servicios .servicio::after {
    width: 111px;
    height: 111px;
  }
}

@media (min-width: 1200px) {
  .carousel-servicios .servicio::after {
    width: 140px;
    height: 140px;
  }
}

@media (min-width: 2400px) {
  .carousel-servicios .servicio::after {
    width: 280px;
    height: 280px;
  }
}

.carousel-servicios .img {
  width: 161px;
  height: 161px;
  position: relative;
  top: 2px;
  left: 2px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

@media (min-width: 360px) {
  .carousel-servicios .img {
    width: 190px;
    height: 190px;
    top: 2px;
    left: 2px;
  }
}

@media (min-width: 768px) {
  .carousel-servicios .img {
    width: 161px;
    height: 161px;
    top: 2px;
    left: 2px;
  }
}

@media (min-width: 992px) {
  .carousel-servicios .img {
    width: 179px;
    height: 179px;
    top: 3px;
    left: 3px;
  }
}

@media (min-width: 1200px) {
  .carousel-servicios .img {
    width: 224px;
    height: 224px;
    top: 4px;
    left: 4px;
  }
}

@media (min-width: 2400px) {
  .carousel-servicios .img {
    width: 448px;
    height: 448px;
    top: 8px;
    left: 8px;
  }
}

.carousel-servicios .articulo {
  font-size: 13.52px;
  font-family: "Roboto-Regular";
  color: black;
  letter-spacing: 0.053em;
  text-align: center;
  margin: 36px 0 22px;
  height: 40.56px;
}

@media (min-width: 360px) {
  .carousel-servicios .articulo {
    font-size: 14.55px;
    margin: 39px 0 24px;
    height: 43.65px;
  }
}

@media (min-width: 768px) {
  .carousel-servicios .articulo {
    font-size: 13.52px;
    margin: 36px 0 22px;
    height: 40.56px;
  }
}

@media (min-width: 992px) {
  .carousel-servicios .articulo {
    font-size: 15.14px;
    margin: 40px 0 26px;
    height: 45.42px;
  }
}

@media (min-width: 1200px) {
  .carousel-servicios .articulo {
    font-size: 19px;
    margin: 48px 0 35px;
    height: 57px;
  }
}

@media (min-width: 2400px) {
  .carousel-servicios .articulo {
    font-size: 38px;
    margin: 96px 0 70px;
    height: 114px;
  }
}

.carousel-servicios .articulo .extra {
  color: #666666;
}

.carousel-servicios a {
  text-decoration: none;
}

.carousel-servicios button {
  background: #efefef;
  background: #393d42;
  color: #ffffff;
  font-family: "Roboto-Regular";
  font-size: 15.28px;
  line-height: 1.2;
  border: none;
  box-shadow: none;
  width: 97px;
  height: 24px;
  display: block;
  margin: 0 auto 44px;
}

@media (min-width: 360px) {
  .carousel-servicios button {
    width: 105px;
    height: 26px;
    font-size: 16.46px;
    margin: 0 auto 62px;
  }
}

@media (min-width: 768px) {
  .carousel-servicios button {
    width: 97px;
    height: 24px;
    font-size: 15.28px;
    margin: 0 auto 40px;
  }
}

@media (min-width: 992px) {
  .carousel-servicios button {
    width: 108.83px;
    height: 27px;
    font-size: 17.11px;
    margin: 0 auto 50px;
  }
}

@media (min-width: 1200px) {
  .carousel-servicios button {
    width: 136px;
    height: 34px;
    font-size: 21.4px;
    margin: 0 auto 65px;
  }
}

@media (min-width: 2400px) {
  .carousel-servicios button {
    width: 272px;
    height: 68px;
    font-size: 42.8px;
    margin: 0 auto 130px;
  }
}

.carousel-servicios button:hover {
  background: #393d42;
}

.carousel-servicios .carousel-control {
  width: 33px;
  height: 33px;
  top: 89px;
  background: none;
  text-shadow: none;
  color: red;
  opacity: 1;
  background-size: 100%;
}

@media (min-width: 360px) {
  .carousel-servicios .carousel-control {
    width: 40px;
    height: 40px;
    top: 99px;
  }
}

@media (min-width: 768px) {
  .carousel-servicios .carousel-control {
    top: 105px;
    width: 15px;
    height: 32px;
    background-repeat: no-repeat;
  }
}

@media (min-width: 992px) {
  .carousel-servicios .carousel-control {
    top: 123px;
    width: 16px;
    height: 35px;
  }
}

@media (min-width: 1200px) {
  .carousel-servicios .carousel-control {
    top: 155px;
    width: 20px;
    height: 43px;
  }
}

@media (min-width: 2400px) {
  .carousel-servicios .carousel-control {
    top: 310px;
    width: 40px;
    height: 86px;
  }
}

.carousel-servicios .carousel-control.left {
  background-image: url(/assets/img/carousel/prev.svg);
}

@media (min-width: 768px) {
  .carousel-servicios .carousel-control.left {
    background-image: url(/assets/img/carousel/prev-md.svg);
  }
  .carousel-servicios .carousel-control.left:hover {
    background-image: url(/assets/img/carousel/prev-md-active.svg);
  }
}

.carousel-servicios .carousel-control.right {
  background-image: url(/assets/img/carousel/next.svg);
}

@media (min-width: 768px) {
  .carousel-servicios .carousel-control.right {
    background-image: url(/assets/img/carousel/next-md.svg);
  }
  .carousel-servicios .carousel-control.right:hover {
    background-image: url(/assets/img/carousel/next-md-active.svg);
  }
}

.carousel-banners {
  /*
	.carousel-inner{
		@media (min-width: 768px) {
			width: 584px; 
			margin: 0 auto;
		}
		@media (min-width: 992px) {
			width: 658px; 
		}
		@media (min-width: 1200px) {
			width: 820px; 
		}
		@media (min-width: 2400px) {
			width: 1640px; 
		}
	}

	.item{

	}
	
	
	.servicio{
		//contenedor de la imagen y referencia para el diseño del fondo
		width: 165px;
		height: 165px;
		margin: 23px auto 0;
		cursor: pointer;

		position: relative;
		@media (min-width: 360px){
			width: 194px;
			height: 194px;
			margin: 22px auto 0;
		}
		@media (min-width: 768px){
			width: 165px;
			height: 165px;
			margin: 40px auto 0;
		}
		@media (min-width: 992px) {
			width: 185px;
			height: 185px;
			margin: 50px auto 0;
		}
		@media (min-width: 1200px) {
			width: 232px;
			height: 232px;
			margin: 65px auto 0;
		}
		@media (min-width: 2400px) {
			width: 464px;
			height: 464px;
			margin: 130px auto 0;
		}

		&::before,
		&::after{
			//reglas heredadas entre los elementos del fondo
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			z-index: -1;
		}
		&::before{
			width: 165px;
			height: 165px;
				
			border: 9px solid #EFEFEF;

			@media (min-width: 360px){
				width: 194px;
				height: 194px;
					
				border: 11px solid #EFEFEF;
			}
			@media (min-width: 768px){
				width: 165px;
				height: 165px;
					
				border: 9px solid #EFEFEF;
			}
			@media (min-width: 992px) {
				width: 185px;
				height: 185px;
					
				border: 10px solid #EFEFEF;
			}
			@media (min-width: 1200px) {
				width: 232px;
				height: 232px;
					
				border: 13px solid #EFEFEF;
			}
			@media (min-width: 2400px) {
				width: 464px;
				height: 464px;
					
				border: 26px solid #EFEFEF;
			}
		}
		&::after{			
			width: 100px;
			height: 100px;

			background-color: #EFEFEF; 
			@media (min-width: 360px){
				width: 116px;
				height: 116px;
			}
			@media (min-width: 768px){
				width: 100px;
				height: 100px;
			}
			@media (min-width: 992px) {
				width: 111px;
				height: 111px;
			}
			@media (min-width: 1200px) {
				width: 140px;
				height: 140px;
			}
			@media (min-width: 2400px) {
				width: 280px;
				height: 280px;
			}
		}
	}
	.img{
		//imagen centrada en el contenedor
		width: 161px;	
		height: 161px;
		position: relative;
		top: 2px;
		left: 2px;

		background-size: contain;
		background-position: 50% 50%; 
		background-repeat: no-repeat; 
		@media (min-width: 360px){
			width: 190px;	
			height: 190px;
			top: 2px;
			left: 2px;
		}
		@media (min-width: 768px){
			width: 161px;	
			height: 161px;
			top: 2px;
			left: 2px;
		}
		@media (min-width: 992px) {
			width: 179px;	
			height: 179px;
			top: 3px;
			left: 3px;
		}
		@media (min-width: 1200px) {
			width: 224px;	
			height: 224px;
			top: 4px;
			left: 4px;
		}
		@media (min-width: 2400px) {
			width: 448px;	
			height: 448px;
			top: 8px;
			left: 8px;
		}
	
	}
	.articulo{
		font-size: 13.52px;
		font-family: $font-shree-devanagari-bold-italic;
		color: black;
		letter-spacing: 0.053em;
		text-align: center;
		margin: 36px 0 22px;

		@media (min-width: 360px){
			font-size: 14.55px;
			margin: 39px 0 24px;
		}
		@media (min-width: 768px){
			font-size: 13.52px;
			margin: 36px 0 22px;
		}
		@media (min-width: 992px) {
			font-size: 15.14px;
			margin: 40px 0 26px;
		}
		@media (min-width: 1200px) {
			font-size: 19px;
			margin: 48px 0 35px;
		}
		@media (min-width: 2400px) {
			font-size: 38px;
			margin: 96px 0 70px;
		}

		.extra{
			color: #666666;
		}
	}

	a{
		text-decoration: none;
	}

	button{
		background: #EFEFEF;
		color: #FFFFFF;
		font-family: $font-helvetica-neue-medium;
		font-size: 15.28px;
		line-height: 1.2;
		border: none;
		box-shadow: none;
		width: 97px;
		height: 24px;
		display: block;
		margin: 0 auto 44px;

		@media (min-width: 360px){
			width: 105px;
			height: 26px;
			font-size: 16.46px;
			margin: 0 auto 62px;
		}
		@media (min-width: 768px){
			width: 97px;
			height: 24px;
			font-size: 15.28px;
			margin: 0 auto 40px;
		}
		@media (min-width: 992px) {
			width: 108.83px;
			height: 27px;
			font-size: 17.11px;
			margin: 0 auto 50px;
		}
		@media (min-width: 1200px) {
			width: 136px;
			height: 34px;
			font-size: 21.4px;
			margin: 0 auto 65px;
		}
		@media (min-width: 2400px) {
			width: 272px;
			height: 68px;
			font-size: 42.8px;
			margin: 0 auto 130px;
		}

		&:hover{
			background: $second-color;
		}
	}
	*/
}

.carousel-banners img {
  width: 100%;
}

.carousel-banners .item a {
  width: 268px;
  height: 43px;
  background: #393d42;
  font-family: "Roboto-Regular";
  font-size: 19px;
  color: #fff;
  display: block;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  border: 1px solid #393d42;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6px;
  text-decoration: none;
}

@media (min-width: 768px) {
  .carousel-banners .item a {
    width: 208px;
    height: 33px;
    font-size: 15px;
    bottom: 14%;
    right: -50%;
  }
}

@media (min-width: 1200px) {
  .carousel-banners .item a {
    width: 268px;
    height: 43px;
    font-size: 17px;
  }
}

@media (min-width: 2400px) {
  .carousel-banners .item a {
    width: 536px;
    height: 86px;
    font-size: 34px;
    border: 2px solid #393d42;
    padding-top: 12px;
  }
}

.carousel-banners .item a:hover {
  background: transparent;
}

.carousel-banners .carousel-control {
  width: 6px;
  height: 10px;
  background: none;
  text-shadow: none;
  color: red;
  opacity: 1;
  background-size: 100%;
  top: 50%;
}

@media (min-width: 360px) {
  .carousel-banners .carousel-control {
    width: 15px;
    height: 32px;
  }
}

@media (min-width: 768px) {
  .carousel-banners .carousel-control {
    width: 15px;
    height: 32px;
    background-repeat: no-repeat;
  }
}

@media (min-width: 992px) {
  .carousel-banners .carousel-control {
    width: 16px;
    height: 35px;
  }
}

@media (min-width: 1200px) {
  .carousel-banners .carousel-control {
    width: 20px;
    height: 43px;
  }
}

@media (min-width: 2400px) {
  .carousel-banners .carousel-control {
    width: 40px;
    height: 86px;
  }
}

.carousel-banners .carousel-control.left {
  opacity: 0;
  background-image: url(/assets/img/carousel/prev-md.svg);
}

@media (min-width: 768px) {
  .carousel-banners .carousel-control.left {
    opacity: 1;
    left: 20px;
  }
}

.carousel-banners .carousel-control.left:hover {
  background-image: url(/assets/img/carousel/prev-md-active.svg);
}

.carousel-banners .carousel-control.right {
  opacity: 0;
  background-image: url(/assets/img/carousel/next-md.svg);
}

@media (min-width: 768px) {
  .carousel-banners .carousel-control.right {
    opacity: 1;
    right: 20px;
  }
}

.carousel-banners .carousel-control.right:hover {
  background-image: url(/assets/img/carousel/next-md-active.svg);
}

@media (min-width: 768px) {
  .cuadros_contacto .cuadros {
    text-align: center;
  }
}

.cuadros_contacto .cuadro {
  width: 220px;
  height: 225px;
  display: block;
  margin: 0 auto 25px;
  background-color: #415d36;
  border-radius: 6px;
  position: relative;
}

@media (min-width: 360px) {
  .cuadros_contacto .cuadro {
    width: 244px;
    height: 250px;
    margin: 0 auto 30px;
  }
}

@media (min-width: 768px) {
  .cuadros_contacto .cuadro {
    width: 190px;
    height: 196px;
    border-radius: 5px;
    display: inline-block;
    vertical-align: top;
    margin: 0 13px 62px;
  }
}

.cuadros_contacto .cuadro .cuadro__contenido {
  position: relative;
  z-index: 2;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  top: 5px;
  left: 5px;
  font-size: 12.12px;
  line-height: 20.8px;
  text-align: center;
  font-family: Gotham-Light;
  letter-spacing: 0.160em;
  color: #ffffff;
}

@media (min-width: 360px) {
  .cuadros_contacto .cuadro .cuadro__contenido {
    font-size: 13.43px;
    line-height: 23px;
  }
}

@media (min-width: 768px) {
  .cuadros_contacto .cuadro .cuadro__contenido {
    font-size: 10.49px;
    line-height: 18px;
  }
}

.cuadros_contacto .cuadro .cuadro__contenido a {
  color: #ffffff;
}

.cuadros_contacto .cuadro .cuadro__contenido div {
  margin-bottom: 18px;
}

.cuadros_contacto .cuadro::after {
  content: '';
  position: absolute;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  top: 5px;
  left: 5px;
  border: 1px solid #ffffff;
  z-index: 1;
}

.cuadros_contacto .cuadro.cuadro_1 {
  padding-top: 36px;
}

@media (min-width: 360px) {
  .cuadros_contacto .cuadro.cuadro_1 {
    padding-top: 40px;
  }
}

@media (min-width: 768px) {
  .cuadros_contacto .cuadro.cuadro_1 {
    padding-top: 31px;
  }
}

.cuadros_contacto .cuadro.cuadro_1 img {
  width: 37.5px;
  display: block;
  margin: 0 auto 24px;
}

@media (min-width: 360px) {
  .cuadros_contacto .cuadro.cuadro_1 img {
    width: 41.5px;
    margin: 0 auto 29px;
  }
}

@media (min-width: 768px) {
  .cuadros_contacto .cuadro.cuadro_1 img {
    width: 32.3px;
    margin: 0 auto 27px;
  }
}

.cuadros_contacto .cuadro.cuadro_2 {
  padding-top: 35px;
}

@media (min-width: 360px) {
  .cuadros_contacto .cuadro.cuadro_2 {
    padding-top: 39px;
  }
}

@media (min-width: 768px) {
  .cuadros_contacto .cuadro.cuadro_2 {
    padding-top: 30px;
  }
}

.cuadros_contacto .cuadro.cuadro_2 img {
  width: 30.75px;
  display: block;
  margin: 0 auto 26px;
}

@media (min-width: 360px) {
  .cuadros_contacto .cuadro.cuadro_2 img {
    width: 34px;
    margin: 0 auto 27px;
  }
}

@media (min-width: 768px) {
  .cuadros_contacto .cuadro.cuadro_2 img {
    width: 26.6px;
    margin: 0 auto 25px;
  }
}

.cuadros_contacto .cuadro.cuadro_3 {
  padding-top: 38px;
}

@media (min-width: 360px) {
  .cuadros_contacto .cuadro.cuadro_3 {
    padding-top: 42px;
  }
}

@media (min-width: 768px) {
  .cuadros_contacto .cuadro.cuadro_3 {
    padding-top: 33px;
  }
}

.cuadros_contacto .cuadro.cuadro_3 img {
  width: 37.5px;
  display: block;
  margin: 0 auto 24px;
}

@media (min-width: 360px) {
  .cuadros_contacto .cuadro.cuadro_3 img {
    width: 41.5px;
    margin: 0 auto 27px;
  }
}

@media (min-width: 768px) {
  .cuadros_contacto .cuadro.cuadro_3 img {
    width: 32.3px;
    margin: 0 auto 25px;
  }
}

footer {
  width: 100%;
  background: black;
  /*
	background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 50%, rgba(65, 93, 54, 1) 100%);
	background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 50%, rgba(65, 93, 54, 1) 100%);
	background: linear-gradient(0deg, rgba(0, 0, 0, 1) 50%, rgba(65, 93, 54, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#415d36",GradientType=1);
	*/
  background: linear-gradient(0deg, #d5d5d5 50%, #415d36 100%);
  background: linear-gradient(180deg, #fff 0%, #d5d5d5 40%, #415d36 98%);
  position: relative;
  padding-top: 20px;
  padding-bottom: 6px;
}

@media (min-width: 992px) {
  footer {
    padding-top: 52.8px;
    padding-bottom: 4.95px;
  }
}

@media (min-width: 1200px) {
  footer {
    padding-top: 64px;
    padding-bottom: 6px;
  }
}

@media (min-width: 2400px) {
  footer {
    padding-top: 128px;
    padding-bottom: 12px;
  }
}

footer .logo {
  background-image: url(/assets/img/seleccion_idioma/logo.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
  width: 163px;
  height: 150px;
  margin-top: 0px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 414px) {
  footer .logo {
    width: 211.9px;
    height: 195px;
    margin-bottom: 10.4px;
  }
}

@media (min-width: 768px) {
  footer .logo {
    width: 391.2px;
    height: 360px;
    margin-bottom: 19.2px;
  }
}

@media (min-width: 992px) {
  footer .logo {
    left: -5.775px;
    width: 134.475px;
    height: 123.75px;
    margin-bottom: 6.6px;
    margin-left: initial;
    margin-right: initial;
  }
}

@media (min-width: 1200px) {
  footer .logo {
    left: -7px;
    width: 163px;
    height: 150px;
    margin-bottom: 8px;
  }
}

@media (min-width: 2400px) {
  footer .logo {
    left: -14px;
    width: 326px;
    height: 300px;
    margin-bottom: 16px;
  }
}

footer .direccion {
  font-family: "Roboto-Light";
  line-height: 1.2;
  color: #fff;
  color: #000;
  font-size: 16px;
  margin-bottom: 30px;
  text-align: center;
}

@media (min-width: 414px) {
  footer .direccion {
    font-size: 20.8px;
    margin-bottom: 39px;
    text-align: center;
  }
}

@media (min-width: 768px) {
  footer .direccion {
    font-size: 38.4px;
    margin-bottom: 72px;
  }
}

@media (min-width: 992px) {
  footer .direccion {
    text-align: left;
    font-size: 14.85px;
    margin-bottom: 44.55px;
  }
}

@media (min-width: 1200px) {
  footer .direccion {
    font-size: 18px;
    margin-bottom: 54px;
  }
}

@media (min-width: 2400px) {
  footer .direccion {
    font-size: 36px;
    margin-bottom: 108px;
  }
}

footer h2 {
  margin-top: 0;
  font-family: "Roboto-Bold";
  line-height: 1.2;
  letter-spacing: -0.064em;
  color: #fff;
  color: #000;
  margin-bottom: 10px;
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
}

@media (min-width: 414px) {
  footer h2 {
    margin-bottom: 13px;
    font-size: 20.8px;
    margin-top: 26px;
  }
}

@media (min-width: 768px) {
  footer h2 {
    margin-bottom: 24px;
    font-size: 38.4px;
    margin-top: 48px;
  }
}

@media (min-width: 992px) {
  footer h2 {
    margin-top: 0px;
    text-align: left;
    margin-bottom: 21.45px;
    font-size: 17.325px;
  }
}

@media (min-width: 1200px) {
  footer h2 {
    margin-bottom: 26px;
    font-size: 21px;
  }
}

@media (min-width: 2400px) {
  footer h2 {
    margin-bottom: 52px;
    font-size: 42px;
  }
}

footer .contenido_centrado {
  text-align: center;
}

@media (min-width: 992px) {
  footer .contenido_centrado h2 {
    text-align: center;
  }
}

footer ul.footer_menu {
  list-style: none;
  padding: 0;
  text-align: center;
}

@media (min-width: 992px) {
  footer ul.footer_menu {
    text-align: left;
  }
}

footer ul.footer_menu .footer_menu_item {
  color: #fff;
  color: #000;
  font-family: "Roboto-Thin";
  font-size: 16px;
  line-height: 1.5;
}

@media (min-width: 414px) {
  footer ul.footer_menu .footer_menu_item {
    font-size: 20.8px;
  }
}

@media (min-width: 768px) {
  footer ul.footer_menu .footer_menu_item {
    font-size: 38.4px;
  }
}

@media (min-width: 992px) {
  footer ul.footer_menu .footer_menu_item {
    font-size: 14.85px;
    line-height: 33px;
  }
}

@media (min-width: 1200px) {
  footer ul.footer_menu .footer_menu_item {
    font-size: 18px;
    line-height: 40px;
  }
}

@media (min-width: 2400px) {
  footer ul.footer_menu .footer_menu_item {
    font-size: 36px;
    line-height: 80px;
  }
}

footer .dato {
  text-align: center;
}

footer .dato .number {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  color: #000;
  font-family: "Roboto-Light";
  text-decoration: none;
  margin-left: 15px;
  margin-right: 7px;
  font-size: 16px;
  line-height: 1.5;
}

@media (min-width: 414px) {
  footer .dato .number {
    font-size: 20.8px;
  }
}

@media (min-width: 768px) {
  footer .dato .number {
    font-size: 38.4px;
  }
}

@media (min-width: 992px) {
  footer .dato .number {
    margin-left: 12.375px;
    margin-right: 5.775px;
    font-size: 14.85px;
    line-height: 33px;
  }
}

@media (min-width: 1200px) {
  footer .dato .number {
    margin-left: 15px;
    margin-right: 7px;
    font-size: 18px;
    line-height: 40px;
  }
}

@media (min-width: 2400px) {
  footer .dato .number {
    margin-left: 30px;
    margin-right: 14px;
    font-size: 36px;
    line-height: 80px;
  }
}

footer .dato a {
  font-size: 0;
}

footer .icon {
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 39.2px;
  height: 39.2px;
  margin: 0 9.8px;
}

@media (min-width: 992px) {
  footer .icon {
    width: 32.34px;
    height: 32.34px;
    margin: 0 8.085px;
  }
}

@media (min-width: 1200px) {
  footer .icon {
    width: 39.2px;
    height: 39.2px;
    margin: 0 9.8px;
  }
}

@media (min-width: 2400px) {
  footer .icon {
    width: 78.4px;
    height: 78.4px;
    margin: 0 19.6px;
  }
}

footer .icon.icon-telefono {
  background-image: url(/assets/img/footer/icon-telefono.svg);
}

footer .icon.icon-instagram {
  background-image: url(/assets/img/footer/icon-instagram.svg);
}

footer .icon.icon-facebook {
  background-image: url(/assets/img/footer/icon-facebook.svg);
}

footer .icon.icon-twitter {
  background-image: url(/assets/img/footer/icon-twitter.svg);
}

footer .icon.icon-linkedin {
  background-image: url(/assets/img/footer/icon-linkedin.svg);
}

footer .copyright {
  font-family: "Roboto-Thin";
  color: #fff;
  font-size: 10px;
  line-height: 1.5;
  text-align: center;
  margin-top: 20px;
}

@media (min-width: 414px) {
  footer .copyright {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  footer .copyright {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  footer .copyright {
    margin-top: 0px;
    text-align: left;
    font-size: 11.55px;
    line-height: 33px;
  }
}

@media (min-width: 1200px) {
  footer .copyright {
    font-size: 14px;
    line-height: 40px;
  }
}

@media (min-width: 2400px) {
  footer .copyright {
    font-size: 28px;
    line-height: 80px;
  }
}

footer .legal_links_footer {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 414px) {
  footer .legal_links_footer {
    margin-top: 13px;
    margin-bottom: 13px;
  }
}

@media (min-width: 768px) {
  footer .legal_links_footer {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

@media (min-width: 992px) {
  footer .legal_links_footer {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: right;
  }
}

footer .legal_links_footer a {
  font-family: "Roboto-Thin";
  color: #fff;
  line-height: 1.5;
  font-size: 10px;
  margin-left: 10px;
}

@media (min-width: 414px) {
  footer .legal_links_footer a {
    font-size: 13px;
    margin-left: 13px;
  }
}

@media (min-width: 768px) {
  footer .legal_links_footer a {
    font-size: 24px;
    margin-left: 24px;
  }
}

@media (min-width: 992px) {
  footer .legal_links_footer a {
    font-size: 11.55px;
    line-height: 33px;
    margin-left: 33px;
  }
}

@media (min-width: 1200px) {
  footer .legal_links_footer a {
    font-size: 14px;
    line-height: 40px;
    margin-left: 40px;
  }
}

@media (min-width: 2400px) {
  footer .legal_links_footer a {
    font-size: 28px;
    line-height: 80px;
    margin-left: 80px;
  }
}

section.formulario {
  background-image: url(../img/contacto/fondo_2.webp);
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: 15% 50%;
  padding-top: 9px;
  padding-bottom: 22px;
}

@media (min-width: 768px) {
  section.formulario {
    padding-top: 63px;
    padding-bottom: 55px;
    height: 448px;
    background-position: 50% 00%;
  }
}

@media (min-width: 992px) {
  section.formulario {
    height: 448px;
    padding-top: 72px;
    background-position: 50% 10%;
  }
}

@media (min-width: 1200px) {
  section.formulario {
    height: 555px;
    padding-top: 70px;
    background-position: 50% 10%;
  }
}

@media (min-width: 2400px) {
  section.formulario {
    height: 1110px;
    padding-top: 140px;
  }
}

section.formulario #form_contacto {
  width: 275px;
  margin: 0 auto;
}

@media (min-width: 360px) {
  section.formulario #form_contacto {
    width: 310px;
  }
}

@media (min-width: 768px) {
  section.formulario #form_contacto {
    width: 100%;
  }
}

@media (min-width: 992px) {
  section.formulario #form_contacto {
    width: 352px;
  }
}

@media (min-width: 1200px) {
  section.formulario #form_contacto {
    width: 442px;
  }
}

@media (min-width: 2400px) {
  section.formulario #form_contacto {
    width: 884px;
  }
}

section.formulario label {
  color: #ffffff;
  font-family: Gotham-Light;
  font-size: 9.91px;
  font-weight: normal;
  margin-bottom: 0px;
  letter-spacing: 0.225em;
  width: 100%;
  text-align: center;
  margin-top: 25px;
}

@media (min-width: 360px) {
  section.formulario label {
    font-size: 11.16px;
    margin-top: 30px;
  }
}

@media (min-width: 768px) {
  section.formulario label {
    width: initial;
  }
}

@media (min-width: 768px) {
  section.formulario form label {
    display: none;
  }
}

section.formulario input[type=text],
section.formulario input[type=email] {
  height: 24px;
  background: transparent;
  border-radius: 0px;
  box-shadow: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  font-family: Gotham-Light;
  font-size: 10px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1px;
  padding-top: 0px;
  letter-spacing: .225em;
}

@media (min-width: 360px) {
  section.formulario input[type=text],
  section.formulario input[type=email] {
    height: 25px;
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  section.formulario input[type=text],
  section.formulario input[type=email] {
    height: 40px;
    margin-top: 26px;
    font-size: 12.39px;
  }
}

@media (min-width: 1200px) {
  section.formulario input[type=text],
  section.formulario input[type=email] {
    height: 40px;
    margin-top: 36px;
    font-size: 12.39px;
  }
}

@media (min-width: 2400px) {
  section.formulario input[type=text],
  section.formulario input[type=email] {
    height: 80px;
    margin-top: 72px;
    font-size: 24.78px;
  }
}

section.formulario input[type=text]:hover, section.formulario input[type=text]:focus,
section.formulario input[type=email]:hover,
section.formulario input[type=email]:focus {
  box-shadow: none;
}

section.formulario input[type=text]::placeholder,
section.formulario input[type=email]::placeholder {
  opacity: 0;
}

@media (min-width: 768px) {
  section.formulario input[type=text]::placeholder,
  section.formulario input[type=email]::placeholder {
    color: #ffffff;
    opacity: 1;
    font-family: Gotham-Light;
    letter-spacing: 0.225em;
  }
}

section.formulario select {
  height: 24px;
  background: transparent;
  border-radius: 0px;
  box-shadow: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  font-family: Gotham-Light;
  font-size: 10px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1px;
  padding-top: 0px;
  letter-spacing: .225em;
}

@media (min-width: 360px) {
  section.formulario select {
    height: 25px;
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  section.formulario select {
    height: 40px;
    margin-top: 26px;
    font-size: 12.39px;
  }
}

@media (min-width: 1200px) {
  section.formulario select {
    height: 40px;
    margin-top: 36px;
    font-size: 12.39px;
  }
}

@media (min-width: 2400px) {
  section.formulario select {
    height: 80px;
    margin-top: 72px;
    font-size: 24.78px;
  }
}

section.formulario select option {
  color: #000;
}

section.formulario select:hover, section.formulario select:focus {
  box-shadow: none;
}

section.formulario .checkbox {
  text-align: center;
}

section.formulario .checkbox label {
  font-size: 8px;
  margin-top: 0px;
}

@media (min-width: 992px) {
  section.formulario .checkbox label {
    font-size: 9px;
  }
}

@media (min-width: 1200px) {
  section.formulario .checkbox label {
    font-size: 11.15px;
  }
}

@media (min-width: 2400px) {
  section.formulario .checkbox label {
    font-size: 22.30px;
  }
}

section.formulario .checkbox label input {
  margin-top: 0;
}

@media (min-width: 2400px) {
  section.formulario .checkbox label input {
    margin-top: 5px;
  }
}

@media (min-width: 768px) {
  section.formulario .columna-izquierda {
    width: 306px;
  }
}

@media (min-width: 992px) {
  section.formulario .columna-izquierda {
    width: 391px;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  section.formulario .columna-izquierda {
    width: 480px;
  }
}

@media (min-width: 2400px) {
  section.formulario .columna-izquierda {
    width: 960px;
  }
}

.mapa {
  width: 100%;
  height: 308px;
  display: block;
}

@media (min-width: 768px) {
  .mapa {
    height: 382px;
  }
}

@media (min-width: 992px) {
  .mapa {
    height: 512px;
  }
}

@media (min-width: 1200px) {
  .mapa {
    height: 635px;
  }
}

@media (min-width: 2400px) {
  .mapa {
    height: 1430px;
  }
}

/*
	Estilos del 
	- Navbar
	- Menu movil
*/
:root {
  --altura_menu_xs: 60px;
}

@media (min-width: 360px) {
  :root {
    --altura_menu_xs: 67px;
  }
}

#logo_principal {
  background-image: url(../img/logo.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: 50% 50%;
  width: 58px;
  height: 50px;
}

@media (min-width: 414px) {
  #logo_principal {
    width: 75.4px;
    height: 65px;
  }
}

@media (min-width: 768px) {
  #logo_principal {
    width: 139.2px;
    height: 120px;
  }
}

@media (min-width: 992px) {
  #logo_principal {
    width: 88.275px;
    height: 75.9px;
  }
}

@media (min-width: 1200px) {
  #logo_principal {
    width: 107px;
    height: 92px;
  }
}

@media (min-width: 2400px) {
  #logo_principal {
    width: 214px;
    height: 184px;
  }
}

.navbar {
  min-height: var(--altura_menu_xs);
  margin-bottom: 0;
  border-radius: 0px;
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
}

@media (min-width: 768px) {
  .navbar {
    width: 100%;
    top: 0;
    left: 0;
  }
}

@media (min-width: 992px) {
  .navbar {
    position: relative;
  }
}

.navbar-brand {
  padding: 19px 30px 11px;
  height: auto;
}

@media (min-width: 414px) {
  .navbar-brand {
    padding: 24.7px 39px 14.3px;
  }
}

@media (min-width: 768px) {
  .navbar-brand {
    padding: 45.6px 72px 26.4px;
  }
}

@media (min-width: 992px) {
  .navbar-brand {
    padding: 15.675px 24.75px 9.075px;
  }
}

@media (min-width: 1200px) {
  .navbar-brand {
    padding: 19px 30px 11px;
  }
}

@media (min-width: 2400px) {
  .navbar-brand {
    padding: 38px 60px 22px;
  }
}

.navbar-default {
  background-color: #ffffff;
  border: none;
  z-index: 1;
  width: 100%;
}

.navbar-default .menu {
  background-image: url("../img/icon-menu.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  cursor: pointer;
  width: 43px;
  height: 26px;
  top: 27px;
  right: 45px;
}

@media (min-width: 414px) {
  .navbar-default .menu {
    width: 55.9px;
    height: 33.8px;
    top: 35.1px;
    right: 58.5px;
  }
}

@media (min-width: 768px) {
  .navbar-default .menu {
    width: 103.2px;
    height: 62.4px;
    top: 64.8px;
    right: 108px;
  }
}

@media (min-width: 992px) {
  .navbar-default .menu {
    width: 35.475px;
    height: 21.45px;
    top: 26.4px;
    right: 37.125px;
  }
}

@media (min-width: 1200px) {
  .navbar-default .menu {
    width: 43px;
    height: 26px;
    top: 32px;
    right: 45px;
  }
}

@media (min-width: 2400px) {
  .navbar-default .menu {
    width: 86px;
    height: 52px;
    top: 64px;
    right: 90px;
  }
}

.navbar-default .menu:hover {
  background-image: url("../img/icon-menu-active.svg");
}

@media (min-width: 992px) {
  .navbar-nav {
    padding-right: 57.75px;
  }
}

@media (min-width: 1200px) {
  .navbar-nav {
    padding-right: 70px;
  }
}

@media (min-width: 2400px) {
  .navbar-nav {
    padding-right: 140px;
  }
}

.navbar-collapse.collapse {
  /* Celuar y Tablet */
}

@media (max-width: 991px) {
  .navbar-collapse.collapse {
    display: none !important;
  }
}

.navbar-default .navbar-nav > li > a {
  line-height: 1;
  color: #000000;
  font-family: "Roboto-Light";
  font-size: 16px;
  padding: 53px 22px 53px;
}

@media (min-width: 992px) {
  .navbar-default .navbar-nav > li > a {
    font-size: 13.2px;
    padding: 43.725px 18.15px 43.725px;
  }
}

@media (min-width: 1200px) {
  .navbar-default .navbar-nav > li > a {
    font-size: 16px;
    padding: 53px 22px 53px;
  }
}

@media (min-width: 2400px) {
  .navbar-default .navbar-nav > li > a {
    font-size: 32px;
    padding: 106px 44px 106px;
  }
}

.navbar-default .navbar-nav > li > a.active:before, .navbar-default .navbar-nav > li > a:hover:before {
  content: "";
  position: absolute;
  left: 0;
  background-color: #393d42;
  width: 100%;
  bottom: 30px;
  height: 1px;
}

@media (min-width: 992px) {
  .navbar-default .navbar-nav > li > a.active:before, .navbar-default .navbar-nav > li > a:hover:before {
    bottom: 24.75px;
    height: 1px;
  }
}

@media (min-width: 1200px) {
  .navbar-default .navbar-nav > li > a.active:before, .navbar-default .navbar-nav > li > a:hover:before {
    bottom: 30px;
    height: 1px;
  }
}

@media (min-width: 2400px) {
  .navbar-default .navbar-nav > li > a.active:before, .navbar-default .navbar-nav > li > a:hover:before {
    bottom: 60px;
    height: 2px;
  }
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li.active > a,
.navbar-default .navbar-nav > li.active > a:hover,
.navbar-default .navbar-nav > li.active > a:focus {
  color: #415d36;
}

.navbar-default .navbar-nav > li.li_icon > a {
  padding: 53px 12px 53px;
}

@media (min-width: 992px) {
  .navbar-default .navbar-nav > li.li_icon > a {
    padding: 43.725px 9.9px 43.725px;
  }
}

@media (min-width: 1200px) {
  .navbar-default .navbar-nav > li.li_icon > a {
    padding: 53px 12px 53px;
  }
}

@media (min-width: 2400px) {
  .navbar-default .navbar-nav > li.li_icon > a {
    padding: 106px 24px 106px;
  }
}

.navbar-default .navbar-nav > li.li_separator {
  line-height: 1;
  font-family: "Roboto-Light";
  color: #000000;
  padding: 53px 4px 53px;
  font-size: 16px;
}

@media (min-width: 992px) {
  .navbar-default .navbar-nav > li.li_separator {
    padding: 43.725px 3.3px 43.725px;
    font-size: 13.2px;
  }
}

@media (min-width: 1200px) {
  .navbar-default .navbar-nav > li.li_separator {
    padding: 53px 4px 53px;
    font-size: 16px;
  }
}

@media (min-width: 2400px) {
  .navbar-default .navbar-nav > li.li_separator {
    padding: 106px 8px 106px;
    font-size: 32px;
  }
}

.navbar-default .navbar-nav > li.li_idiom > a {
  padding: 53px 3px 53px;
}

@media (min-width: 992px) {
  .navbar-default .navbar-nav > li.li_idiom > a {
    padding: 43.725px 2.475px 43.725px;
  }
}

@media (min-width: 1200px) {
  .navbar-default .navbar-nav > li.li_idiom > a {
    padding: 53px 3px 53px;
  }
}

@media (min-width: 2400px) {
  .navbar-default .navbar-nav > li.li_idiom > a {
    padding: 106px 6px 106px;
  }
}

.navbar-default .icon {
  background-size: 100%;
  background-repeat: no-repeat;
}

.navbar-default .icon.icon-facebook {
  padding-top: 0px;
  background-image: url(../img/icon-facebook.svg);
  padding-top: 0px;
  width: 11px;
  height: 25px;
}

@media (min-width: 992px) {
  .navbar-default .icon.icon-facebook {
    width: 9.075px;
    height: 20.625px;
  }
}

@media (min-width: 1200px) {
  .navbar-default .icon.icon-facebook {
    width: 11px;
    height: 25px;
  }
}

@media (min-width: 2400px) {
  .navbar-default .icon.icon-facebook {
    width: 22px;
    height: 50px;
  }
}

.navbar-default .icon.icon-facebook:hover {
  background-image: url(../img/icon-facebook-active.svg);
}

.navbar-default .icon.icon-linkedin {
  background-image: url(../img/icon-linkedin.svg);
  padding-top: 3px;
  width: 19px;
  height: 19px;
}

@media (min-width: 992px) {
  .navbar-default .icon.icon-linkedin {
    padding-top: 2.475px;
    width: 15.675px;
    height: 15.675px;
  }
}

@media (min-width: 1200px) {
  .navbar-default .icon.icon-linkedin {
    padding-top: 3px;
    width: 19px;
    height: 19px;
  }
}

@media (min-width: 2400px) {
  .navbar-default .icon.icon-linkedin {
    padding-top: 6px;
    width: 38px;
    height: 38px;
  }
}

/* Celular y Tablet */
#menu-movil {
  width: 0%;
  height: 100%;
  position: fixed;
  background-color: rgba(16, 25, 47, 0.86);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: 40% 50%;
  z-index: 1000;
  transition: width 2s;
}

#menu-movil.active {
  width: 100%;
}

#menu-movil * {
  transition: opacity 0.5s 0s;
  opacity: 0;
}

#menu-movil.active * {
  transition: opacity 2s 1s;
  opacity: 1;
}

@media (min-width: 992px) {
  #menu-movil {
    display: none !important;
  }
}

#menu-movil .icon-close {
  cursor: pointer;
  float: right;
  margin-top: 23px;
  margin-right: 23px;
  width: 17px;
}

@media (min-width: 360px) {
  #menu-movil .icon-close {
    width: 19px;
    margin-top: 27px;
    margin-right: 27px;
  }
}

@media (min-width: 414px) {
  #menu-movil .icon-close {
    width: 22.1px;
    margin-top: 29.9px;
    margin-right: 29.9px;
  }
}

@media (min-width: 768px) {
  #menu-movil .icon-close {
    width: 40.8px;
    margin-top: 46px;
    margin-right: 46px;
  }
}

#menu-movil .container-fluid {
  height: calc(100% - 45px - 24px);
  top: 45px;
  position: relative;
}

@media (min-width: 414px) {
  #menu-movil .container-fluid {
    height: calc(100% - 45px * 1.3 - 24px * 1.3);
    top: 58.5px;
  }
}

@media (min-width: 768px) {
  #menu-movil .container-fluid {
    height: calc(100% - 45px * 2.4 - 24px * 2.4);
    top: 108px;
  }
}

#menu-movil .row {
  height: 100%;
}

#menu-movil .li_separator {
  display: inline-block;
  color: #ffffff;
  font-family: "Roboto-Regular";
  letter-spacing: 0.053em;
  font-size: 22.85px;
  margin-top: 24px;
}

@media (min-width: 360px) {
  #menu-movil .li_separator {
    font-size: 25.77px;
  }
}

@media (min-width: 414px) {
  #menu-movil .li_separator {
    font-size: 29.705px;
    margin-top: 31.2px;
  }
}

@media (min-width: 768px) {
  #menu-movil .li_separator {
    font-size: 54.84px;
    margin-top: 57.6px;
  }
}

#menu-movil a {
  color: #ffffff;
  font-family: "Roboto-Regular";
  letter-spacing: 0.053em;
  display: block;
  font-size: 22.85px;
  margin-top: 24px;
}

#menu-movil a:hover {
  color: #ffffff;
}

@media (min-width: 360px) {
  #menu-movil a {
    font-size: 25.77px;
  }
}

@media (min-width: 414px) {
  #menu-movil a {
    font-size: 29.705px;
    margin-top: 31.2px;
  }
}

@media (min-width: 768px) {
  #menu-movil a {
    font-size: 54.84px;
    margin-top: 57.6px;
  }
}

#menu-movil a.menu_es, #menu-movil a.menu_en {
  display: inline-block;
}

#menu-movil .a_icon {
  display: inline-block;
  margin: 36px 11px 50px;
}

@media (min-width: 360px) {
  #menu-movil .a_icon {
    margin: 41px 13px 57px;
  }
}

@media (min-width: 414px) {
  #menu-movil .a_icon {
    margin: 46.8px 14.3px 65px;
  }
}

@media (min-width: 768px) {
  #menu-movil .a_icon {
    margin: 86.4px 26.4px 120px;
  }
}

#menu-movil .a_icon .icon {
  width: 36px;
  height: 36px;
  background-size: 100%;
}

@media (min-width: 360px) {
  #menu-movil .a_icon .icon {
    width: 40.5px;
    height: 40.5px;
  }
}

@media (min-width: 414px) {
  #menu-movil .a_icon .icon {
    width: 46.8px;
    height: 46.8px;
  }
}

@media (min-width: 768px) {
  #menu-movil .a_icon .icon {
    width: 86.4px;
    height: 86.4px;
  }
}

#menu-movil .a_icon .icon.icon-facebook {
  background-image: url(../img/menu-movil/icon-facebook-active.svg);
}

#menu-movil .a_icon .icon.icon-linkedin {
  background-image: url(../img/menu-movil/icon-linkedin-active.svg);
}

.progress-big {
  height: 40px;
}

.progress-big > div {
  font-size: 30px;
  line-height: 37px;
}

.video iframe {
  height: 200px;
  display: block;
}

@media (min-width: 360px) {
  .video iframe {
    height: 316px;
  }
}

@media (min-width: 768px) {
  .video iframe {
    height: 370px;
  }
}

@media (min-width: 992px) {
  .video iframe {
    height: 413px;
  }
}

@media (min-width: 1200px) {
  .video iframe {
    height: 516px;
  }
}

@media (min-width: 2400px) {
  .video iframe {
    height: 1032px;
  }
}

.logo_boxnave {
  width: 100px;
  background-color: #fff;
  border-radius: 9px;
  padding: 7px;
  box-shadow: 0px 0px 5px #666;
  position: relative;
  top: -1px;
  margin-left: 3px;
  margin-right: 25px;
}

.desarrollado {
  color: white;
  margin-top: 10px;
  width: 100%;
}

@media (min-width: 768px) {
  .desarrollado {
    margin-top: 20px;
  }
}

@media (min-width: 992px) {
  .desarrollado {
    text-align: right;
    margin-top: 15px;
  }
}

.desarrollado a {
  color: white;
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
}

@media (min-width: 1200px) {
  .desarrollado a {
    font-size: 14px;
  }
}

.seleccionar_idioma {
  height: calc(100vh);
  min-height: 600px;
}

@media (min-width: 414px) {
  .seleccionar_idioma {
    min-height: 780px;
  }
}

@media (min-width: 768px) {
  .seleccionar_idioma {
    min-height: 1440px;
  }
}

@media (min-width: 992px) {
  .seleccionar_idioma {
    min-height: 495px;
  }
}

@media (min-width: 1200px) {
  .seleccionar_idioma {
    min-height: 720px;
  }
}

@media (min-width: 2400px) {
  .seleccionar_idioma {
    min-height: 1200px;
  }
}

.seleccionar_idioma .punto_central {
  width: 100%;
}

@media (min-width: 992px) {
  .seleccionar_idioma .punto_central {
    height: 115.5px;
  }
}

@media (min-width: 1200px) {
  .seleccionar_idioma .punto_central {
    height: 140px;
  }
}

@media (min-width: 2400px) {
  .seleccionar_idioma .punto_central {
    height: 280px;
  }
}

.seleccionar_idioma .contenedor_imagen {
  background-color: white;
  border-radius: 50%;
  width: 290px;
  height: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto 20px;
  z-index: 10;
}

@media (min-width: 768px) {
  .seleccionar_idioma .contenedor_imagen {
    width: 348px;
    height: 348px;
  }
}

@media (min-width: 992px) {
  .seleccionar_idioma .contenedor_imagen {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: initial;
    width: calc(36vw);
    height: calc(36vw);
    transform: translateY(calc(-50% + (70px * 0.825))) translateX(calc(-50% + ((33.3vw /*+ 30px*/) / 2)));
  }
}

@media (min-width: 1200px) {
  .seleccionar_idioma .contenedor_imagen {
    width: calc(36vw);
    height: calc(36vw);
    transform: translateY(calc(-50% + 70px)) translateX(calc(-50% + ((33.3vw /*+ 30px*/) / 2)));
  }
}

@media (min-width: 2400px) {
  .seleccionar_idioma .contenedor_imagen {
    width: calc(36vw);
    height: calc(36vw);
    transform: translateY(calc(-50% + (70px * 2))) translateX(calc(-50% + ((33.3vw /*+ 30px*/) / 2)));
  }
}

.seleccionar_idioma .logo_inicio {
  max-width: 70%;
  display: block;
}

@media (min-width: 992px) {
  .seleccionar_idioma .logo_inicio {
    width: 100%;
  }
}

.seleccionar_idioma .seccion_principal {
  background-color: #000;
  height: 100%;
  background-image: url(/assets/img/seleccion_idioma/fondo.png);
  background-size: cover;
  background-position: 50% 100%;
  position: relative;
}

.seleccionar_idioma .seccion_principal .overlay {
  /*
			background-color: rgba($main-color, 0.7);
			mix-blend-mode: multiply;
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 1;
			*/
}

.seleccionar_idioma .seccion_principal .container-fluid {
  position: relative;
  z-index: 2;
}

@media (min-width: 992px) {
  .seleccionar_idioma .seccion_principal .container-fluid {
    padding-top: calc((100vh) / 2 - (70px * 0.825));
  }
}

@media (min-width: 1200px) {
  .seleccionar_idioma .seccion_principal .container-fluid {
    padding-top: calc((100vh) / 2 - 70px);
  }
}

@media (min-width: 2400px) {
  .seleccionar_idioma .seccion_principal .container-fluid {
    padding-top: calc((100vh) / 2 - (70px * 2));
  }
}

.seleccionar_idioma .seccion_principal .container-fluid h1 {
  color: #fff;
  text-align: center;
  font-family: "Roboto-Black";
  font-size: 23px;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 1;
  color: white;
  background-color: #393d42;
  padding: 10px;
}

@media (min-width: 414px) {
  .seleccionar_idioma .seccion_principal .container-fluid h1 {
    font-size: 39px;
    margin-top: 26px;
    margin-bottom: 26px;
  }
}

@media (min-width: 768px) {
  .seleccionar_idioma .seccion_principal .container-fluid h1 {
    font-size: 72px;
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

@media (min-width: 992px) {
  .seleccionar_idioma .seccion_principal .container-fluid h1 {
    font-size: 28.875px;
    padding-top: 36.3px;
    padding-bottom: 36.3px;
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .seleccionar_idioma .seccion_principal .container-fluid h1 {
    font-size: 35px;
    padding-top: 44px;
    padding-bottom: 44px;
  }
}

@media (min-width: 2400px) {
  .seleccionar_idioma .seccion_principal .container-fluid h1 {
    font-size: 70px;
    padding-top: 88px;
    padding-bottom: 88px;
  }
}

.seleccionar_idioma .seccion_principal .container-fluid .selector {
  background-color: #393d42;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .seleccionar_idioma .seccion_principal .container-fluid .selector {
    margin-bottom: 0px;
  }
}

.seleccionar_idioma .seccion_principal .container-fluid .selector a {
  position: relative;
  display: block;
  text-decoration: none;
}

.seleccionar_idioma .seccion_principal .container-fluid .cuadro {
  bottom: 0px;
  width: 100%;
}

@media (min-width: 992px) {
  .seleccionar_idioma .seccion_principal .container-fluid .cuadro {
    position: absolute;
  }
}

.seleccionar_idioma .seccion_principal .container-fluid .cuadro .contenido {
  background-color: #393d42;
  width: 100%;
  z-index: 3;
  position: relative;
}

.seleccionar_idioma .seccion_principal .container-fluid .cuadro .contenido .lbl {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  font-family: "Roboto-Regular";
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: 104px;
  height: 25px;
  bottom: 10px;
}

@media (min-width: 414px) {
  .seleccionar_idioma .seccion_principal .container-fluid .cuadro .contenido .lbl {
    font-size: 20.8px;
    width: 135.2px;
    height: 32.5px;
    bottom: 13px;
  }
}

@media (min-width: 768px) {
  .seleccionar_idioma .seccion_principal .container-fluid .cuadro .contenido .lbl {
    font-size: 38.4px;
    width: 249.6px;
    height: 60px;
    bottom: 10px;
  }
}

@media (min-width: 992px) {
  .seleccionar_idioma .seccion_principal .container-fluid .cuadro .contenido .lbl {
    font-size: 19.24725px;
    width: 186.45px;
    height: 64.35px;
    bottom: initial;
  }
}

@media (min-width: 1200px) {
  .seleccionar_idioma .seccion_principal .container-fluid .cuadro .contenido .lbl {
    font-size: 35px;
    width: 226px;
    height: 78px;
  }
}

@media (min-width: 2400px) {
  .seleccionar_idioma .seccion_principal .container-fluid .cuadro .contenido .lbl {
    font-size: 46.66px;
    width: 452px;
    height: 156px;
  }
}

.seleccionar_idioma .seccion_principal .container-fluid .cuadro .fondo {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(65, 93, 54, 0.9);
}

.seleccionar_idioma .seccion_principal .container-fluid .recuadro {
  width: 100%;
  background-color: rgba(57, 61, 66, 0.7);
  color: #fff;
  font-family: "Roboto-Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  height: 50px;
  margin-top: 30px;
}

@media (min-width: 414px) {
  .seleccionar_idioma .seccion_principal .container-fluid .recuadro {
    font-size: 23.4px;
    height: 65px;
    margin-top: 39px;
  }
}

@media (min-width: 768px) {
  .seleccionar_idioma .seccion_principal .container-fluid .recuadro {
    font-size: 43.2px;
    height: 120px;
    margin-top: 72px;
  }
}

@media (min-width: 992px) {
  .seleccionar_idioma .seccion_principal .container-fluid .recuadro {
    font-size: 19.8px;
    height: 57.75px;
    margin-top: 37.125px;
  }
}

@media (min-width: 1200px) {
  .seleccionar_idioma .seccion_principal .container-fluid .recuadro {
    font-size: 24px;
    height: 70px;
    margin-top: 45px;
  }
}

@media (min-width: 2400px) {
  .seleccionar_idioma .seccion_principal .container-fluid .recuadro {
    font-size: 48px;
    height: 140px;
    margin-top: 90px;
  }
}

.home .seccion_1 {
  height: calc(100vh - 80px);
  min-height: 400px;
  max-height: 1024px;
}

@media (min-width: 414px) {
  .home .seccion_1 {
    height: calc(100vh - 80px * 1.3);
    min-height: 400px;
    max-height: 1024px;
  }
}

@media (min-width: 768px) {
  .home .seccion_1 {
    height: calc(100vh - 80px * 2.4);
    min-height: 480px;
    max-height: 1024px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 {
    height: calc(100vh - 122px * 0.825);
    min-height: 600px;
    max-height: 1024px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 {
    height: calc(100vh - 122px);
    min-height: 600px;
    max-height: 1024px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 {
    height: calc(100vh - 122px * 2);
    min-height: 1200px;
    max-height: 2048px;
  }
}

.home .seccion_1 #carousel__home_1 {
  height: 100%;
}

.home .seccion_1 #carousel__home_1 .carousel-inner {
  height: 100%;
}

.home .seccion_1 #carousel__home_1 .carousel-inner .item {
  height: 100%;
}

.home .seccion_1 #carousel__home_1 .carousel-inner .item .diapositiva_1 {
  background-color: black;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  align-items: center;
  flex-grow: 0;
  position: relative;
}

.home .seccion_1 #carousel__home_1 .carousel-inner .item .diapositiva_1 .contenedor_texto {
  width: 100%;
  position: relative;
  z-index: 2;
}

.home .seccion_1 #carousel__home_1 .carousel-inner .item .diapositiva_1 .contenedor_texto::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #393d42;
  mix-blend-mode: multiply;
  transform: translateZ(0);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
}

.home .seccion_1 #carousel__home_1 .carousel-inner .item .diapositiva_1 .contenedor_texto .texto {
  color: #fff;
  text-align: center;
  font-family: "Roboto-Bold";
  position: relative;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: 2;
  letter-spacing: 0.025em;
  font-size: 18px;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 414px) {
  .home .seccion_1 #carousel__home_1 .carousel-inner .item .diapositiva_1 .contenedor_texto .texto {
    font-size: 23.4px;
    line-height: 26px;
    padding-top: 13px;
    padding-bottom: 13px;
  }
}

@media (min-width: 768px) {
  .home .seccion_1 #carousel__home_1 .carousel-inner .item .diapositiva_1 .contenedor_texto .texto {
    font-size: 43.2px;
    line-height: 48px;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 #carousel__home_1 .carousel-inner .item .diapositiva_1 .contenedor_texto .texto {
    font-size: 49.5px;
    line-height: 66px;
    padding-top: 38.775px;
    padding-bottom: 36.3px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 #carousel__home_1 .carousel-inner .item .diapositiva_1 .contenedor_texto .texto {
    font-size: 60px;
    line-height: 80px;
    padding-top: 47px;
    padding-bottom: 44px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 #carousel__home_1 .carousel-inner .item .diapositiva_1 .contenedor_texto .texto {
    font-size: 120px;
    line-height: 160px;
    padding-top: 94px;
    padding-bottom: 88px;
  }
}

.home .seccion_1 #carousel__home_1 .carousel-inner .item .diapositiva_1 .contenedor_video {
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
}

.home .seccion_1 #carousel__home_1 .carousel-inner .item .diapositiva_1 .contenedor_video video {
  height: 100%;
  width: 177.77777778vh;
  /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw;
  /* 100 * 9 / 16 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* % of current element */
}

.home .seccion_1 #carousel__home_1 .carousel-control .icon-left,
.home .seccion_1 #carousel__home_1 .carousel-control .icon-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

@media (min-width: 414px) {
  .home .seccion_1 #carousel__home_1 .carousel-control .icon-left,
  .home .seccion_1 #carousel__home_1 .carousel-control .icon-right {
    width: 26px;
    height: 26px;
  }
}

@media (min-width: 768px) {
  .home .seccion_1 #carousel__home_1 .carousel-control .icon-left,
  .home .seccion_1 #carousel__home_1 .carousel-control .icon-right {
    width: 48px;
    height: 48px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 #carousel__home_1 .carousel-control .icon-left,
  .home .seccion_1 #carousel__home_1 .carousel-control .icon-right {
    width: 37.95px;
    height: 37.95px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 #carousel__home_1 .carousel-control .icon-left,
  .home .seccion_1 #carousel__home_1 .carousel-control .icon-right {
    width: 46px;
    height: 46px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 #carousel__home_1 .carousel-control .icon-left,
  .home .seccion_1 #carousel__home_1 .carousel-control .icon-right {
    width: 92px;
    height: 92px;
  }
}

.home .seccion_1 #carousel__home_1 .carousel-control .icon-left {
  left: 0;
}

.home .seccion_1 #carousel__home_1 .carousel-control .icon-right {
  right: 0;
}

.home .seccion_2 h1 {
  font-family: "Roboto-Bold";
  line-height: 1.2;
  color: #393d42;
  text-align: center;
  font-size: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 414px) {
  .home .seccion_2 h1 {
    font-size: 39px;
    padding-top: 26px;
    padding-bottom: 26px;
  }
}

@media (min-width: 768px) {
  .home .seccion_2 h1 {
    font-size: 72px;
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media (min-width: 992px) {
  .home .seccion_2 h1 {
    font-size: 49.5px;
    padding-top: 45.375px;
    padding-bottom: 37.95px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_2 h1 {
    font-size: 60px;
    padding-top: 55px;
    padding-bottom: 46px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_2 h1 {
    font-size: 120px;
    padding-top: 110px;
    padding-bottom: 92px;
  }
}

.home .seccion_2 h1 span {
  color: #415d36;
}

.home .seccion_2 .texto {
  color: #000000;
  line-height: 2;
  font-family: "Roboto-Light";
  letter-spacing: 0.025em;
  text-align: justify;
  font-size: 13px;
}

@media (min-width: 414px) {
  .home .seccion_2 .texto {
    font-size: 16.9px;
  }
}

@media (min-width: 768px) {
  .home .seccion_2 .texto {
    font-size: 31.2px;
  }
}

@media (min-width: 992px) {
  .home .seccion_2 .texto {
    font-size: 14.85px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_2 .texto {
    font-size: 18px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_2 .texto {
    font-size: 36px;
  }
}

.home .seccion_2 .texto span {
  color: #415d36;
  font-family: Roboto-Regular;
}

.home .seccion_2 .texto .bold {
  color: #415d36;
  font-family: "Roboto-Bold";
  font-size: 200%;
  text-align: center;
}

.home .seccion_2 .imagen_servicios {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 414px) {
  .home .seccion_2 .imagen_servicios {
    margin-top: 26px;
    margin-bottom: 26px;
  }
}

@media (min-width: 768px) {
  .home .seccion_2 .imagen_servicios {
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

@media (min-width: 992px) {
  .home .seccion_2 .imagen_servicios {
    margin-top: 41.25px;
    margin-bottom: 24.75px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_2 .imagen_servicios {
    margin-top: 50px;
    margin-bottom: 30px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_2 .imagen_servicios {
    margin-top: 100px;
    margin-bottom: 60px;
  }
}

.home .seccion_3 {
  margin-bottom: 20px;
}

@media (min-width: 414px) {
  .home .seccion_3 {
    margin-bottom: 26px;
  }
}

@media (min-width: 768px) {
  .home .seccion_3 {
    margin-bottom: 48px;
  }
}

@media (min-width: 992px) {
  .home .seccion_3 {
    margin-bottom: 24.75px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_3 {
    margin-bottom: 30px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_3 {
    margin-bottom: 60px;
  }
}

.home .seccion_3 .cabecera {
  background-image: url(/assets/img/home/nuestros_servicios.jpg);
  width: 100%;
  background-size: 100% auto;
  background-position: 50% 30%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin-top: 30px;
  margin-bottom: 20px;
}

@media (min-width: 414px) {
  .home .seccion_3 .cabecera {
    height: 156px;
    margin-top: 39px;
    margin-bottom: 26px;
  }
}

@media (min-width: 768px) {
  .home .seccion_3 .cabecera {
    height: 288px;
    margin-top: 72px;
    margin-bottom: 48px;
  }
}

@media (min-width: 992px) {
  .home .seccion_3 .cabecera {
    height: 148.5px;
    margin-top: 49.5px;
    margin-bottom: 41.25px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_3 .cabecera {
    height: 180px;
    margin-top: 60px;
    margin-bottom: 50px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_3 .cabecera {
    height: 360px;
    margin-top: 120px;
    margin-bottom: 100px;
  }
}

.home .seccion_3 .cabecera::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #415d36;
  mix-blend-mode: multiply;
  transform: translateZ(0);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.6;
}

.home .seccion_3 .cabecera h1 {
  color: #fff;
  line-height: 1;
  text-align: center;
  font-family: "Roboto-Bold";
  position: relative;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: 2;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 30px;
}

@media (min-width: 414px) {
  .home .seccion_3 .cabecera h1 {
    font-size: 39px;
  }
}

@media (min-width: 768px) {
  .home .seccion_3 .cabecera h1 {
    font-size: 72px;
  }
}

@media (min-width: 992px) {
  .home .seccion_3 .cabecera h1 {
    font-size: 49.5px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_3 .cabecera h1 {
    font-size: 60px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_3 .cabecera h1 {
    font-size: 120px;
  }
}

.home .seccion_3 .contenedor_servicios {
  background-color: #415d36;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .home .seccion_3 .contenedor_servicios {
    padding-left: 36px;
    padding-right: 36px;
  }
}

@media (min-width: 992px) {
  .home .seccion_3 .contenedor_servicios {
    padding-left: 49.5px;
    padding-right: 49.5px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_3 .contenedor_servicios {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_3 .contenedor_servicios {
    padding-left: 120px;
    padding-right: 120px;
  }
}

.home .seccion_3 .contenedor_servicios h2 {
  width: 100%;
  color: #ffffff;
  font-family: "Roboto-Light";
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 25px;
  line-height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (min-width: 414px) {
  .home .seccion_3 .contenedor_servicios h2 {
    font-size: 32.5px;
    line-height: 39px;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
  }
}

@media (min-width: 768px) {
  .home .seccion_3 .contenedor_servicios h2 {
    font-size: 60px;
    line-height: 72px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@media (min-width: 992px) {
  .home .seccion_3 .contenedor_servicios h2 {
    font-size: 33px;
    line-height: 49.5px;
    padding-top: 5.775px;
    padding-bottom: 5.775px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_3 .contenedor_servicios h2 {
    font-size: 40px;
    line-height: 60px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_3 .contenedor_servicios h2 {
    font-size: 80px;
    line-height: 120px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

.home .seccion_3 .contenedor_servicios .servicio {
  width: calc(100%);
  cursor: pointer;
  position: relative;
  height: 290px;
  margin-bottom: 15px;
}

@media (min-width: 414px) {
  .home .seccion_3 .contenedor_servicios .servicio {
    height: 377px;
    margin-bottom: 19.5px;
  }
}

@media (min-width: 768px) {
  .home .seccion_3 .contenedor_servicios .servicio {
    height: 600px;
    margin-bottom: 36px;
  }
}

@media (min-width: 992px) {
  .home .seccion_3 .contenedor_servicios .servicio {
    height: 330px;
    margin-bottom: 0px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_3 .contenedor_servicios .servicio {
    height: 400px;
    margin-bottom: 0px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_3 .contenedor_servicios .servicio {
    height: 800px;
    margin-bottom: 0px;
  }
}

.home .seccion_3 .contenedor_servicios .servicio .imagen {
  width: calc(100%);
  background-color: #fff;
  background-size: cover;
  background-position: 50% 50%;
  height: calc(100%);
  margin-bottom: 15px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .seccion_3 .contenedor_servicios .servicio .descripcion {
  background-color: #fff;
  font-family: "Roboto-Regular";
  line-height: 1.2;
  color: #000;
  text-align: center;
  color: #393d42;
  display: flex;
  align-items: center;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  font-size: 13px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  position: absolute;
  opacity: 0;
  transition: opacity 1s;
}

@media (min-width: 414px) {
  .home .seccion_3 .contenedor_servicios .servicio .descripcion {
    top: 13px;
    left: 13px;
    width: calc(100% - 26px);
    height: calc(100% - 26px);
    font-size: 16.9px;
    padding-left: 13px;
    padding-right: 13px;
    margin-bottom: 26px;
  }
}

@media (min-width: 768px) {
  .home .seccion_3 .contenedor_servicios .servicio .descripcion {
    top: 24px;
    left: 24px;
    width: calc(100% - (20px * 2.4));
    height: calc(100% - (20px * 2.4));
    font-size: 24px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 48px;
  }
}

@media (min-width: 992px) {
  .home .seccion_3 .contenedor_servicios .servicio .descripcion {
    top: 15px;
    left: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    font-size: 13.2px;
    padding-left: 12.375px;
    padding-right: 12.375px;
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .home .seccion_3 .contenedor_servicios .servicio .descripcion {
    top: 15px;
    left: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_3 .contenedor_servicios .servicio .descripcion {
    top: 30px;
    left: 30px;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    font-size: 32px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.home .seccion_3 .contenedor_servicios .servicio .descripcion div {
  width: 100%;
}

.home .seccion_3 .contenedor_servicios .servicio .descripcion ul {
  padding-left: 1em;
}

.home .seccion_3 .contenedor_servicios .servicio .descripcion ul li {
  text-align: left;
}

.home .seccion_3 .contenedor_servicios .servicio:hover .descripcion {
  opacity: 0.9;
}

.home .seccion_3 .contenedor_servicios .contenedor_boton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 20px;
}

@media (min-width: 414px) {
  .home .seccion_3 .contenedor_servicios .contenedor_boton {
    margin-bottom: 26px;
  }
}

@media (min-width: 768px) {
  .home .seccion_3 .contenedor_servicios .contenedor_boton {
    margin-bottom: 48px;
  }
}

@media (min-width: 992px) {
  .home .seccion_3 .contenedor_servicios .contenedor_boton {
    margin-top: 16.5px;
    margin-bottom: 16.5px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_3 .contenedor_servicios .contenedor_boton {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_3 .contenedor_servicios .contenedor_boton {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.home .seccion_3 .contenedor_servicios .contenedor_boton .btn_ver_todos {
  display: block;
  background-color: #415d36;
  font-family: "Roboto-Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  width: 104px;
  height: 30px;
  border-radius: 14px;
  border: 2px solid #fff;
  font-size: 18px;
}

@media (min-width: 768px) {
  .home .seccion_3 .contenedor_servicios .contenedor_boton .btn_ver_todos {
    width: 249.6px;
    height: 72px;
    border-radius: 33.6px;
    font-size: 43.2px;
    border: 4px solid #fff;
  }
}

@media (min-width: 992px) {
  .home .seccion_3 .contenedor_servicios .contenedor_boton .btn_ver_todos {
    width: 85.8px;
    height: 24.75px;
    border-radius: 11.55px;
    font-size: 14.85px;
    border: 2px solid #fff;
  }
}

@media (min-width: 1200px) {
  .home .seccion_3 .contenedor_servicios .contenedor_boton .btn_ver_todos {
    width: 104px;
    height: 30px;
    border-radius: 14px;
    border: 2px solid #fff;
    font-size: 18px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_3 .contenedor_servicios .contenedor_boton .btn_ver_todos {
    width: 208px;
    height: 60px;
    border-radius: 28px;
    font-size: 36px;
    border: 4px solid #fff;
  }
}

.home .seccion_4 {
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  /*
		&::before {
			content: "";
			width: 100%;
			height: 100%;
			background-color: $second-color;
			mix-blend-mode: multiply;
			transform: translateZ(0);
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0.7;
		}
		*/
}

.home .seccion_4 .cabecera {
  background-image: url(/assets/img/home/seccion_4_cabecera_1.jpg);
  width: 100%;
  background-size: 100% auto;
  background-position: 50% 30%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin-top: 30px;
  margin-bottom: 20px;
}

@media (min-width: 414px) {
  .home .seccion_4 .cabecera {
    height: 156px;
    margin-top: 39px;
    margin-bottom: 26px;
  }
}

@media (min-width: 768px) {
  .home .seccion_4 .cabecera {
    height: 288px;
    margin-top: 72px;
    margin-bottom: 48px;
  }
}

@media (min-width: 992px) {
  .home .seccion_4 .cabecera {
    height: 148.5px;
    margin-top: 49.5px;
    margin-bottom: 41.25px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_4 .cabecera {
    height: 180px;
    margin-top: 60px;
    margin-bottom: 50px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_4 .cabecera {
    height: 360px;
    margin-top: 120px;
    margin-bottom: 100px;
  }
}

.home .seccion_4 .cabecera::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #415d36;
  mix-blend-mode: multiply;
  transform: translateZ(0);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.6;
}

.home .seccion_4 .cabecera h1 {
  color: #fff;
  line-height: 1;
  text-align: center;
  font-family: "Roboto-Bold";
  position: relative;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: 2;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 30px;
}

@media (min-width: 414px) {
  .home .seccion_4 .cabecera h1 {
    font-size: 39px;
  }
}

@media (min-width: 768px) {
  .home .seccion_4 .cabecera h1 {
    font-size: 72px;
  }
}

@media (min-width: 992px) {
  .home .seccion_4 .cabecera h1 {
    font-size: 49.5px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_4 .cabecera h1 {
    font-size: 60px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_4 .cabecera h1 {
    font-size: 120px;
  }
}

.home .seccion_4 .contenedor_proyectos {
  background-color: #415d36;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 414px) {
  .home .seccion_4 .contenedor_proyectos {
    padding-left: 26px;
    padding-right: 26px;
  }
}

@media (min-width: 768px) {
  .home .seccion_4 .contenedor_proyectos {
    padding-left: 48px;
    padding-right: 48px;
  }
}

@media (min-width: 992px) {
  .home .seccion_4 .contenedor_proyectos {
    padding-left: 49.5px;
    padding-right: 49.5px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_4 .contenedor_proyectos {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_4 .contenedor_proyectos {
    padding-left: 120px;
    padding-right: 120px;
  }
}

.home .seccion_4 .contenedor_proyectos h2 {
  width: 100%;
  color: #ffffff;
  font-family: "Roboto-Light";
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 25px;
  line-height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (min-width: 414px) {
  .home .seccion_4 .contenedor_proyectos h2 {
    font-size: 32.5px;
    line-height: 39px;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
  }
}

@media (min-width: 768px) {
  .home .seccion_4 .contenedor_proyectos h2 {
    font-size: 60px;
    line-height: 72px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@media (min-width: 992px) {
  .home .seccion_4 .contenedor_proyectos h2 {
    font-size: 33px;
    line-height: 49.5px;
    padding-top: 5.775px;
    padding-bottom: 9.075px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_4 .contenedor_proyectos h2 {
    font-size: 40px;
    line-height: 60px;
    padding-top: 7px;
    padding-bottom: 11px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_4 .contenedor_proyectos h2 {
    font-size: 80px;
    line-height: 120px;
    padding-top: 14px;
    padding-bottom: 22px;
  }
}

.home .seccion_4 .contenedor_proyectos .servicio {
  width: calc(100%);
  cursor: pointer;
  position: relative;
  height: 290px;
  margin-bottom: 15px;
  /*
				.descripcion {
					width: 100%;
					background-color: #fff;
					font-family: $Roboto-Regular;
					line-height: 1.2;
					color: #000;
					text-align: justify;
					color: $second-color;
					display: flex;
					align-items: center;
					height: 200px;
					font-size: 16px;
					padding-left: 20px;
					padding-right: 20px;
					border: 1px solid $main-color;
					@media (min-width: 992px) {
						//*.825;
						height: 200px * 0.825;
						font-size: 16px * 0.825;
						padding-left: 20px * 0.825;
						padding-right: 20px * 0.825;
						border: 1px solid $main-color;
					}
					@media (min-width: 1200px) {
						//
						height: 200px;
						font-size: 16px;
						padding-left: 20px;
						padding-right: 20px;
						border: 1px solid $main-color;
					}
					@media (min-width: 2400px) {
						//*2;
						height: 200px * 2;
						font-size: 16px * 2;
						padding-left: 20px * 2;
						padding-right: 20px * 2;
						border: 2px solid $main-color;
					}
				}
				*/
}

@media (min-width: 414px) {
  .home .seccion_4 .contenedor_proyectos .servicio {
    height: 377px;
    margin-bottom: 19.5px;
  }
}

@media (min-width: 768px) {
  .home .seccion_4 .contenedor_proyectos .servicio {
    height: 600px;
    margin-bottom: 36px;
  }
}

@media (min-width: 992px) {
  .home .seccion_4 .contenedor_proyectos .servicio {
    height: 330px;
    margin-bottom: 0px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_4 .contenedor_proyectos .servicio {
    height: 400px;
    margin-bottom: 0px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_4 .contenedor_proyectos .servicio {
    height: 800px;
    margin-bottom: 0px;
  }
}

.home .seccion_4 .contenedor_proyectos .servicio .imagen {
  width: 100%;
  background-color: #fff;
  background-size: cover;
  background-position: 50% 50%;
  height: calc(100%);
  margin-bottom: 15px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .seccion_4 .contenedor_proyectos .contenedor_boton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 20px;
}

@media (min-width: 414px) {
  .home .seccion_4 .contenedor_proyectos .contenedor_boton {
    margin-bottom: 26px;
  }
}

@media (min-width: 768px) {
  .home .seccion_4 .contenedor_proyectos .contenedor_boton {
    margin-bottom: 48px;
  }
}

@media (min-width: 992px) {
  .home .seccion_4 .contenedor_proyectos .contenedor_boton {
    margin-top: 49.5px;
    margin-bottom: 49.5px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_4 .contenedor_proyectos .contenedor_boton {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_4 .contenedor_proyectos .contenedor_boton {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

.home .seccion_4 .contenedor_proyectos .contenedor_boton .btn_ver_todos {
  display: block;
  background-color: #415d36;
  font-family: "Roboto-Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  width: 104px;
  height: 30px;
  border-radius: 14px;
  font-size: 18px;
  border: 2px solid #fff;
}

@media (min-width: 768px) {
  .home .seccion_4 .contenedor_proyectos .contenedor_boton .btn_ver_todos {
    width: 249.6px;
    height: 72px;
    border-radius: 33.6px;
    font-size: 43.2px;
    border: 4px solid #fff;
  }
}

@media (min-width: 992px) {
  .home .seccion_4 .contenedor_proyectos .contenedor_boton .btn_ver_todos {
    width: 85.8px;
    height: 24.75px;
    border-radius: 11.55px;
    font-size: 14.85px;
    border: 2px solid #fff;
  }
}

@media (min-width: 1200px) {
  .home .seccion_4 .contenedor_proyectos .contenedor_boton .btn_ver_todos {
    width: 104px;
    height: 30px;
    border-radius: 14px;
    font-size: 18px;
    border: 2px solid #fff;
  }
}

@media (min-width: 2400px) {
  .home .seccion_4 .contenedor_proyectos .contenedor_boton .btn_ver_todos {
    width: 208px;
    height: 60px;
    border-radius: 28px;
    font-size: 36px;
    border: 4px solid #fff;
  }
}

.home .seccion_5 {
  margin-top: 30px;
}

@media (min-width: 414px) {
  .home .seccion_5 {
    margin-top: 39px;
  }
}

@media (min-width: 768px) {
  .home .seccion_5 {
    margin-top: 72px;
  }
}

@media (min-width: 992px) {
  .home .seccion_5 {
    margin-top: 51.975px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_5 {
    margin-top: 63px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_5 {
    margin-top: 126px;
  }
}

.home .seccion_5 h1 {
  font-family: "Roboto-Black";
  line-height: 1.2;
  color: #393d42;
  width: 100%;
  margin-top: 0;
  text-align: center;
  font-size: 25px;
  padding-top: 5px;
  margin-bottom: 25px;
  border-bottom: 1px solid #415d36;
}

@media (min-width: 414px) {
  .home .seccion_5 h1 {
    font-size: 32.5px;
    padding-top: 6.5px;
    margin-bottom: 32.5px;
    border-bottom: 1px solid #415d36;
  }
}

@media (min-width: 768px) {
  .home .seccion_5 h1 {
    font-size: 60px;
    padding-top: 12px;
    margin-bottom: 60px;
    border-bottom: 2px solid #415d36;
  }
}

@media (min-width: 992px) {
  .home .seccion_5 h1 {
    font-size: 29.7px;
    padding-top: 4.125px;
    margin-bottom: 45.375px;
    border-bottom: 1px solid #415d36;
  }
}

@media (min-width: 1200px) {
  .home .seccion_5 h1 {
    font-size: 36px;
    padding-top: 5px;
    margin-bottom: 55px;
    border-bottom: 1px solid #415d36;
  }
}

@media (min-width: 2400px) {
  .home .seccion_5 h1 {
    font-size: 72px;
    padding-top: 10px;
    margin-bottom: 110px;
    border-bottom: 2px solid #415d36;
  }
}

.home .seccion_5 .contenedor_datos {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.home .seccion_5 .contenedor_datos .contenedor_dato {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  width: 45%;
}

@media (min-width: 414px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato {
    margin-bottom: 26px;
  }
}

@media (min-width: 768px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato {
    margin-bottom: 48px;
  }
}

@media (min-width: 992px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato {
    width: 47%;
    margin-bottom: 29.7px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato {
    margin-bottom: 36px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato {
    margin-bottom: 72px;
  }
}

.home .seccion_5 .contenedor_datos .contenedor_dato.contenedor_dato_largo {
  width: 55%;
}

@media (min-width: 992px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato.contenedor_dato_largo {
    width: 53%;
  }
}

.home .seccion_5 .contenedor_datos .contenedor_dato .icon {
  width: 30px;
  height: 30px;
}

@media (min-width: 414px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .icon {
    width: 39px;
    height: 39px;
  }
}

@media (min-width: 768px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .icon {
    width: 72px;
    height: 72px;
  }
}

@media (min-width: 992px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .icon {
    width: 42.075px;
    height: 42.075px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .icon {
    width: 51px;
    height: 51px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .icon {
    width: 102px;
    height: 102px;
  }
}

.home .seccion_5 .contenedor_datos .contenedor_dato .icon.icon-telefono {
  background-image: url(/assets/img/home/icon-telefono.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.home .seccion_5 .contenedor_datos .contenedor_dato .icon.icon-mail {
  background-image: url(/assets/img/home/icon-mail.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.home .seccion_5 .contenedor_datos .contenedor_dato .icon.icon-ubicacion {
  background-image: url(/assets/img/home/icon-ubicacion.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.home .seccion_5 .contenedor_datos .contenedor_dato .icon.icon-horario {
  background-image: url(/assets/img/home/icon-horario.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.home .seccion_5 .contenedor_datos .contenedor_dato .informacion {
  width: calc(100% - 30px);
  padding-left: 9px;
}

@media (min-width: 414px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .informacion {
    width: calc(100% - 30px * 1.3);
    padding-left: 11.7px;
  }
}

@media (min-width: 768px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .informacion {
    width: calc(100% - 30px * 2.4);
    padding-left: 21.6px;
  }
}

@media (min-width: 992px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .informacion {
    width: calc(100% - 51px * 0.825);
    padding-left: 17.325px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .informacion {
    width: calc(100% - 51px);
    padding-left: 21px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .informacion {
    width: calc(100% - 51px * 2);
    padding-left: 42px;
  }
}

.home .seccion_5 .contenedor_datos .contenedor_dato .informacion .nombre {
  font-family: "Roboto-Medium";
  font-size: 10px;
  line-height: 1.2;
  color: #000000;
}

@media (min-width: 414px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .informacion .nombre {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .informacion .nombre {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .informacion .nombre {
    font-size: 14.85px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .informacion .nombre {
    font-size: 18px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .informacion .nombre {
    font-size: 36px;
  }
}

.home .seccion_5 .contenedor_datos .contenedor_dato .informacion .dato {
  font-family: "Roboto-Light";
  line-height: 1.2;
  color: #000;
  font-size: 10px;
}

@media (min-width: 414px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .informacion .dato {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .informacion .dato {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .informacion .dato {
    font-size: 14.85px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .informacion .dato {
    font-size: 18px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_5 .contenedor_datos .contenedor_dato .informacion .dato {
    font-size: 36px;
  }
}

.home .seccion_5 .contenedor_datos .contenedor_dato .informacion .dato a {
  font-family: "Roboto-Light";
  color: #000;
}

.home .seccion_5 .imagen_contacto {
  width: 100%;
  height: 200px;
  border: 1px solid #000;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.home .seccion_5 .imagen_contacto video {
  width: 157%;
  height: auto;
  min-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* % of current element */
}

@media (min-width: 414px) {
  .home .seccion_5 .imagen_contacto {
    height: 260px;
  }
}

@media (min-width: 768px) {
  .home .seccion_5 .imagen_contacto {
    border: 2px solid #000;
    height: 480px;
  }
}

@media (min-width: 992px) {
  .home .seccion_5 .imagen_contacto {
    width: 377.025px;
    height: 326.7px;
    margin-left: 48.675px;
    border: 1px solid #000;
  }
}

@media (min-width: 1200px) {
  .home .seccion_5 .imagen_contacto {
    width: 457px;
    height: 396px;
    margin-left: 59px;
    border: 1px solid #000;
  }
}

@media (min-width: 2400px) {
  .home .seccion_5 .imagen_contacto {
    width: 914px;
    height: 792px;
    margin-left: 118px;
    border: 2px solid #000;
  }
}

.nosotros .seccion_1 h1 {
  font-family: "Roboto-Bold";
  line-height: 1.2;
  color: #393d42;
  text-align: center;
  font-size: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 414px) {
  .nosotros .seccion_1 h1 {
    font-size: 39px;
    padding-top: 26px;
    padding-bottom: 26px;
  }
}

@media (min-width: 768px) {
  .nosotros .seccion_1 h1 {
    font-size: 72px;
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media (min-width: 992px) {
  .nosotros .seccion_1 h1 {
    font-size: 49.5px;
    padding-top: 45.375px;
    padding-bottom: 37.95px;
  }
}

@media (min-width: 1200px) {
  .nosotros .seccion_1 h1 {
    font-size: 60px;
    padding-top: 55px;
    padding-bottom: 46px;
  }
}

@media (min-width: 2400px) {
  .nosotros .seccion_1 h1 {
    font-size: 120px;
    padding-top: 110px;
    padding-bottom: 92px;
  }
}

.nosotros .seccion_1 h1 span {
  color: #415d36;
}

.nosotros .seccion_1 .texto {
  color: #000000;
  line-height: 2;
  font-family: "Roboto-Light";
  letter-spacing: 0.025em;
  text-align: justify;
  font-size: 13px;
}

@media (min-width: 414px) {
  .nosotros .seccion_1 .texto {
    font-size: 16.9px;
  }
}

@media (min-width: 768px) {
  .nosotros .seccion_1 .texto {
    font-size: 31.2px;
  }
}

@media (min-width: 992px) {
  .nosotros .seccion_1 .texto {
    font-size: 14.85px;
  }
}

@media (min-width: 1200px) {
  .nosotros .seccion_1 .texto {
    font-size: 18px;
  }
}

@media (min-width: 2400px) {
  .nosotros .seccion_1 .texto {
    font-size: 36px;
  }
}

.nosotros .seccion_1 .texto span {
  color: #415d36;
  font-family: Roboto-Regular;
}

.nosotros .seccion_1 .texto .bold {
  color: #415d36;
  font-family: "Roboto-Bold";
  font-size: 200%;
  text-align: center;
}

.nosotros .seccion_1 .imagen {
  width: 100%;
  height: 200px;
  background-color: #ccc;
  background-image: url(/assets/img/nosotros/imagen_1.jpg);
  background-size: cover;
  background-position: 50% 50%;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 414px) {
  .nosotros .seccion_1 .imagen {
    margin-top: 26px;
    margin-bottom: 26px;
    height: 260px;
  }
}

@media (min-width: 768px) {
  .nosotros .seccion_1 .imagen {
    margin-top: 48px;
    margin-bottom: 48px;
    height: 480px;
  }
}

@media (min-width: 992px) {
  .nosotros .seccion_1 .imagen {
    margin-top: 41.25px;
    margin-bottom: 24.75px;
    height: 330px;
  }
}

@media (min-width: 1200px) {
  .nosotros .seccion_1 .imagen {
    margin-top: 50px;
    margin-bottom: 30px;
    height: 400px;
  }
}

@media (min-width: 2400px) {
  .nosotros .seccion_1 .imagen {
    margin-top: 100px;
    margin-bottom: 60px;
    height: 800px;
  }
}

.nosotros .seccion_2 {
  margin-top: 30px;
}

@media (min-width: 414px) {
  .nosotros .seccion_2 {
    margin-top: 39px;
  }
}

@media (min-width: 768px) {
  .nosotros .seccion_2 {
    margin-top: 72px;
  }
}

@media (min-width: 992px) {
  .nosotros .seccion_2 {
    margin-top: 33px;
  }
}

@media (min-width: 1200px) {
  .nosotros .seccion_2 {
    margin-top: 40px;
  }
}

@media (min-width: 2400px) {
  .nosotros .seccion_2 {
    margin-top: 80px;
  }
}

.nosotros .seccion_2 h1 {
  font-family: "Roboto-Bold";
  line-height: 1.2;
  color: #393d42;
  text-align: center;
  font-size: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 414px) {
  .nosotros .seccion_2 h1 {
    font-size: 39px;
    padding-top: 26px;
    padding-bottom: 26px;
  }
}

@media (min-width: 768px) {
  .nosotros .seccion_2 h1 {
    font-size: 72px;
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media (min-width: 992px) {
  .nosotros .seccion_2 h1 {
    font-size: 49.5px;
    padding-top: 45.375px;
    padding-bottom: 37.95px;
  }
}

@media (min-width: 1200px) {
  .nosotros .seccion_2 h1 {
    font-size: 60px;
    padding-top: 55px;
    padding-bottom: 46px;
  }
}

@media (min-width: 2400px) {
  .nosotros .seccion_2 h1 {
    font-size: 120px;
    padding-top: 110px;
    padding-bottom: 92px;
  }
}

.nosotros .seccion_2 h1 span {
  color: #415d36;
}

.nosotros .seccion_2 .texto {
  color: #000000;
  line-height: 2;
  font-family: "Roboto-Light";
  letter-spacing: 0.025em;
  text-align: justify;
  font-size: 13px;
}

@media (min-width: 414px) {
  .nosotros .seccion_2 .texto {
    font-size: 16.9px;
  }
}

@media (min-width: 768px) {
  .nosotros .seccion_2 .texto {
    font-size: 31.2px;
  }
}

@media (min-width: 992px) {
  .nosotros .seccion_2 .texto {
    font-size: 14.85px;
  }
}

@media (min-width: 1200px) {
  .nosotros .seccion_2 .texto {
    font-size: 18px;
  }
}

@media (min-width: 2400px) {
  .nosotros .seccion_2 .texto {
    font-size: 36px;
  }
}

.nosotros .seccion_2 .texto span {
  color: #415d36;
  font-family: Roboto-Regular;
}

.nosotros .seccion_2 .texto .bold {
  color: #415d36;
  font-family: "Roboto-Bold";
  font-size: 200%;
  text-align: center;
}

.nosotros .seccion_2 .imagen {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 100px;
  background-image: url(/assets/img/nosotros/logo.svg);
  background-size: auto 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

@media (min-width: 414px) {
  .nosotros .seccion_2 .imagen {
    margin-top: 26px;
    margin-bottom: 26px;
    height: 130px;
  }
}

@media (min-width: 768px) {
  .nosotros .seccion_2 .imagen {
    margin-top: 48px;
    margin-bottom: 48px;
    height: 240px;
  }
}

@media (min-width: 992px) {
  .nosotros .seccion_2 .imagen {
    margin-top: 41.25px;
    margin-bottom: 24.75px;
    height: 144.375px;
  }
}

@media (min-width: 1200px) {
  .nosotros .seccion_2 .imagen {
    height: 175px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
}

@media (min-width: 2400px) {
  .nosotros .seccion_2 .imagen {
    height: 350px;
    margin-top: 100px;
    margin-bottom: 60px;
  }
}

.nosotros .seccion_3 {
  margin-top: 60px;
  background-image: url(/assets/img/nosotros/fondo.jpg);
  background-size: cover;
  background-position: 50% 50%;
  padding-bottom: 150px;
}

@media (min-width: 414px) {
  .nosotros .seccion_3 {
    margin-top: 78px;
  }
}

@media (min-width: 768px) {
  .nosotros .seccion_3 {
    margin-top: 144px;
  }
}

@media (min-width: 992px) {
  .nosotros .seccion_3 {
    margin-top: 82.5px;
  }
}

@media (min-width: 1200px) {
  .nosotros .seccion_3 {
    margin-top: 100px;
  }
}

@media (min-width: 2400px) {
  .nosotros .seccion_3 {
    margin-top: 200px;
  }
}

.nosotros .seccion_3 h1 {
  color: #393d42;
  color: #415d36;
  line-height: 1;
  text-align: center;
  font-family: "Roboto-Bold";
  position: relative;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: 2;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 30px;
}

@media (min-width: 414px) {
  .nosotros .seccion_3 h1 {
    font-size: 39px;
    margin-top: 39px;
    margin-bottom: 39px;
  }
}

@media (min-width: 768px) {
  .nosotros .seccion_3 h1 {
    font-size: 72px;
    margin-top: 72px;
    margin-bottom: 72px;
  }
}

@media (min-width: 992px) {
  .nosotros .seccion_3 h1 {
    font-size: 49.5px;
    margin-top: 49.5px;
    margin-bottom: 49.5px;
  }
}

@media (min-width: 1200px) {
  .nosotros .seccion_3 h1 {
    font-size: 60px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

@media (min-width: 2400px) {
  .nosotros .seccion_3 h1 {
    font-size: 120px;
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

.nosotros .seccion_3 .cuadros {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}

@media (min-width: 992px) {
  .nosotros .seccion_3 .cuadros {
    justify-content: space-between;
  }
}

.nosotros .seccion_3 .cuadros .cuadro {
  width: 200px;
  height: 200px;
  margin-bottom: 30px;
  position: relative;
}

@media (min-width: 414px) {
  .nosotros .seccion_3 .cuadros .cuadro {
    width: 260px;
    height: 260px;
    margin-bottom: 39px;
  }
}

@media (min-width: 768px) {
  .nosotros .seccion_3 .cuadros .cuadro {
    width: 480px;
    height: 480px;
    margin-bottom: 72px;
  }
}

@media (min-width: 992px) {
  .nosotros .seccion_3 .cuadros .cuadro {
    width: 165px;
    height: 165px;
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .nosotros .seccion_3 .cuadros .cuadro {
    width: 200px;
    height: 200px;
  }
}

@media (min-width: 2400px) {
  .nosotros .seccion_3 .cuadros .cuadro {
    width: 400px;
    height: 400px;
  }
}

.nosotros .seccion_3 .cuadros .cuadro .texto {
  z-index: 3;
  color: #fff;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

@media (min-width: 414px) {
  .nosotros .seccion_3 .cuadros .cuadro .texto {
    font-size: 26px;
  }
}

@media (min-width: 768px) {
  .nosotros .seccion_3 .cuadros .cuadro .texto {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .nosotros .seccion_3 .cuadros .cuadro .texto {
    font-size: 16.5px;
  }
}

@media (min-width: 1200px) {
  .nosotros .seccion_3 .cuadros .cuadro .texto {
    font-size: 20px;
  }
}

@media (min-width: 2400px) {
  .nosotros .seccion_3 .cuadros .cuadro .texto {
    font-size: 40px;
  }
}

.nosotros .seccion_3 .cuadros .cuadro:after {
  content: "";
  background-color: rgba(65, 93, 54, 0.8);
  background-blend-mode: darken;
  transform: translateZ(0);
  mix-blend-mode: multiply;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.nosotros .seccion_3 .cuadros .cuadro:before {
  content: "";
  width: 90%;
  height: 90%;
  border: 1px solid #fff;
  z-index: 2;
  position: absolute;
  top: 5%;
  left: 5%;
}

.banner_contacto {
  height: 370px;
  height: 100%;
  padding-left: 25px;
  background-image: url(../img/contacto/fondo_1.webp);
  background-size: cover;
  background-position: 50% 70%;
  margin-bottom: 23px;
  padding-top: var(--altura_menu_xs);
  background-color: #ccc;
  background-blend-mode: multiply;
}

@media (min-width: 768px) {
  .banner_contacto {
    height: 384px;
    background-size: 125%;
    background-position: 50% 20%;
    margin-bottom: 58px;
  }
}

@media (min-width: 992px) {
  .banner_contacto {
    height: auto;
    background: none;
    padding-top: 0;
    padding-left: 0;
    overflow: hidden;
    margin-bottom: 5px;
  }
}

@media (min-width: 1200px) {
  .banner_contacto {
    margin-bottom: 6px;
  }
}

@media (min-width: 2400px) {
  .banner_contacto {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .banner_contacto > div,
  .banner_contacto > div > div,
  .banner_contacto > div > div > div {
    height: 100%;
  }
  .banner_contacto .banner_titulo {
    height: 100%;
    display: table !important;
  }
}

.banner_contacto .banner_titulo {
  color: #ffffff;
  font-family: "Roboto-Light";
  font-size: 17px;
}

@media (min-width: 360px) {
  .banner_contacto .banner_titulo {
    font-size: 23.63px;
  }
}

.banner_contacto .banner {
  background-image: url(../img/contacto/fondo_1.webp);
  width: 100%;
  height: 286px;
  background-size: cover;
  background-position: 50% 30%;
  position: relative;
  left: -15px;
}

@media (min-width: 1200px) {
  .banner_contacto .banner {
    height: 358px;
  }
}

@media (min-width: 2400px) {
  .banner_contacto .banner {
    height: 716px;
  }
}

.contenedor_datos_contacto {
  padding-top: 55px;
  padding-bottom: 55px;
  color: #666666;
  font-family: "Roboto-Regular";
  letter-spacing: 0.16em;
  font-size: 11.47px;
  line-break: 13.77px;
}

@media (min-width: 992px) {
  .contenedor_datos_contacto {
    padding-bottom: 0px;
  }
}

@media (min-width: 1200px) {
  .contenedor_datos_contacto {
    padding-top: 66px;
    font-size: 14.34px;
    line-break: 17.21px;
    padding-left: 10px;
  }
}

@media (min-width: 2400px) {
  .contenedor_datos_contacto {
    padding-top: 132px;
    font-size: 28.68px;
    line-break: 34.42px;
    padding-left: 40px;
  }
}

.contenedor_datos_contacto > div {
  height: 35px;
  margin-bottom: 20px;
  text-align: center;
}

@media (min-width: 768px) {
  .contenedor_datos_contacto > div {
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .contenedor_datos_contacto > div {
    height: 40px;
    margin-bottom: 25px;
  }
}

@media (min-width: 2400px) {
  .contenedor_datos_contacto > div {
    height: 80px;
    margin-bottom: 50px;
  }
}

.contenedor_datos_contacto div {
  vertical-align: top;
}

.contenedor_datos_contacto div.naranja {
  color: #393d42;
}

@media (min-width: 768px) {
  .contenedor_datos_contacto div.inline-block:not(.naranja) {
    width: calc(100% - 160px);
  }
}

@media (min-width: 2400px) {
  .contenedor_datos_contacto div.inline-block:not(.naranja) {
    width: calc(100% - 320px);
  }
}

.contenedor_datos_contacto a {
  color: #666666;
}

.pregunta__titulo {
  font-size: 19.49px;
  line-height: 23.35px;
  letter-spacing: 0.053em;
  color: #000000;
  font-family: "Roboto-Regular";
  margin-top: 43px;
}

@media (min-width: 360px) {
  .pregunta__titulo {
    font-size: 21.85px;
    line-height: 26.21px;
  }
}

@media (min-width: 768px) {
  .pregunta__titulo {
    color: #ffffff;
  }
}

@media (min-width: 992px) {
  .pregunta__titulo {
    font-size: 21.08px;
    line-height: 25.29px;
  }
}

@media (min-width: 1200px) {
  .pregunta__titulo {
    margin-top: 71px;
    font-size: 26.35px;
    line-height: 31.63px;
  }
}

@media (min-width: 2400px) {
  .pregunta__titulo {
    margin-top: 174px;
    font-size: 52.7px;
    line-height: 63.26px;
  }
}

.pregunta__descripcion {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.053em;
  color: #000000;
  font-family: "Roboto-Regular";
  margin-top: 15px;
  margin-bottom: 44.5px;
}

@media (min-width: 360px) {
  .pregunta__descripcion {
    font-size: 13.47px;
    line-height: 21.33px;
  }
}

@media (min-width: 768px) {
  .pregunta__descripcion {
    font-size: 13.28px;
    line-height: 15.94px;
    color: #ffffff;
  }
}

@media (min-width: 992px) {
  .pregunta__descripcion {
    font-size: 14.39px;
    line-height: 17.27px;
  }
}

@media (min-width: 1200px) {
  .pregunta__descripcion {
    font-size: 18px;
    line-height: 21.59px;
    margin-top: 22px;
    margin-bottom: 75px;
  }
}

@media (min-width: 2400px) {
  .pregunta__descripcion {
    font-size: 36px;
    line-height: 43.18px;
    margin-top: 44px;
    margin-bottom: 150px;
  }
}

.pregunta__titulo,
.pregunta__descripcion {
  width: 290px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 360px) {
  .pregunta__titulo,
  .pregunta__descripcion {
    width: 315px;
  }
}

@media (min-width: 768px) {
  .pregunta__titulo,
  .pregunta__descripcion {
    width: auto;
  }
}

.contacto__clientes {
  padding-top: 15px;
  padding-bottom: 15px;
  display: block;
}

@media (min-width: 768px) {
  .contacto__clientes {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .contacto__clientes {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .contacto__clientes {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (min-width: 2400px) {
  .contacto__clientes {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.contacto__clientes h1 {
  color: #393d42;
  text-align: center;
  font-family: "Roboto-Light";
  font-size: 24px;
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .contacto__clientes h1 {
    font-size: 35px;
    padding-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .contacto__clientes h1 {
    font-size: 50px;
    padding-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  .contacto__clientes h1 {
    font-size: 77px;
    padding-bottom: 30px;
  }
}

@media (min-width: 2400px) {
  .contacto__clientes h1 {
    font-size: 154px;
    padding-bottom: 60px;
  }
}

.contacto__clientes .logos {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
}

.contacto__clientes .logos .logo {
  width: 40%;
  display: block;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .contacto__clientes .logos .logo {
    width: 27%;
  }
}

@media (min-width: 992px) {
  .contacto__clientes .logos .logo {
    width: 15%;
  }
}

.servicios {
  min-height: 600px;
}

@media (min-width: 414px) {
  .servicios {
    min-height: 780px;
  }
}

@media (min-width: 768px) {
  .servicios {
    min-height: 1440px;
  }
}

@media (min-width: 992px) {
  .servicios {
    min-height: 495px;
  }
}

@media (min-width: 1200px) {
  .servicios {
    min-height: 720px;
  }
}

@media (min-width: 2400px) {
  .servicios {
    min-height: 1200px;
  }
}

.servicios .seccion_principal {
  background-color: #000;
  height: 100%;
  background-image: url(/assets/img/servicios/fondo.png);
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  min-height: 400px;
}

@media (min-width: 992px) {
  .servicios .seccion_principal {
    min-height: 660px;
  }
}

@media (min-width: 1200px) {
  .servicios .seccion_principal {
    min-height: 800px;
  }
}

@media (min-width: 2400px) {
  .servicios .seccion_principal {
    min-height: 1600px;
  }
}

.servicios .seccion_principal .overlay {
  /*
			background-color: rgba($main-color, 0.7);
			mix-blend-mode: multiply;
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 1;
			*/
}

.servicios .seccion_principal .container {
  position: relative;
  z-index: 2;
  padding-bottom: 100px;
}

.servicios .seccion_principal .container a {
  display: block;
}

@media (min-width: 992px) {
  .servicios .seccion_principal .container {
    padding-bottom: 0px;
    padding-top: 82.5px;
  }
}

@media (min-width: 1200px) {
  .servicios .seccion_principal .container {
    padding-top: 100px;
  }
}

@media (min-width: 2400px) {
  .servicios .seccion_principal .container {
    padding-top: 200px;
  }
}

.servicios .seccion_principal .container h1 {
  color: #fff;
  text-align: center;
  font-family: "Roboto-Black";
  font-size: 35px;
  margin-bottom: 30px;
}

@media (min-width: 414px) {
  .servicios .seccion_principal .container h1 {
    font-size: 45.5px;
    margin-bottom: 39px;
  }
}

@media (min-width: 768px) {
  .servicios .seccion_principal .container h1 {
    font-size: 84px;
    margin-bottom: 72px;
  }
}

@media (min-width: 992px) {
  .servicios .seccion_principal .container h1 {
    font-size: 70.125px;
    margin-bottom: 24.75px;
  }
}

@media (min-width: 1200px) {
  .servicios .seccion_principal .container h1 {
    font-size: 85px;
    margin-bottom: 30px;
  }
}

@media (min-width: 2400px) {
  .servicios .seccion_principal .container h1 {
    font-size: 170px;
    margin-bottom: 60px;
  }
}

.servicios .seccion_principal .container h2 {
  color: #fff;
  text-align: center;
  font-family: "Roboto-Black";
  font-size: 23px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  color: white;
  background-color: #415d36;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

@media (min-width: 414px) {
  .servicios .seccion_principal .container h2 {
    font-size: 39px;
    margin-top: 26px;
    margin-bottom: 26px;
  }
}

@media (min-width: 768px) {
  .servicios .seccion_principal .container h2 {
    font-size: 72px;
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

@media (min-width: 992px) {
  .servicios .seccion_principal .container h2 {
    font-size: 28.875px;
    margin-top: 0;
    margin-bottom: 36.3px;
    width: 297px;
    height: 66px;
    border-radius: 16.5px;
  }
}

@media (min-width: 1200px) {
  .servicios .seccion_principal .container h2 {
    font-size: 35px;
    margin-bottom: 44px;
    width: 360px;
    height: 80px;
    border-radius: 20px;
  }
}

@media (min-width: 2400px) {
  .servicios .seccion_principal .container h2 {
    font-size: 70px;
    margin-bottom: 88px;
    width: 720px;
    height: 160px;
    border-radius: 40px;
  }
}

.servicios .seccion_principal .container .cuadro {
  position: relative;
  width: 141px;
  height: 141px;
}

@media (min-width: 414px) {
  .servicios .seccion_principal .container .cuadro {
    width: 183.3px;
    height: 183.3px;
  }
}

@media (min-width: 768px) {
  .servicios .seccion_principal .container .cuadro {
    width: 338.4px;
    height: 338.4px;
  }
}

@media (min-width: 992px) {
  .servicios .seccion_principal .container .cuadro {
    width: 236.775px;
    height: 236.775px;
    margin-bottom: 16.5px;
  }
}

@media (min-width: 1200px) {
  .servicios .seccion_principal .container .cuadro {
    width: 287px;
    height: 287px;
    margin-bottom: 20px;
  }
}

@media (min-width: 2400px) {
  .servicios .seccion_principal .container .cuadro {
    width: 574px;
    height: 574px;
    margin-bottom: 40px;
  }
}

.servicios .seccion_principal .container .cuadro.cuadro_es {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.servicios .seccion_principal .container .cuadro.cuadro_en {
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
}

.servicios .seccion_principal .container .cuadro .contenido {
  background-color: #393d42;
  width: 100%;
  height: 100%;
  z-index: 3;
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 50%;
}

.servicios .seccion_principal .container .recuadro {
  width: 100%;
  background-color: rgba(57, 61, 66, 0.7);
  color: #fff;
  font-family: "Roboto-Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  height: 50px;
  margin-top: 30px;
}

@media (min-width: 414px) {
  .servicios .seccion_principal .container .recuadro {
    font-size: 23.4px;
    height: 65px;
    margin-top: 39px;
  }
}

@media (min-width: 768px) {
  .servicios .seccion_principal .container .recuadro {
    font-size: 43.2px;
    height: 120px;
    margin-top: 72px;
  }
}

@media (min-width: 992px) {
  .servicios .seccion_principal .container .recuadro {
    font-size: 19.8px;
    height: 57.75px;
    margin-top: 37.125px;
  }
}

@media (min-width: 1200px) {
  .servicios .seccion_principal .container .recuadro {
    font-size: 24px;
    height: 70px;
    margin-top: 45px;
  }
}

@media (min-width: 2400px) {
  .servicios .seccion_principal .container .recuadro {
    font-size: 48px;
    height: 140px;
    margin-top: 90px;
  }
}

.contenido_single {
  padding-top: var(--altura_menu_xs);
  position: relative;
}

@media (min-width: 768px) {
  .contenido_single {
    padding-top: 158px;
  }
}

@media (min-width: 992px) {
  .contenido_single {
    padding-top: 75px;
  }
}

@media (min-width: 1200px) {
  .contenido_single {
    padding-top: 80px;
  }
}

@media (min-width: 2400px) {
  .contenido_single {
    padding-top: 160px;
  }
}

.contenido_single .imagen_servicio {
  margin: 50px auto 22px;
  width: 240px;
  height: 240px;
  position: relative;
}

@media (min-width: 360px) {
  .contenido_single .imagen_servicio {
    width: 270px;
    height: 270px;
    margin: 55px auto 24px;
  }
}

@media (min-width: 768px) {
  .contenido_single .imagen_servicio {
    width: 290px;
    height: 290px;
    margin: 0px auto 25px;
  }
}

@media (min-width: 1200px) {
  .contenido_single .imagen_servicio {
    width: 360px;
    height: 360px;
    margin: 0px auto 30px;
  }
}

@media (min-width: 2400px) {
  .contenido_single .imagen_servicio {
    width: 720px;
    height: 720px;
    margin: 0px auto 60px;
  }
}

.contenido_single .imagen_servicio::before, .contenido_single .imagen_servicio::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

.contenido_single .imagen_servicio::before {
  width: 189px;
  height: 189px;
  border: 10px solid #efefef;
}

@media (min-width: 360px) {
  .contenido_single .imagen_servicio::before {
    width: 213px;
    height: 213px;
    border: 12px solid #efefef;
  }
}

@media (min-width: 768px) {
  .contenido_single .imagen_servicio::before {
    width: 226px;
    height: 226px;
    border: 12px solid #efefef;
  }
}

@media (min-width: 1200px) {
  .contenido_single .imagen_servicio::before {
    width: 280px;
    height: 280px;
    border: 15px solid #efefef;
  }
}

@media (min-width: 2400px) {
  .contenido_single .imagen_servicio::before {
    width: 560px;
    height: 560px;
    border: 30px solid #efefef;
  }
}

.contenido_single .imagen_servicio::after {
  width: 114px;
  height: 114px;
  background-color: #efefef;
}

@media (min-width: 360px) {
  .contenido_single .imagen_servicio::after {
    width: 128px;
    height: 128px;
  }
}

@media (min-width: 768px) {
  .contenido_single .imagen_servicio::after {
    width: 136px;
    height: 136px;
  }
}

@media (min-width: 1200px) {
  .contenido_single .imagen_servicio::after {
    width: 168px;
    height: 168px;
  }
}

@media (min-width: 2400px) {
  .contenido_single .imagen_servicio::after {
    width: 336px;
    height: 336px;
  }
}

.contenido_single .imagen_servicio .imagen {
  background-size: cover;
  width: 189px;
  height: 189px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@media (min-width: 360px) {
  .contenido_single .imagen_servicio .imagen {
    width: 213px;
    height: 213px;
  }
}

@media (min-width: 768px) {
  .contenido_single .imagen_servicio .imagen {
    width: 226px;
    height: 226px;
  }
}

@media (min-width: 1200px) {
  .contenido_single .imagen_servicio .imagen {
    width: 280px;
    height: 280px;
  }
}

@media (min-width: 2400px) {
  .contenido_single .imagen_servicio .imagen {
    width: 560px;
    height: 560px;
  }
}

.contenido_single .descripcion {
  font-family: "Roboto-Regular";
  color: #333333;
  margin: auto;
  text-align: justify;
  line-height: 1.4;
  letter-spacing: 0.053em;
  margin-bottom: 30px;
  /*@media (min-width: 992px){
			display: inline-block;
			width: calc(100% - 140px);
			vertical-align: top;
		}*/
}

@media (min-width: 768px) {
  .contenido_single .descripcion {
    width: 655px;
  }
}

@media (min-width: 992px) {
  .contenido_single .descripcion {
    width: 790px;
    margin-bottom: 40px;
  }
}

@media (min-width: 1200px) {
  .contenido_single .descripcion {
    width: 980px;
    margin-bottom: 50px;
  }
}

@media (min-width: 2400px) {
  .contenido_single .descripcion {
    font-size: 200%;
    width: 1960px;
    margin-bottom: 100px;
  }
}

.contenido_single .descripcion h3 {
  color: #393d42;
}

.contenido_single .descripcion .imagen {
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width: 2400px) {
  .contenido_single .descripcion .imagen {
    height: 600px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.contenido_single .datos_servicio {
  line-height: 1.2;
  letter-spacing: 0.053em;
}

@media (min-width: 992px) {
  .contenido_single .datos_servicio .contenido {
    width: calc(100% - 140px);
    vertical-align: top;
    display: inline-block;
  }
}

@media (min-width: 1200px) {
  .contenido_single .datos_servicio .contenido {
    width: calc(100% - 180px);
  }
}

@media (min-width: 2400px) {
  .contenido_single .datos_servicio .contenido {
    width: calc(100% - 360px);
  }
}

.contenido_single .datos_servicio .titulo {
  font-family: "Roboto-Light";
  color: #393d42;
  font-size: 47.5px;
  margin-bottom: 35px;
}

@media (min-width: 360px) {
  .contenido_single .datos_servicio .titulo {
    font-size: 53.55px;
    margin-bottom: 37px;
  }
}

@media (min-width: 768px) {
  .contenido_single .datos_servicio .titulo {
    font-size: 47.5px;
    margin-top: 40px;
    margin-bottom: 38px;
  }
}

@media (min-width: 1200px) {
  .contenido_single .datos_servicio .titulo {
    font-size: 57.5px;
    margin-top: 36px;
    margin-bottom: 44px;
  }
}

@media (min-width: 2400px) {
  .contenido_single .datos_servicio .titulo {
    font-size: 115px;
    margin-top: 12px;
    margin-bottom: 88px;
  }
}

.contenido_single .datos_servicio .cuerpo {
  font-family: "Roboto-Regular";
  color: #333333;
  text-align: justify;
  /*@media (min-width: 992px){
				display: inline-block;
				width: calc(100% - 140px);
				vertical-align: top;
			}*/
  margin-bottom: 30px;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .contenido_single .datos_servicio .cuerpo {
    margin-bottom: 0px;
    text-align: left;
  }
}

@media (min-width: 2400px) {
  .contenido_single .datos_servicio .cuerpo {
    font-size: 200%;
  }
}

.contenido_single .datos_servicio .cambiar_servicio {
  display: none;
}

@media (min-width: 992px) {
  .contenido_single .datos_servicio .cambiar_servicio {
    width: 134px;
    height: 100px;
    vertical-align: top;
    display: inline-block;
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .contenido_single .datos_servicio .cambiar_servicio {
    width: 174px;
    height: 100px;
  }
}

@media (min-width: 2400px) {
  .contenido_single .datos_servicio .cambiar_servicio {
    width: 348px;
    height: 200px;
  }
}

.contenido_single .datos_servicio .cambiar_servicio a {
  width: 14px;
  height: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  margin: 10px 9px 0;
}

@media (min-width: 1200px) {
  .contenido_single .datos_servicio .cambiar_servicio a {
    width: 16px;
    height: 30px;
    margin: 13px 11px 0;
  }
}

@media (min-width: 2400px) {
  .contenido_single .datos_servicio .cambiar_servicio a {
    width: 32px;
    height: 60px;
    margin: 26px 22px 0;
  }
}

.contenido_single .datos_servicio .cambiar_servicio a.prev {
  background-image: url(../img/single/prev.svg);
}

.contenido_single .datos_servicio .cambiar_servicio a.prev:hover {
  background-image: url(../img/single/prev-active.svg);
}

.contenido_single .datos_servicio .cambiar_servicio a.next {
  background-image: url(../img/single/next.svg);
}

.contenido_single .datos_servicio .cambiar_servicio a.next:hover {
  background-image: url(../img/single/next-active.svg);
}

.contenido_single .datos_servicio .botones {
  width: 290px;
  margin: 42px auto 62px;
  text-align: center;
}

@media (min-width: 360px) {
  .contenido_single .datos_servicio .botones {
    width: 330px;
    margin: 76px auto 69px;
  }
}

@media (min-width: 768px) {
  .contenido_single .datos_servicio .botones {
    width: 250px;
    margin: 50px auto 106px;
  }
}

@media (min-width: 1200px) {
  .contenido_single .datos_servicio .botones {
    width: 310px;
    margin: 72px auto 196px;
  }
}

@media (min-width: 2400px) {
  .contenido_single .datos_servicio .botones {
    width: 620px;
    margin: 144px auto 392px;
  }
}

.contenido_single .datos_servicio .botones a {
  display: inline-block;
  width: 132px;
  height: 77px;
  font-size: 12px;
  letter-spacing: 0;
  font-family: "Roboto-Regular";
  border: 1px solid #393d42;
  color: #262261;
  text-decoration: none;
}

@media (min-width: 360px) {
  .contenido_single .datos_servicio .botones a {
    width: 148px;
    height: 87px;
    font-size: 13.57px;
  }
}

@media (min-width: 768px) {
  .contenido_single .datos_servicio .botones a {
    width: 113px;
    height: 67px;
    font-size: 10.41px;
  }
}

@media (min-width: 1200px) {
  .contenido_single .datos_servicio .botones a {
    width: 142px;
    height: 83px;
    font-size: 13px;
  }
}

@media (min-width: 2400px) {
  .contenido_single .datos_servicio .botones a {
    width: 284px;
    height: 166px;
    font-size: 26px;
    border: 2px solid #393d42;
  }
}

.contenido_single .datos_servicio .botones a:first-child {
  margin-right: 10px;
}

@media (min-width: 360px) {
  .contenido_single .datos_servicio .botones a:first-child {
    margin-right: 12px;
  }
}

@media (min-width: 768px) {
  .contenido_single .datos_servicio .botones a:first-child {
    margin-right: 10px;
  }
}

@media (min-width: 1200px) {
  .contenido_single .datos_servicio .botones a:first-child {
    margin-right: 12px;
  }
}

@media (min-width: 2400px) {
  .contenido_single .datos_servicio .botones a:first-child {
    margin-right: 24px;
  }
}

.contenido_single .datos_servicio .botones a:last-child {
  margin-left: 10px;
}

@media (min-width: 360px) {
  .contenido_single .datos_servicio .botones a:last-child {
    margin-left: 12px;
  }
}

@media (min-width: 768px) {
  .contenido_single .datos_servicio .botones a:last-child {
    margin-left: 10px;
  }
}

@media (min-width: 1200px) {
  .contenido_single .datos_servicio .botones a:last-child {
    margin-left: 12px;
  }
}

@media (min-width: 2400px) {
  .contenido_single .datos_servicio .botones a:last-child {
    margin-left: 24px;
  }
}

.contenido_single .datos_servicio .botones a img {
  width: 27px;
  height: 16px;
  margin: 19px auto 6px;
  display: block;
}

@media (min-width: 360px) {
  .contenido_single .datos_servicio .botones a img {
    width: 30px;
    height: 18px;
    margin: 22px auto 6px;
  }
}

@media (min-width: 768px) {
  .contenido_single .datos_servicio .botones a img {
    width: 25px;
    height: 14px;
    margin: 16px auto 5px;
  }
}

@media (min-width: 1200px) {
  .contenido_single .datos_servicio .botones a img {
    width: 29px;
    height: 17px;
    margin: 21px auto 6px;
  }
}

@media (min-width: 2400px) {
  .contenido_single .datos_servicio .botones a img {
    width: 58px;
    height: 34px;
    margin: 42px auto 12px;
  }
}

.contenido_single .datos_servicio .botones a:hover {
  color: #262261 !important;
}

.cuadro_single a {
  text-decoration: none;
}

.cuadro_single button {
  width: 140px;
  height: 29px;
  display: block;
  margin: 0 auto;
  background-color: transparent;
  border: 1px solid #ffffff;
  font-size: 12px;
  line-height: 14.38px;
  font-family: "Roboto-Regular";
  color: #ffffff;
  padding-top: 1px;
}

@media (min-width: 360px) {
  .cuadro_single button {
    width: 158px;
    height: 32.5px;
    font-size: 13.49px;
    line-height: 16.19px;
    padding-top: 2px;
  }
}

@media (min-width: 768px) {
  .cuadro_single button {
    width: 167px;
    height: 34px;
    font-size: 14.29px;
    line-height: 17.15px;
    padding-top: 2px;
  }
}

@media (min-width: 992px) {
  .cuadro_single button {
    width: 147px;
    height: 30.39px;
    font-size: 12.66px;
    line-height: 15.2px;
    padding-top: 2px;
  }
}

@media (min-width: 1200px) {
  .cuadro_single button {
    width: 185px;
    height: 38px;
    font-size: 15.84px;
    line-height: 19px;
    padding-top: 2px;
  }
}

@media (min-width: 2400px) {
  .cuadro_single button {
    width: 370px;
    height: 76px;
    font-size: 31.68px;
    line-height: 38px;
    padding-top: 4px;
  }
}

.cuadro_single button:hover {
  background-color: rgba(140, 193, 72, 0.7);
  color: #ffffff;
  border: none;
}

.cuadro_single .cuadro {
  background-color: rgba(0, 0, 0, 0.41);
  background-blend-mode: multiply;
  height: 220px;
  margin-top: 6px;
}

@media (min-width: 360px) {
  .cuadro_single .cuadro {
    height: 247px;
    margin-top: 7px;
  }
}

@media (min-width: 768px) {
  .cuadro_single .cuadro {
    height: 339px;
    margin-top: 0px;
  }
}

@media (min-width: 992px) {
  .cuadro_single .cuadro {
    height: 300px;
    margin-top: 0px;
  }
}

@media (min-width: 1200px) {
  .cuadro_single .cuadro {
    height: 376px;
  }
}

@media (min-width: 2400px) {
  .cuadro_single .cuadro {
    height: 752px;
  }
}

.cuadro_single .cuadro.cuadro-servicios {
  background-image: url(/assets/img/nosotros/cuadro-servicios.webp);
  background-size: cover;
  background-position: 50% 50%;
  display: table;
}

@media (min-width: 768px) and (max-width: 991px) {
  .cuadro_single .cuadro.cuadro-servicios {
    margin-bottom: 4px;
    height: 325px;
  }
}

.cuadro_single .cuadro.cuadro-nosotros {
  background-image: url(/assets/img/nosotros/cuadro-contacto.webp);
  background-size: cover;
  background-position: 50% 50%;
  display: table;
  background-color: rgba(0, 0, 0, 0.26);
}

@media (min-width: 768px) {
  .cuadro_single .cuadro.cuadro-nosotros {
    border-right: 2px solid white;
  }
}

@media (min-width: 992px) {
  .cuadro_single .cuadro.cuadro-nosotros {
    border-right: 4px solid white;
  }
}

@media (min-width: 2400px) {
  .cuadro_single .cuadro.cuadro-nosotros {
    border-right: 8px solid white;
  }
}

.cuadro_single .cuadro.cuadro-inicio {
  background-image: url(/assets/img/nosotros/cuadro-inicio.webp);
  background-size: cover;
  background-position: 50% 50%;
  display: table;
}

@media (min-width: 768px) {
  .cuadro_single .cuadro.cuadro-inicio {
    border-left: 2px solid white;
  }
}

@media (min-width: 992px) {
  .cuadro_single .cuadro.cuadro-inicio {
    border-right: 4px solid white;
    border-left: 0;
  }
}

@media (min-width: 2400px) {
  .cuadro_single .cuadro.cuadro-inicio {
    border-right: 8px solid white;
  }
}

.galeria_single {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .galeria_single {
    width: 655px;
    margin: auto;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .galeria_single {
    width: 790px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .galeria_single {
    width: 980px;
    margin-bottom: 40px;
  }
}

@media (min-width: 2400px) {
  .galeria_single {
    font-size: 200%;
    width: 1960px;
    margin-bottom: 80px;
  }
}

.galeria_single .cuadro {
  width: 280px;
  height: 280px;
  margin-left: auto;
  margin-bottom: 15px;
  margin-right: auto;
  background-size: cover;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .galeria_single .cuadro {
    width: 198px;
    height: 198px;
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .galeria_single .cuadro {
    width: 243px;
    height: 243px;
  }
}

@media (min-width: 1200px) {
  .galeria_single .cuadro {
    width: 305px;
    height: 305px;
  }
}

@media (min-width: 2400px) {
  .galeria_single .cuadro {
    width: 610px;
    height: 610px;
  }
}

/*
@import "pages/terminos"; 
*/
