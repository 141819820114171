.banner_contacto {
	height: 370px;
	height: 100%;
	padding-left: 25px;
	background-image: url(../img/contacto/fondo_1.webp);
	background-size: cover;
	background-position: 50% 70%;
	margin-bottom: 23px;
	padding-top: var(--altura_menu_xs);
	background-color: #ccc;
	background-blend-mode: multiply;

	@media (min-width: 768px) {
		height: 384px;
		background-size: 125%;
		background-position: 50% 20%;
		margin-bottom: 58px;
	}
	@media (min-width: 992px) {
		height: auto;
		background: none;
		padding-top: 0;
		padding-left: 0;
		overflow: hidden;
		margin-bottom: 5px;
	}
	@media (min-width: 1200px) {
		margin-bottom: 6px;
	}
	@media (min-width: 2400px) {
		margin-bottom: 12px;
	}

	@media (max-width: 767px) {
		> div,
		> div > div,
		> div > div > div {
			height: 100%;
		}

		.banner_titulo {
			height: 100%;
			display: table !important;
		}
	}

	.banner_titulo {
		color: $white-color;
		font-family: $Roboto-Light;
		font-size: 17px;
		@media (min-width: 360px) {
			font-size: 23.63px;
		}
	}

	.banner {
		background-image: url(../img/contacto/fondo_1.webp);
		width: 100%;
		height: 286px;
		background-size: cover;
		background-position: 50% 30%;
		position: relative;
		left: -15px;
		@media (min-width: 1200px) {
			height: 358px;
		}
		@media (min-width: 2400px) {
			height: 716px;
		}
	}
}

.contenedor_datos_contacto {
	padding-top: 55px;
	padding-bottom: 55px;
	color: $thirt-color;
	font-family: $Roboto-Regular;
	letter-spacing: 0.16em;
	font-size: 11.47px;
	line-break: 13.77px;

	@media (min-width: 992px) {
		padding-bottom: 0px;
	}
	@media (min-width: 1200px) {
		padding-top: 66px;
		font-size: 14.34px;
		line-break: 17.21px;
		padding-left: 10px;
	}
	@media (min-width: 2400px) {
		padding-top: 132px;
		font-size: 28.68px;
		line-break: 34.42px;
		padding-left: 40px;
	}

	> div {
		height: 35px;
		margin-bottom: 20px;
		text-align: center;

		@media (min-width: 768px) {
			text-align: left;
		}
		@media (min-width: 1200px) {
			height: 40px;
			margin-bottom: 25px;
		}
		@media (min-width: 2400px) {
			height: 80px;
			margin-bottom: 50px;
		}
	}
	div {
		vertical-align: top;
	}
	div.naranja {
		color: $second-color;
	}
	div.inline-block:not(.naranja) {
		@media (min-width: 768px) {
			width: calc(100% - 160px);
		}

		@media (min-width: 2400px) {
			width: calc(100% - 320px);
		}
	}
	a {
		color: $thirt-color;
	}
}
.pregunta__titulo {
	font-size: 19.49px;
	line-height: 23.35px;
	letter-spacing: 0.053em;
	color: $black-color;
	font-family: $Roboto-Regular;
	margin-top: 43px;
	@media (min-width: 360px) {
		font-size: 21.85px;
		line-height: 26.21px;
	}
	@media (min-width: 768px) {
		color: $white-color;
	}
	@media (min-width: 992px) {
		font-size: 21.08px;
		line-height: 25.29px;
	}
	@media (min-width: 1200px) {
		margin-top: 71px;
		font-size: 26.35px;
		line-height: 31.63px;
	}
	@media (min-width: 2400px) {
		margin-top: 174px;
		font-size: 52.7px;
		line-height: 63.26px;
	}
}

.pregunta__descripcion {
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.053em;
	color: $black-color;
	font-family: $Roboto-Regular;
	margin-top: 15px;
	margin-bottom: 44.5px;
	@media (min-width: 360px) {
		font-size: 13.47px;
		line-height: 21.33px;
	}
	@media (min-width: 768px) {
		font-size: 13.28px;
		line-height: 15.94px;
		color: $white-color;
	}
	@media (min-width: 992px) {
		font-size: 14.39px;
		line-height: 17.27px;
	}
	@media (min-width: 1200px) {
		font-size: 18px;
		line-height: 21.59px;
		margin-top: 22px;
		margin-bottom: 75px;
	}
	@media (min-width: 2400px) {
		font-size: 36px;
		line-height: 43.18px;
		margin-top: 44px;
		margin-bottom: 150px;
	}
}

.pregunta__titulo,
.pregunta__descripcion {
	width: 290px;
	margin-left: auto;
	margin-right: auto;
	@media (min-width: 360px) {
		width: 315px;
	}
	@media (min-width: 768px) {
		width: auto;
	}
}

.contacto__clientes {
	padding-top: 15px;
	padding-bottom: 15px;
	display: block;

	@media (min-width: 768px) {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	@media (min-width: 992px) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	@media (min-width: 1200px) {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	@media (min-width: 2400px) {
		padding-top: 100px;
		padding-bottom: 100px;
	}

	h1 {
		color: $second-color;
		text-align: center;
		font-family: $Roboto-Light;
		font-size: 24px;
		padding-bottom: 10px;
		@media (min-width: 768px) {
			font-size: 35px;
			padding-bottom: 10px;
		}
		@media (min-width: 992px) {
			font-size: 50px;
			padding-bottom: 20px;
		}
		@media (min-width: 1200px) {
			font-size: 77px;
			padding-bottom: 30px;
		}
		@media (min-width: 2400px) {
			font-size: 77px * 2;
			padding-bottom: 30px * 2;
		}
	}
	.logos {
		display: flex;
		justify-content: space-evenly;
		flex-direction: row;
		flex-wrap: wrap;

		.logo {
			width: 40%;
			display: block;
			margin-bottom: 15px;
			@media (min-width: 768px) {
				width: 27%;
			}

			@media (min-width: 992px) {
				width: 15%;
			}
		}
	}
}
