/*
*,
*::before,
*:after {
  box-sizing: border-box;
}

body {
  margin : 0;
  font-family : $font-family;
}
*/

//Los estilos que no dependen de clases, los que son aplicados a etiquetas, normalice

* {
	line-height: 1.2;
}

html,
body {
	height: 100%;
}

body {
	position: relative;
	height: 100%;
}
@media (min-width: 768px) {
	body {
		height: initial;
	}
}

html {
	min-height: 480px;

	@media (min-width: 768px) {
		min-height: 1024px;
	}
	@media (min-width: 992px) {
		min-height: 768px;
	}
	@media (min-width: 1200px) {
		min-height: 720px;
	}
	@media (min-width: 2400px) {
		min-height: 1440px;
	}
}
.container {
	@media (min-width: 2400px) {
		width: 2370px;
	}
}
