/*
	Estilos del 
	- Navbar
	- Menu movil
*/
:root {
	--altura_menu_xs: 60px;
	@media (min-width: 360px) {
		--altura_menu_xs: 67px;
	}
}

#logo_principal {
	background-image: url(../img/logo.svg);
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-color: #fff;
	background-position: 50% 50%;

	width: 58px;
	height: 50px;

	//Celualres y tablets
	@media (min-width: 414px) {
		//*1.30;
		width: 58px * 1.3;
		height: 50px * 1.3;
	}
	@media (min-width: 768px) {
		//*2.4;
		width: 58px * 2.4;
		height: 50px * 2.4;
	}

	@media (min-width: 992px) {
		//*.825;
		width: 107px * 0.825;
		height: 92px * 0.825;
	}
	@media (min-width: 1200px) {
		width: 107px;
		height: 92px;
	}
	@media (min-width: 2400px) {
		width: 107px * 2;
		height: 92px * 2;
		//
	}
}

.navbar {
	min-height: var(--altura_menu_xs);
	margin-bottom: 0;
	border-radius: 0px;

	width: 100%;
	top: 0;
	left: 0;
	//position: absolute;
	position: relative;

	@media (min-width: 768px) {
		width: 100%;
		top: 0;
		left: 0;
		//	position: absolute;
	}

	@media (min-width: 992px) {
		position: relative;
	}
}

.navbar-brand {
	//height: var(--altura_menu_xs);
	padding: 19px 30px 11px;
	height: auto;

	//Celualres y tablets
	@media (min-width: 414px) {
		//*1.30;
		padding: 19px * 1.3 30px * 1.3 11px * 1.3;
	}
	@media (min-width: 768px) {
		//*2.4;
		padding: 19px * 2.4 30px * 2.4 11px * 2.4;
	}

	@media (min-width: 992px) {
		//;
		padding: 19px * 0.825 30px * 0.825 11px * 0.825;
	}
	@media (min-width: 1200px) {
		padding: 19px 30px 11px;
	}
	@media (min-width: 2400px) {
		//;
		padding: 19px * 2 30px * 2 11px * 2;
	}

	//height:
}
.navbar-default {
	background-color: $white-color;
	border: none;
	//position: absolute;
	z-index: 1;
	width: 100%;
}

.navbar-default .menu {
	//icono que abre menu movil
	background-image: url("../img/icon-menu.svg");
	background-size: 100%;
	background-repeat: no-repeat;

	position: absolute;
	cursor: pointer;

	width: 43px;
	height: 26px;
	top: 27px;
	right: 45px;

	//Celualres y tablets
	@media (min-width: 414px) {
		//*1.30;
		width: 43px * 1.3;
		height: 26px * 1.3;
		top: 27px * 1.3;
		right: 45px * 1.3;
	}
	@media (min-width: 768px) {
		//*2.4;
		width: 43px * 2.4;
		height: 26px * 2.4;
		top: 27px * 2.4;
		right: 45px * 2.4;
	}

	@media (min-width: 992px) {
		//*.825;
		width: 43px * 0.825;
		height: 26px * 0.825;
		top: 32px * 0.825;
		right: 45px * 0.825;
	}
	@media (min-width: 1200px) {
		width: 43px;
		height: 26px;
		top: 32px;
		right: 45px;
	}
	@media (min-width: 2400px) {
		//*2;
		width: 43px * 2;
		height: 26px * 2;
		top: 32px * 2;
		right: 45px * 2;
	}

	&:hover {
		background-image: url("../img/icon-menu-active.svg");
	}
}

.navbar-nav {
	@media (min-width: 992px) {
		//*.825;
		padding-right: 70px * 0.825;
	}
	@media (min-width: 1200px) {
		padding-right: 70px;
	}
	@media (min-width: 2400px) {
		padding-right: 70px * 2;
	}
}

.navbar-collapse.collapse {
	/* Celuar y Tablet */
	@media (max-width: 991px) {
		display: none !important;
	}
}
.navbar-default .navbar-nav > li > a {
	line-height: 1;
	color: #000000;
	font-family: $Roboto-Light;

	font-size: 16px;
	padding: 53px 22px 53px;

	@media (min-width: 992px) {
		//*.825;
		font-size: 16px * 0.825;
		padding: 53px * 0.825 22px * 0.825 53px * 0.825;
	}
	@media (min-width: 1200px) {
		font-size: 16px;
		padding: 53px 22px 53px;
	}
	@media (min-width: 2400px) {
		//*2;
		font-size: 16px * 2;
		padding: 53px * 2 22px * 2 53px * 2;
	}

	&.active,
	&:hover {
		&:before {
			content: "";
			position: absolute;
			left: 0;
			background-color: $second-color;
			width: 100%;

			bottom: 30px;
			height: 1px;

			@media (min-width: 992px) {
				//*.825;
				bottom: 30px * 0.825;
				height: 1px;
			}
			@media (min-width: 1200px) {
				bottom: 30px;
				height: 1px;
			}
			@media (min-width: 2400px) {
				//*2;
				bottom: 30px * 2;
				height: 1px * 2;
			}
		}
	}
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li.active > a,
.navbar-default .navbar-nav > li.active > a:hover,
.navbar-default .navbar-nav > li.active > a:focus {
	color: $main-color;
}

.navbar-default .navbar-nav > li.li_icon > a {
	padding: 53px 12px 53px;
	@media (min-width: 992px) {
		padding: 53px * 0.825 12px * 0.825 53px * 0.825;
	}
	@media (min-width: 1200px) {
		padding: 53px 12px 53px;
	}
	@media (min-width: 2400px) {
		padding: 53px * 2 12px * 2 53px * 2;
	}
}

.navbar-default .navbar-nav > li.li_separator {
	line-height: 1;
	font-family: $Roboto-Light;
	color: #000000;

	padding: 53px 4px 53px;
	font-size: 16px;
	@media (min-width: 992px) {
		//*.825;
		padding: 53px * 0.825 4px * 0.825 53px * 0.825;
		font-size: 16px * 0.825;
	}
	@media (min-width: 1200px) {
		padding: 53px 4px 53px;
		font-size: 16px;
	}
	@media (min-width: 2400px) {
		//*2;
		padding: 53px * 2 4px * 2 53px * 2;
		font-size: 16px * 2;
	}
}

.navbar-default .navbar-nav > li.li_idiom > a {
	padding: 53px 3px 53px;

	@media (min-width: 992px) {
		//*.825;
		padding: 53px * 0.825 3px * 0.825 53px * 0.825;
	}
	@media (min-width: 1200px) {
		padding: 53px 3px 53px;
	}
	@media (min-width: 2400px) {
		//*2;
		padding: 53px * 2 3px * 2 53px * 2;
	}
}

.navbar-default .icon {
	background-size: 100%;
	background-repeat: no-repeat;

	&.icon-facebook {
		padding-top: 0px;
		background-image: url(../img/icon-facebook.svg);
		padding-top: 0px;

		width: 11px;
		height: 25px;

		@media (min-width: 992px) {
			//*.825;
			width: 11px * 0.825;
			height: 25px * 0.825;
		}
		@media (min-width: 1200px) {
			width: 11px;
			height: 25px;
		}
		@media (min-width: 2400px) {
			//*2;
			width: 11px * 2;
			height: 25px * 2;
		}

		&:hover {
			background-image: url(../img/icon-facebook-active.svg);
		}
	}
	&.icon-linkedin {
		background-image: url(../img/icon-linkedin.svg);

		padding-top: 3px;
		width: 19px;
		height: 19px;

		@media (min-width: 992px) {
			//*.825;
			padding-top: 3px * 0.825;
			width: 19px * 0.825;
			height: 19px * 0.825;
		}
		@media (min-width: 1200px) {
			padding-top: 3px;
			width: 19px;
			height: 19px;
		}
		@media (min-width: 2400px) {
			//*2;
			padding-top: 3px * 2;
			width: 19px * 2;
			height: 19px * 2;
		}
	}
}

/* Celular y Tablet */
#menu-movil {
	width: 0%;
	height: 100%;
	position: fixed;
	background-color: rgba(16, 25, 47, 0.86);
	background-blend-mode: multiply;
	//background-image: url(../img/menu-movil/fondo.webp);
	background-size: cover;
	background-position: 40% 50%;
	z-index: 1000;
	transition: width 2s;
}
#menu-movil.active {
	width: 100%;
}
#menu-movil * {
	transition: opacity 0.5s 0s;
	opacity: 0;
}
#menu-movil.active * {
	transition: opacity 2s 1s;
	opacity: 1;
}

@media (min-width: 992px) {
	#menu-movil {
		display: none !important;
	}
}
#menu-movil .icon-close {
	cursor: pointer;
	float: right;
	margin-top: 23px;
	margin-right: 23px;
	width: 17px;

	@media (min-width: 360px) {
		width: 19px;
		margin-top: 27px;
		margin-right: 27px;
	}
	@media (min-width: 414px) {
		//*1.30;
		width: 17px * 1.3;
		margin-top: 23px * 1.3;
		margin-right: 23px * 1.3;
	}
	@media (min-width: 768px) {
		//*2.4;
		width: 17px * 2.4;
		margin-top: 23px * 2;
		margin-right: 23px * 2;
	}
}
#menu-movil .container-fluid {
	height: calc(100% - 45px - 24px);
	top: 45px;
	position: relative;

	@media (min-width: 414px) {
		//*1.30;
		height: calc(100% - 45px * 1.3 - 24px * 1.3);
		top: 45px * 1.3;
	}
	@media (min-width: 768px) {
		//*2.4;
		height: calc(100% - 45px * 2.4 - 24px * 2.4);
		top: 45px * 2.4;
	}
}
#menu-movil .row {
	height: 100%;
}

#menu-movil {
	.li_separator {
		display: inline-block;
		color: $white-color;
		font-family: $Roboto-Regular;
		letter-spacing: 0.053em;
		//text-decoration: none;

		font-size: 22.85px;
		margin-top: 24px;

		@media (min-width: 360px) {
			font-size: 25.77px;
		}

		@media (min-width: 414px) {
			font-size: 22.85px * 1.3;
			margin-top: 24px * 1.3;
			//*1.30;
		}
		@media (min-width: 768px) {
			font-size: 22.85px * 2.4;
			margin-top: 24px * 2.4;
			//*2.4;
		}
	}

	a {
		color: $white-color;
		font-family: $Roboto-Regular;
		letter-spacing: 0.053em;
		//text-decoration: none;
		display: block;

		font-size: 22.85px;
		margin-top: 24px;

		&:hover {
			color: $white-color;
		}

		@media (min-width: 360px) {
			font-size: 25.77px;
		}

		@media (min-width: 414px) {
			font-size: 22.85px * 1.3;
			margin-top: 24px * 1.3;
			//*1.30;
		}
		@media (min-width: 768px) {
			font-size: 22.85px * 2.4;
			margin-top: 24px * 2.4;
			//*2.4;
		}

		&.menu_es,
		&.menu_en {
			display: inline-block;
		}
	}
}
#menu-movil .a_icon {
	display: inline-block;
	margin: 36px 11px 50px;
	@media (min-width: 360px) {
		margin: 41px 13px 57px;
	}
	@media (min-width: 414px) {
		//*1.30;
		margin: 36px * 1.3 11px * 1.3 50px * 1.3;
	}
	@media (min-width: 768px) {
		//*2.4;
		margin: 36px * 2.4 11px * 2.4 50px * 2.4;
	}

	.icon {
		width: 36px;
		height: 36px;

		background-size: 100%;

		@media (min-width: 360px) {
			width: 40.5px;
			height: 40.5px;
		}
		@media (min-width: 414px) {
			//*1.30;
			width: 36px * 1.3;
			height: 36px * 1.3;
		}
		@media (min-width: 768px) {
			//*2.4;
			width: 36px * 2.4;
			height: 36px * 2.4;
		}

		&.icon-facebook {
			background-image: url(../img/menu-movil/icon-facebook-active.svg);
		}
		&.icon-linkedin {
			background-image: url(../img/menu-movil/icon-linkedin-active.svg);
		}
	}
}
