.nosotros {
	.seccion_1 {
		h1 {
			font-family: $Roboto-Bold;
			line-height: 1.2;
			color: #393d42;
			text-align: center;

			font-size: 30px;
			padding-top: 20px;
			padding-bottom: 20px;

			//Celualres y tablets
			@media (min-width: 414px) {
				//*1.30;
				font-size: 30px * 1.3;
				padding-top: 20px * 1.3;
				padding-bottom: 20px * 1.3;
			}
			@media (min-width: 768px) {
				//*2.4;
				font-size: 30px * 2.4;
				padding-top: 20px * 2.4;
				padding-bottom: 20px * 2.4;
			}

			@media (min-width: 992px) {
				//*.825;
				font-size: 60px * 0.825;
				padding-top: 55px * 0.825;
				padding-bottom: 46px * 0.825;
			}
			@media (min-width: 1200px) {
				//
				font-size: 60px;
				padding-top: 55px;
				padding-bottom: 46px;
			}
			@media (min-width: 2400px) {
				//*2;
				font-size: 60px * 2;
				padding-top: 55px * 2;
				padding-bottom: 46px * 2;
			}

			span {
				color: $main-color;
			}
		}
		.texto {
			color: $black-color;
			line-height: 2;
			font-family: $Roboto-Light;
			letter-spacing: 0.025em;
			text-align: justify;

			font-size: 13px;

			//Celualres y tablets
			@media (min-width: 414px) {
				font-size: 13px * 1.3;
			}
			@media (min-width: 768px) {
				font-size: 13px * 2.4;
			}

			@media (min-width: 992px) {
				font-size: 18px * 0.825;
			}
			@media (min-width: 1200px) {
				font-size: 18px;
			}
			@media (min-width: 2400px) {
				font-size: 18px * 2;
			}

			span {
				color: $main-color;
				font-family: Roboto-Regular;
			}

			.bold {
				color: $main-color;
				font-family: $Roboto-Bold;
				font-size: 200%;
				text-align: center;
			}
		}
		.imagen {
			width: 100%;
			height: 200px;
			background-color: #ccc;
			background-image: url(/assets/img/nosotros/imagen_1.jpg);
			background-size: cover;
			background-position: 50% 50%;

			margin-top: 20px;
			margin-bottom: 20px;

			//Celualres y tablets
			@media (min-width: 414px) {
				//*1.30;
				margin-top: 20px * 1.3;
				margin-bottom: 20px * 1.3;
				height: 200px * 1.3;
			}
			@media (min-width: 768px) {
				//*2.4;
				margin-top: 20px * 2.4;
				margin-bottom: 20px * 2.4;
				height: 200px * 2.4;
			}

			@media (min-width: 992px) {
				//*.825;
				margin-top: 50px * 0.825;
				margin-bottom: 30px * 0.825;
				height: 400px * 0.825;
			}
			@media (min-width: 1200px) {
				//
				margin-top: 50px;
				margin-bottom: 30px;
				height: 400px;
			}
			@media (min-width: 2400px) {
				//*2;
				margin-top: 50px * 2;
				margin-bottom: 30px * 2;
				height: 400px * 2;
			}
		}
	} //.seccion_1

	.seccion_2 {
		margin-top: 30px;

		//Celualres y tablets
		@media (min-width: 414px) {
			margin-top: 30px * 1.3;
		}
		@media (min-width: 768px) {
			margin-top: 30px * 2.4;
		}

		@media (min-width: 992px) {
			margin-top: 40px * 0.825;
		}
		@media (min-width: 1200px) {
			margin-top: 40px;
		}
		@media (min-width: 2400px) {
			margin-top: 40px * 2;
		}

		h1 {
			font-family: $Roboto-Bold;
			line-height: 1.2;
			color: #393d42;
			text-align: center;

			font-size: 30px;
			padding-top: 20px;
			padding-bottom: 20px;

			//Celualres y tablets
			@media (min-width: 414px) {
				//*1.30;
				font-size: 30px * 1.3;
				padding-top: 20px * 1.3;
				padding-bottom: 20px * 1.3;
			}
			@media (min-width: 768px) {
				//*2.4;
				font-size: 30px * 2.4;
				padding-top: 20px * 2.4;
				padding-bottom: 20px * 2.4;
			}

			@media (min-width: 992px) {
				//*.825;
				font-size: 60px * 0.825;
				padding-top: 55px * 0.825;
				padding-bottom: 46px * 0.825;
			}
			@media (min-width: 1200px) {
				//
				font-size: 60px;
				padding-top: 55px;
				padding-bottom: 46px;
			}
			@media (min-width: 2400px) {
				//*2;
				font-size: 60px * 2;
				padding-top: 55px * 2;
				padding-bottom: 46px * 2;
			}

			span {
				color: $main-color;
			}
		}
		.texto {
			color: $black-color;
			line-height: 2;
			font-family: $Roboto-Light;
			letter-spacing: 0.025em;
			text-align: justify;

			font-size: 13px;

			//Celualres y tablets
			@media (min-width: 414px) {
				font-size: 13px * 1.3;
			}
			@media (min-width: 768px) {
				font-size: 13px * 2.4;
			}

			@media (min-width: 992px) {
				font-size: 18px * 0.825;
			}
			@media (min-width: 1200px) {
				font-size: 18px;
			}
			@media (min-width: 2400px) {
				font-size: 18px * 2;
			}

			span {
				color: $main-color;
				font-family: Roboto-Regular;
			}

			.bold {
				color: $main-color;
				font-family: $Roboto-Bold;
				font-size: 200%;
				text-align: center;
			}
		}
		.imagen {
			width: 100%;

			margin-top: 20px;
			margin-bottom: 20px;

			height: 100px;

			background-image: url(/assets/img/nosotros/logo.svg);
			background-size: auto 100%;
			background-position: 50% 50%;
			background-repeat: no-repeat;

			//Celualres y tablets
			@media (min-width: 414px) {
				//*1.30;
				margin-top: 20px * 1.3;
				margin-bottom: 20px * 1.3;
				height: 100px * 1.3;
			}
			@media (min-width: 768px) {
				//*2.4;
				margin-top: 20px * 2.4;
				margin-bottom: 20px * 2.4;
				height: 100px * 2.4;
			}

			@media (min-width: 992px) {
				//*.825;
				margin-top: 50px * 0.825;
				margin-bottom: 30px * 0.825;
				height: 175px * 0.825;
			}
			@media (min-width: 1200px) {
				//
				height: 175px;
				margin-top: 50px;
				margin-bottom: 30px;
			}
			@media (min-width: 2400px) {
				//*2;
				height: 175px * 2;
				margin-top: 50px * 2;
				margin-bottom: 30px * 2;
			}
		}
	} //.seccion_2

	.seccion_3 {
		margin-top: 60px;
		background-image: url(/assets/img/nosotros/fondo.jpg);
		background-size: cover;
		background-position: 50% 50%;

		padding-bottom: 150px;

		//Celualres y tablets
		@media (min-width: 414px) {
			margin-top: 60px * 1.3;
		}
		@media (min-width: 768px) {
			margin-top: 60px * 2.4;
		}

		@media (min-width: 992px) {
			margin-top: 100px * 0.825;
		}
		@media (min-width: 1200px) {
			margin-top: 100px;
		}
		@media (min-width: 2400px) {
			margin-top: 100px * 2;
		}

		h1 {
			color: $second-color;
			color: $main-color;
			line-height: 1;
			text-align: center;
			font-family: $Roboto-Bold;
			position: relative;
			width: 100%;
			height: auto;
			top: 0;
			left: 0;
			z-index: 2;
			margin-top: 30px;
			margin-bottom: 30px;

			font-size: 30px;
			//Celualres y tablets

			@media (min-width: 414px) {
				font-size: 30px * 1.3;
				margin-top: 30px * 1.3;
				margin-bottom: 30px * 1.3;
			}
			@media (min-width: 768px) {
				font-size: 30px * 2.4;
				margin-top: 30px * 2.4;
				margin-bottom: 30px * 2.4;
			}

			@media (min-width: 992px) {
				font-size: 60px * 0.825;
				margin-top: 60px * 0.825;
				margin-bottom: 60px * 0.825;
			}
			@media (min-width: 1200px) {
				font-size: 60px;
				margin-top: 60px;
				margin-bottom: 60px;
			}
			@media (min-width: 2400px) {
				font-size: 60px * 2;
				margin-top: 60px * 2;
				margin-bottom: 60px * 2;
			}
		}
		.cuadros {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-around;

			@media (min-width: 992px) {
				justify-content: space-between;
			}

			.cuadro {
				width: 200px;
				height: 200px;

				margin-bottom: 30px;
				position: relative;

				//Celualres y tablets
				@media (min-width: 414px) {
					//*1.30;
					width: 200px * 1.3;
					height: 200px * 1.3;
					margin-bottom: 30px * 1.3;
				}
				@media (min-width: 768px) {
					//*2.4;
					width: 200px * 2.4;
					height: 200px * 2.4;
					margin-bottom: 30px * 2.4;
				}

				@media (min-width: 992px) {
					//*.825;
					width: 200px * 0.825;
					height: 200px * 0.825;
					margin-bottom: 0;
				}
				@media (min-width: 1200px) {
					//
					width: 200px;
					height: 200px;
				}
				@media (min-width: 2400px) {
					//*2;
					width: 200px * 2;
					height: 200px * 2;
				}

				.texto {
					z-index: 3;
					color: #fff;
					position: relative;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					font-size: 20px;
					//Celualres y tablets
					@media (min-width: 414px) {
						font-size: 20px * 1.3;
					}
					@media (min-width: 768px) {
						font-size: 20px * 2.4;
					}

					@media (min-width: 992px) {
						font-size: 20px * 0.825;
					}
					@media (min-width: 1200px) {
						font-size: 20px;
					}
					@media (min-width: 2400px) {
						font-size: 20px * 2;
					}
				}

				&:after {
					content: "";
					background-color: rgba($main-color, 0.8);
					background-blend-mode: darken;
					transform: translateZ(0);
					mix-blend-mode: multiply;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
				}

				&:before {
					content: "";
					width: 90%;
					height: 90%;
					border: 1px solid #fff;
					z-index: 2;
					position: absolute;
					top: 5%;
					left: 5%;
				}
			}
		}
	} //.seccion_3
}
