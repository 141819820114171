$main-color: #415d36;
$main-color-83: rgba(16, 25, 47, 0.83);

$second-color: #393d42;
$second-color-70: rgba(140, 193, 72, 0.7);
$thirt-color: #666666;

$menu-color: #cacaca;

$grey-color: #4d4d4d;
$title-color: #58595b;
$greay-l-l-color: #efefef;

$black-color: #000000;
$white-color: #ffffff;

$white-color-80: rgba(255, 255, 255, 0.8);

$color-303030: #303030;
