//Theme es el look and feel, con cambiar los colores y tipografias cambia drasticamente una pagina
@font-face {
	src: url("../fonts/Roboto/Roboto-Light.ttf");
	font-family: Roboto-Light;
}
@font-face {
	src: url("../fonts/Roboto/Roboto-Regular.ttf");
	font-family: Roboto-Regular;
}
@font-face {
	src: url("../fonts/Roboto/Roboto-Black.ttf");
	font-family: Roboto-Black;
}
@font-face {
	src: url("../fonts/Roboto/Roboto-Bold.ttf");
	font-family: Roboto-Bold;
}
@font-face {
	src: url("../fonts/Roboto/Roboto-Medium.ttf");
	font-family: Roboto-Medium;
}
@font-face {
	src: url("../fonts/Roboto/Roboto-Thin.ttf");
	font-family: Roboto-Thin;
}

$Roboto-Light: "Roboto-Light";
$Roboto-Regular: "Roboto-Regular";
$Roboto-Black: "Roboto-Black";
$Roboto-Bold: "Roboto-Bold";
$Roboto-Medium: "Roboto-Medium";
$Roboto-Thin: "Roboto-Thin";
