.cuadros_contacto{
	.cuadros{
		@media (min-width: 768px) {
			text-align: center;
		}
	}
	.cuadro{
		
		width: 220px;
		height: 225px;

		display: block;
		margin: 0 auto 25px;
		background-color: $main-color;
		border-radius: 6px;
		position: relative;

		@media (min-width: 360px) {
			width: 244px;
			height: 250px;
			margin: 0 auto 30px;
		}

		@media (min-width: 768px) {
			width: 190px;
			height: 196px;
			border-radius: 5px;

			display: inline-block;
			vertical-align: top;
			margin: 0 13px 62px;
		}

		.cuadro__contenido{
			position: relative;
			z-index: 2;
			width: calc(100% - 10px);
			height: calc(100% - 10px);
			top: 5px;
			left: 5px;
			font-size: 12.12px;
			line-height: 20.8px;
			text-align: center;
			font-family: Gotham-Light;
			letter-spacing: 0.160em;
			color: $white-color;

			@media (min-width: 360px) {
				font-size: 13.43px;
				line-height: 23px;
			}
			@media (min-width: 768px) {
				font-size: 10.49px;
				line-height: 18px;
			}

			a{
				color: $white-color;
			}

			div{
				margin-bottom: 18px; 
			}
		}

		&::after{
			content: '';
			position: absolute;
			width: calc(100% - 10px);
			height: calc(100% - 10px);
			top: 5px;
			left: 5px;
			border: 1px solid $white-color;
			z-index: 1;
		}

		&.cuadro_1{
			padding-top: 36px;
			@media (min-width: 360px) {
				padding-top: 40px;
			}
			@media (min-width: 768px) {
				padding-top: 31px;
			}

			img{
				width: 37.5px;
				display: block;
				margin: 0 auto 24px;
				@media (min-width: 360px) {
					width: 41.5px;
					margin: 0 auto 29px; 
				}
				@media (min-width: 768px) {
					width: 32.3px;
					margin: 0 auto 27px;
				}
			}
		}

		&.cuadro_2{
			padding-top: 35px;
			@media (min-width: 360px) {
				padding-top: 39px;
			}
			@media (min-width: 768px) {
				padding-top: 30px;
			}

			img{
				width: 30.75px;
				display: block;
				margin: 0 auto 26px;
				@media (min-width: 360px) {
					width: 34px;
					margin: 0 auto 27px; 
				}
				@media (min-width: 768px) {
					width: 26.6px;
					margin: 0 auto 25px;
				}
			}
		}

		&.cuadro_3{
			padding-top: 38px;
			@media (min-width: 360px) {
				padding-top: 42px;
			}
			@media (min-width: 768px) {
				padding-top: 33px;
			}

			img{
				width: 37.5px;
				display: block;
				margin: 0 auto 24px;
				@media (min-width: 360px) {
					width: 41.5px;
					margin: 0 auto 27px; 
				}
				@media (min-width: 768px) {
					width: 32.3px;
					margin: 0 auto 25px;
				}
			}
		}
		
	}
}