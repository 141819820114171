.carousel-servicios {
	width: 290px;
	margin: 0 auto;
	@media (min-width: 360px) {
		width: 330px;
	}
	@media (min-width: 768px) {
		width: 645px;
	}
	@media (min-width: 992px) {
		width: 720px;
	}
	@media (min-width: 1200px) {
		width: 900px;
	}
	@media (min-width: 2400px) {
		width: 1800px;
	}

	.carousel-inner {
		@media (min-width: 768px) {
			width: 584px;
			margin: 0 auto;
		}
		@media (min-width: 992px) {
			width: 658px;
		}
		@media (min-width: 1200px) {
			width: 820px;
		}
		@media (min-width: 2400px) {
			width: 1640px;
		}
	}

	.item {
	}
	.servicio {
		//contenedor de la imagen y referencia para el diseño del fondo
		width: 165px;
		height: 165px;
		margin: 23px auto 0;
		cursor: pointer;

		position: relative;
		@media (min-width: 360px) {
			width: 194px;
			height: 194px;
			margin: 22px auto 0;
		}
		@media (min-width: 768px) {
			width: 165px;
			height: 165px;
			margin: 40px auto 0;
		}
		@media (min-width: 992px) {
			width: 185px;
			height: 185px;
			margin: 50px auto 0;
		}
		@media (min-width: 1200px) {
			width: 232px;
			height: 232px;
			margin: 65px auto 0;
		}
		@media (min-width: 2400px) {
			width: 464px;
			height: 464px;
			margin: 130px auto 0;
		}

		&::before,
		&::after {
			//reglas heredadas entre los elementos del fondo
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			z-index: -1;
		}
		&::before {
			width: 165px;
			height: 165px;

			border: 9px solid #efefef;

			@media (min-width: 360px) {
				width: 194px;
				height: 194px;

				border: 11px solid #efefef;
			}
			@media (min-width: 768px) {
				width: 165px;
				height: 165px;

				border: 9px solid #efefef;
			}
			@media (min-width: 992px) {
				width: 185px;
				height: 185px;

				border: 10px solid #efefef;
			}
			@media (min-width: 1200px) {
				width: 232px;
				height: 232px;

				border: 13px solid #efefef;
			}
			@media (min-width: 2400px) {
				width: 464px;
				height: 464px;

				border: 26px solid #efefef;
			}
		}
		&::after {
			width: 100px;
			height: 100px;

			background-color: #efefef;
			@media (min-width: 360px) {
				width: 116px;
				height: 116px;
			}
			@media (min-width: 768px) {
				width: 100px;
				height: 100px;
			}
			@media (min-width: 992px) {
				width: 111px;
				height: 111px;
			}
			@media (min-width: 1200px) {
				width: 140px;
				height: 140px;
			}
			@media (min-width: 2400px) {
				width: 280px;
				height: 280px;
			}
		}
	}
	.img {
		//imagen centrada en el contenedor
		width: 161px;
		height: 161px;
		position: relative;
		top: 2px;
		left: 2px;

		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		@media (min-width: 360px) {
			width: 190px;
			height: 190px;
			top: 2px;
			left: 2px;
		}
		@media (min-width: 768px) {
			width: 161px;
			height: 161px;
			top: 2px;
			left: 2px;
		}
		@media (min-width: 992px) {
			width: 179px;
			height: 179px;
			top: 3px;
			left: 3px;
		}
		@media (min-width: 1200px) {
			width: 224px;
			height: 224px;
			top: 4px;
			left: 4px;
		}
		@media (min-width: 2400px) {
			width: 448px;
			height: 448px;
			top: 8px;
			left: 8px;
		}
	}
	.articulo {
		font-size: 13.52px;
		font-family: $Roboto-Regular;
		color: black;
		letter-spacing: 0.053em;
		text-align: center;
		margin: 36px 0 22px;
		height: (13.52px * 1.5 * 2);

		@media (min-width: 360px) {
			font-size: 14.55px;
			margin: 39px 0 24px;
			height: (14.55px * 1.5 * 2);
		}
		@media (min-width: 768px) {
			font-size: 13.52px;
			margin: 36px 0 22px;
			height: (13.52px * 1.5 * 2);
		}
		@media (min-width: 992px) {
			font-size: 15.14px;
			margin: 40px 0 26px;
			height: (15.14px * 1.5 * 2);
		}
		@media (min-width: 1200px) {
			font-size: 19px;
			margin: 48px 0 35px;
			height: (19px * 1.5 * 2);
		}
		@media (min-width: 2400px) {
			font-size: 38px;
			margin: 96px 0 70px;
			height: (38px * 1.5 * 2);
		}

		.extra {
			color: #666666;
		}
	}

	a {
		text-decoration: none;
	}

	button {
		background: #efefef;
		background: $second-color;
		color: #ffffff;
		font-family: $Roboto-Regular;
		font-size: 15.28px;
		line-height: 1.2;
		border: none;
		box-shadow: none;
		width: 97px;
		height: 24px;
		display: block;
		margin: 0 auto 44px;

		@media (min-width: 360px) {
			width: 105px;
			height: 26px;
			font-size: 16.46px;
			margin: 0 auto 62px;
		}
		@media (min-width: 768px) {
			width: 97px;
			height: 24px;
			font-size: 15.28px;
			margin: 0 auto 40px;
		}
		@media (min-width: 992px) {
			width: 108.83px;
			height: 27px;
			font-size: 17.11px;
			margin: 0 auto 50px;
		}
		@media (min-width: 1200px) {
			width: 136px;
			height: 34px;
			font-size: 21.4px;
			margin: 0 auto 65px;
		}
		@media (min-width: 2400px) {
			width: 272px;
			height: 68px;
			font-size: 42.8px;
			margin: 0 auto 130px;
		}

		&:hover {
			background: $second-color;
		}
	}

	.carousel-control {
		//flechas de navegacion
		width: 33px;
		height: 33px;
		top: 89px;
		background: none;
		text-shadow: none;
		color: red;
		opacity: 1;
		background-size: 100%;

		@media (min-width: 360px) {
			width: 40px;
			height: 40px;
			top: 99px;
		}
		@media (min-width: 768px) {
			top: 105px;
			width: 15px;
			height: 32px;
			background-repeat: no-repeat;
		}
		@media (min-width: 992px) {
			top: 123px;
			width: 16px;
			height: 35px;
		}
		@media (min-width: 1200px) {
			top: 155px;
			width: 20px;
			height: 43px;
		}
		@media (min-width: 2400px) {
			top: 310px;
			width: 40px;
			height: 86px;
		}

		&.left {
			background-image: url(/assets/img/carousel/prev.svg);
			@media (min-width: 768px) {
				background-image: url(/assets/img/carousel/prev-md.svg);

				&:hover {
					background-image: url(/assets/img/carousel/prev-md-active.svg);
				}
			}
		}
		&.right {
			background-image: url(/assets/img/carousel/next.svg);
			@media (min-width: 768px) {
				background-image: url(/assets/img/carousel/next-md.svg);

				&:hover {
					background-image: url(/assets/img/carousel/next-md-active.svg);
				}
			}
		}
	}
}
