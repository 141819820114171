.mapa{
	width: 100%;
	height: 308px;
	display: block;

	@media (min-width: 768px) {
		height: 382px;
	}
	@media (min-width: 992px) {
		height: 512px;
	}
	@media (min-width: 1200px) {
		height: 635px;
	}
	@media (min-width: 2400px) {
		height: 1430px;
	}
}