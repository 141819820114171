.relative{
    position: relative;
}

.inline-block{
	display: inline-block;
}

.centrador{
	display: table-cell;
	vertical-align: middle;
}