.contenido_single {
	padding-top: var(--altura_menu_xs);
	position: relative;

	@media (min-width: 768px) {
		padding-top: 158px;
	}
	@media (min-width: 992px) {
		padding-top: 75px;
	}
	@media (min-width: 1200px) {
		padding-top: 80px;
	}
	@media (min-width: 2400px) {
		padding-top: 160px;
	}

	.imagen_servicio {
		margin: 50px auto 22px;
		width: 240px;
		height: 240px;
		position: relative;

		@media (min-width: 360px) {
			width: 270px;
			height: 270px;
			margin: 55px auto 24px;
		}
		@media (min-width: 768px) {
			width: 290px;
			height: 290px;
			margin: 0px auto 25px;
		}
		@media (min-width: 1200px) {
			width: 360px;
			height: 360px;
			margin: 0px auto 30px;
		}
		@media (min-width: 2400px) {
			width: 720px;
			height: 720px;
			margin: 0px auto 60px;
		}

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			z-index: -1;
		}
		&::before {
			//Borde externo gris
			width: 189px;
			height: 189px;

			border: 10px solid #efefef;
			@media (min-width: 360px) {
				width: 213px;
				height: 213px;

				border: 12px solid #efefef;
			}
			@media (min-width: 768px) {
				width: 226px;
				height: 226px;

				border: 12px solid #efefef;
			}
			@media (min-width: 1200px) {
				width: 280px;
				height: 280px;

				border: 15px solid #efefef;
			}
			@media (min-width: 2400px) {
				width: 560px;
				height: 560px;

				border: 30px solid #efefef;
			}
		}
		&::after {
			//Cuadro interno
			width: 114px;
			height: 114px;

			background-color: #efefef;
			@media (min-width: 360px) {
				width: 128px;
				height: 128px;
			}
			@media (min-width: 768px) {
				width: 136px;
				height: 136px;
			}
			@media (min-width: 1200px) {
				width: 168px;
				height: 168px;
			}
			@media (min-width: 2400px) {
				width: 336px;
				height: 336px;
			}
		}

		.imagen {
			background-size: cover;
			width: 189px;
			height: 189px;
			background-position: 50% 50%;
			background-repeat: no-repeat;

			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;

			@media (min-width: 360px) {
				width: 213px;
				height: 213px;
			}
			@media (min-width: 768px) {
				width: 226px;
				height: 226px;
			}
			@media (min-width: 1200px) {
				width: 280px;
				height: 280px;
			}
			@media (min-width: 2400px) {
				width: 560px;
				height: 560px;
			}
		}
	}

	.descripcion {
		font-family: $Roboto-Regular;
		color: #333333;
		margin: auto;
		text-align: justify;
		line-height: 1.4;
		letter-spacing: 0.053em;
		margin-bottom: 30px;
		//no se define tamaño de letro por que puede variar al entrar contenido
		/*@media (min-width: 992px){
			display: inline-block;
			width: calc(100% - 140px);
			vertical-align: top;
		}*/
		@media (min-width: 768px) {
			width: 655px;
		}
		@media (min-width: 992px) {
			width: 790px;
			margin-bottom: 40px;
		}
		@media (min-width: 1200px) {
			width: 980px;
			margin-bottom: 50px;
		}
		@media (min-width: 2400px) {
			font-size: 200%;
			width: 980px * 2;
			margin-bottom: 100px;
		}

		//subtitulos
		h3 {
			color: $second-color;
		}

		.imagen {
			width: 100%;
			height: 300px;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: cover;
			margin-top: 30px;
			margin-bottom: 30px;

			@media (min-width: 2400px) {
				height: 600px;
				margin-top: 60px;
				margin-bottom: 60px;
			}
		}
	}

	.datos_servicio {
		line-height: 1.2;
		letter-spacing: 0.053em;

		.contenido {
			@media (min-width: 992px) {
				width: calc(100% - 140px);
				vertical-align: top;
				display: inline-block;
			}
			@media (min-width: 1200px) {
				width: calc(100% - 180px);
			}
			@media (min-width: 2400px) {
				width: calc(100% - 360px);
			}
		}
		.titulo {
			font-family: $Roboto-Light;
			color: $second-color;
			font-size: 19px * 2.5;
			margin-bottom: 35px;

			@media (min-width: 360px) {
				font-size: 21.42px * 2.5;
				margin-bottom: 37px;
			}
			@media (min-width: 768px) {
				font-size: 19px * 2.5;
				margin-top: 40px;
				margin-bottom: 38px;
			}
			@media (min-width: 1200px) {
				font-size: 23px * 2.5;
				margin-top: 36px;
				margin-bottom: 44px;
			}
			@media (min-width: 2400px) {
				font-size: 46px * 2.5;
				margin-top: 12px;
				margin-bottom: 88px;
			}
		}

		.cuerpo {
			font-family: $Roboto-Regular;
			color: #333333;
			text-align: justify;
			//no se define tamaño de letro por que puede variar al entrar contenido
			/*@media (min-width: 992px){
				display: inline-block;
				width: calc(100% - 140px);
				vertical-align: top;
			}*/
			margin-bottom: 30px;
			line-height: 1.4;
			@media (min-width: 768px) {
				margin-bottom: 0px;
				text-align: left;
			}
			@media (min-width: 2400px) {
				font-size: 200%;
			}
		}

		.cambiar_servicio {
			display: none;
			@media (min-width: 992px) {
				width: 134px;
				height: 100px;

				vertical-align: top;
				display: inline-block;
				text-align: center;
			}
			@media (min-width: 1200px) {
				width: 174px;
				height: 100px;
			}
			@media (min-width: 2400px) {
				width: 348px;
				height: 200px;
			}
			a {
				width: 14px;
				height: 24px;
				background-size: cover;
				background-repeat: no-repeat;
				display: inline-block;
				margin: 10px 9px 0;

				@media (min-width: 1200px) {
					width: 16px;
					height: 30px;
					margin: 13px 11px 0;
				}
				@media (min-width: 2400px) {
					width: 32px;
					height: 60px;
					margin: 26px 22px 0;
				}

				&.prev {
					background-image: url(../img/single/prev.svg);

					&:hover {
						background-image: url(../img/single/prev-active.svg);
					}
				}
				&.next {
					background-image: url(../img/single/next.svg);
					&:hover {
						background-image: url(../img/single/next-active.svg);
					}
				}
			}
		}

		.botones {
			width: 290px;
			margin: 42px auto 62px;
			text-align: center;

			@media (min-width: 360px) {
				width: 330px;
				margin: 76px auto 69px;
			}
			@media (min-width: 768px) {
				width: 250px;
				margin: 50px auto 106px;
			}
			@media (min-width: 1200px) {
				width: 310px;
				margin: 72px auto 196px;
			}
			@media (min-width: 2400px) {
				width: 620px;
				margin: 144px auto 392px;
			}

			a {
				display: inline-block;
				width: 132px;
				height: 77px;
				font-size: 12px;
				letter-spacing: 0;
				font-family: $Roboto-Regular;
				border: 1px solid $second-color;
				color: #262261;
				text-decoration: none;

				@media (min-width: 360px) {
					width: 148px;
					height: 87px;
					font-size: 13.57px;
				}
				@media (min-width: 768px) {
					width: 113px;
					height: 67px;
					font-size: 10.41px;
				}
				@media (min-width: 1200px) {
					width: 142px;
					height: 83px;
					font-size: 13px;
				}
				@media (min-width: 2400px) {
					width: 284px;
					height: 166px;
					font-size: 26px;
					border: 2px solid $second-color;
				}

				&:first-child {
					margin-right: 10px;
					@media (min-width: 360px) {
						margin-right: 12px;
					}
					@media (min-width: 768px) {
						margin-right: 10px;
					}
					@media (min-width: 1200px) {
						margin-right: 12px;
					}
					@media (min-width: 2400px) {
						margin-right: 24px;
					}
				}
				&:last-child {
					margin-left: 10px;
					@media (min-width: 360px) {
						margin-left: 12px;
					}
					@media (min-width: 768px) {
						margin-left: 10px;
					}
					@media (min-width: 1200px) {
						margin-left: 12px;
					}
					@media (min-width: 2400px) {
						margin-left: 24px;
					}
				}

				img {
					//flecha hacia abajo
					width: 27px;
					height: 16px;
					margin: 19px auto 6px;
					display: block;

					@media (min-width: 360px) {
						width: 30px;
						height: 18px;
						margin: 22px auto 6px;
					}
					@media (min-width: 768px) {
						width: 25px;
						height: 14px;
						margin: 16px auto 5px;
					}
					@media (min-width: 1200px) {
						width: 29px;
						height: 17px;
						margin: 21px auto 6px;
					}
					@media (min-width: 2400px) {
						width: 58px;
						height: 34px;
						margin: 42px auto 12px;
					}
				}

				&:hover {
					color: #262261 !important;
				}
			}
		}
	}
}

.cuadro_single {
	a {
		text-decoration: none;
	}
	button {
		width: 140px;
		height: 29px;
		display: block;
		margin: 0 auto;
		background-color: transparent;
		border: 1px solid $white-color;
		font-size: 12px;
		line-height: 14.38px;
		font-family: $Roboto-Regular;
		color: $white-color;
		padding-top: 1px;

		@media (min-width: 360px) {
			width: 158px;
			height: 32.5px;
			font-size: 13.49px;
			line-height: 16.19px;
			padding-top: 2px;
		}
		@media (min-width: 768px) {
			width: 167px;
			height: 34px;
			font-size: 14.29px;
			line-height: 17.15px;
			padding-top: 2px;
		}
		@media (min-width: 992px) {
			width: 147px;
			height: 30.39px;
			font-size: 12.66px;
			line-height: 15.2px;
			padding-top: 2px;
		}
		@media (min-width: 1200px) {
			width: 185px;
			height: 38px;
			font-size: 15.84px;
			line-height: 19px;
			padding-top: 2px;
		}
		@media (min-width: 2400px) {
			width: 370px;
			height: 76px;
			font-size: 31.68px;
			line-height: 38px;
			padding-top: 4px;
		}

		&:hover {
			background-color: $second-color-70;
			color: $white-color;
			border: none;
		}
	}

	.cuadro {
		background-color: rgba(0, 0, 0, 0.41);
		background-blend-mode: multiply;
		height: 220px;
		margin-top: 6px;

		@media (min-width: 360px) {
			height: 247px;
			margin-top: 7px;
		}
		@media (min-width: 768px) {
			height: 339px;
			margin-top: 0px;
		}
		@media (min-width: 992px) {
			height: 300px;
			margin-top: 0px;
		}
		@media (min-width: 1200px) {
			height: 376px;
		}
		@media (min-width: 2400px) {
			height: 752px;
		}
		&.cuadro-servicios {
			background-image: url(/assets/img/nosotros/cuadro-servicios.webp);
			background-size: cover;
			background-position: 50% 50%;

			display: table;
			@media (min-width: 768px) and (max-width: 991px) {
				margin-bottom: 4px;
				height: 325px;
			}
		}
		&.cuadro-nosotros {
			background-image: url(/assets/img/nosotros/cuadro-contacto.webp);
			background-size: cover;
			background-position: 50% 50%;
			display: table;
			background-color: rgba(0, 0, 0, 0.26);

			@media (min-width: 768px) {
				border-right: 2px solid white;
			}
			@media (min-width: 992px) {
				border-right: 4px solid white;
			}
			@media (min-width: 2400px) {
				border-right: 8px solid white;
			}
		}

		&.cuadro-inicio {
			background-image: url(/assets/img/nosotros/cuadro-inicio.webp);
			background-size: cover;
			background-position: 50% 50%;

			display: table;
			@media (min-width: 768px) {
				border-left: 2px solid white;
			}
			@media (min-width: 992px) {
				border-right: 4px solid white;
				border-left: 0;
			}
			@media (min-width: 2400px) {
				border-right: 8px solid white;
			}
		}
	}
}

.galeria_single {
	margin-bottom: 15px;

	@media (min-width: 768px) {
		width: 655px;
		margin: auto;
		margin-bottom: 20px;
	}
	@media (min-width: 992px) {
		width: 790px;
		margin-bottom: 30px;
	}
	@media (min-width: 1200px) {
		width: 980px;
		margin-bottom: 40px;
	}
	@media (min-width: 2400px) {
		font-size: 200%;
		width: 980px * 2;
		margin-bottom: 80px;
	}

	.cuadro {
		width: 280px;
		height: 280px;
		margin-left: auto;
		margin-bottom: 15px;
		margin-right: auto;
		background-size: cover;
		background-size: contain;
		background-position: 50% 50%;
		background-repeat: no-repeat;

		@media (min-width: 768px) {
			width: 198px;
			height: 198px;
			margin-bottom: 30px;
		}
		@media (min-width: 992px) {
			width: 243px;
			height: 243px;
		}

		@media (min-width: 1200px) {
			width: 305px;
			height: 305px;
		}
		@media (min-width: 2400px) {
			width: 305px * 2;
			height: 305px * 2;
		}
	}
}
