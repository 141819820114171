section.formulario{
	background-image: url(../img/contacto/fondo_2.webp);
	background-color: rgba(0,0,0,.3);
	background-blend-mode: multiply;
	background-size: cover;
	background-position: 15% 50%;

	padding-top: 9px;
	padding-bottom: 22px;

	@media (min-width: 768px) {
		padding-top: 63px;
		padding-bottom: 55px;
		height: 448px;
		background-position: 50% 00%;
	}
	@media (min-width: 992px) {
		height: 448px;
		padding-top: 72px;
		background-position: 50% 10%;
	}
	@media (min-width: 1200px) {
		height: 555px;
		padding-top: 70px;
		background-position: 50% 10%;
	}
	@media (min-width: 2400px) {
		height: 1110px;
		padding-top: 140px;
	}



	#form_contacto{
		width: 275px;
		margin: 0 auto;
		@media (min-width: 360px) {
			width: 310px;
		}
		@media (min-width: 768px) {
			width: 100%;
		}
		@media (min-width: 992px) {
			width: 352px;
		}
		@media (min-width: 1200px) {
			width: 442px;
		}
		@media (min-width: 2400px) {
			width: 884px;
		}
	}
	label{
		color: $white-color;
		font-family: Gotham-Light;
		font-size: 9.91px;
		font-weight: normal;
		margin-bottom: 0px;
		letter-spacing: 0.225em;	
		width: 100%;
		text-align: center;
		margin-top: 25px;
		@media (min-width: 360px) {
			font-size: 11.16px;
			margin-top: 30px;
		}
		@media (min-width: 768px) {
			width: initial;
		}
	}

	form label{
		@media (min-width: 768px) {
			display: none;
		}
	}

	input[type=text],
	input[type=email]{
		height: 24px;
		background: transparent;
		border-radius: 0px;
		box-shadow: none;
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 1px solid $white-color;
		color: $white-color;
		font-family: Gotham-Light;
		font-size: 10px;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 1px;
		padding-top: 0px;
		letter-spacing: .225em;
		@media (min-width: 360px) {
			height: 25px;
			font-size: 12px;
		}
		@media (min-width: 768px) {
			height: 40px;
			margin-top: 26px;
			font-size: 12.39px;
		}
		@media (min-width: 1200px) {
			height: 40px;
			margin-top: 36px;
			font-size: 12.39px;
		}
		@media (min-width: 2400px) {
			height: 80px;
			margin-top: 72px;
			font-size: 12.39px*2;
		}

		&:hover,
		&:focus{
			box-shadow: none;
		}

		&::placeholder{
			opacity: 0;

			@media (min-width: 768px) {
				color: $white-color;
				opacity: 1;
				font-family: Gotham-Light;
				letter-spacing: 0.225em;
			}
		}
	}

	
	select{
		height: 24px;
		background: transparent;
		border-radius: 0px;
		box-shadow: none;
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 1px solid $white-color;
		color: $white-color;
		font-family: Gotham-Light;
		font-size: 10px;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 1px;
		padding-top: 0px;
		letter-spacing: .225em;
		@media (min-width: 360px) {
			height: 25px;
			font-size: 12px;
		}
		@media (min-width: 768px) {
			height: 40px;
			margin-top: 26px;
			font-size: 12.39px;
		}
		@media (min-width: 1200px) {
			height: 40px;
			margin-top: 36px;
			font-size: 12.39px;
		}
		@media (min-width: 2400px) {
			height: 80px;
			margin-top: 72px;
			font-size: 12.39px*2;
		}

		option{
			color: #000;
		}

		&:hover,
		&:focus{
			box-shadow: none;
		}

	}

	//aceptas terminas y condiciones
	.checkbox{
		text-align: center;
		label{
			font-size: 8px;
			margin-top: 0px;

			@media (min-width: 992px) {
				font-size: 9px;
			}
			@media (min-width: 1200px) {
				font-size: 11.15px;
			}
			@media (min-width: 2400px) {
				font-size: 22.30px;
			}
			input{
				margin-top: 0;

				@media (min-width: 2400px) {
					margin-top: 5px;
				}
			}
		}
	}

	.columna-izquierda{
		@media (min-width: 768px) {
			width: 306px;
		}
		@media (min-width: 992px) {
			width: 391px;
			margin: 0 auto;
		}
		@media (min-width: 1200px) {
			width: 480px;
		}
		@media (min-width: 2400px) {
			width: 960px;
		}
	}
}