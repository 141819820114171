.boton{
	width: 148px;
	height: 31px;
	background-color: transparent;
	font-size: 12.67px;
	line-height: 15px;
	font-family: Gotham-Light;
	color: $white-color;
	display: block;
	margin: 5px auto 0;
	border: 1px solid $white-color;
	border-radius: 0;
	@media (min-width: 360px){
		width: 166px;
		height: 34px;
		font-size: 14.27px;
		line-height: 17.13px;
		margin: 15px auto 0px;
	}
	@media (min-width: 768px){
		width: 148px;
		height: 31px;
		font-size: 12.67px;
		line-height: 15px;
		margin-top: 25px;
	}
	@media (min-width: 1200px){
		width: 185px;
		height: 38px;
		font-size: 15.84px;
		line-height: 19px;
		margin-top: 50px;
	}
	@media (min-width: 2400px){
		width: 370px;
		height: 76px;
		font-size: 31.68px;
		line-height: 38px;
		margin-top: 100px;
	}

	&:hover{
		background-color: $second-color-70;
		color: $white-color;
		border: none;
	}
}

.boton_home{
	width: 148px;
	height: 31px;
	background-color: $greay-l-l-color;
	font-size: 12.67px;
	line-height: 15px;
	font-family: Gotham-Light;
	color: $white-color;
	display: block;
	margin: 5px auto 0;
	border: 1px solid $white-color;
	border-radius: 0;

	background-color: $second-color-70;
	color: $white-color;
	border: none;
	
	@media (min-width: 360px){
		width: 166px;
		height: 34px;
		font-size: 14.27px;
		line-height: 17.13px;
		margin: 15px auto 0px;
	}
	@media (min-width: 768px){
		width: 148px;
		height: 31px;
		font-size: 12.67px;
		line-height: 15px;
		margin-top: 25px;
	}
	@media (min-width: 1200px){
		width: 185px;
		height: 38px;
		font-size: 15.84px;
		line-height: 19px;
		margin-top: 50px;
	}
	@media (min-width: 2400px){
		width: 370px;
		height: 76px;
		font-size: 31.68px;
		line-height: 38px;
		margin-top: 100px;
	}

	&:hover,
	&.active{
		background-color: $second-color-70;
		color: $white-color;
		border: none;
	}
}