.home {
	.seccion_1 {
		height: calc(100vh - 80px);
		min-height: 400px;
		max-height: 1024px;

		//Celualres y tablets
		@media (min-width: 414px) {
			//*1.30;
			height: calc(100vh - 80px * 1.3);
			min-height: 400px;
			max-height: 1024px;
		}
		@media (min-width: 768px) {
			//*2.4;
			height: calc(100vh - 80px * 2.4);
			min-height: 480px;
			max-height: 1024px;
		}

		@media (min-width: 992px) {
			//*.825;
			height: calc(100vh - 122px * 0.825);
			min-height: 600px;
			max-height: 1024px;
		}
		@media (min-width: 1200px) {
			//
			height: calc(100vh - 122px);
			min-height: 600px;
			max-height: 1024px;
		}
		@media (min-width: 2400px) {
			//*2;
			height: calc(100vh - 122px * 2);
			min-height: 600px * 2;
			max-height: 1024px * 2;
		}

		#carousel__home_1 {
			height: 100%;
			.carousel-inner {
				height: 100%;
				.item {
					height: 100%;

					.diapositiva_1 {
						background-color: black;
						height: 100%;
						background-size: cover;
						background-position: 50% 50%;
						display: flex;
						align-items: center;
						flex-grow: 0;

						position: relative;
						.contenedor_texto {
							width: 100%;
							position: relative;
							z-index: 2;

							&::before {
								content: "";
								width: 100%;
								height: 100%;
								background-color: #393d42;
								mix-blend-mode: multiply;
								transform: translateZ(0);
								position: absolute;
								top: 0;
								left: 0;
								opacity: 0.7;
							}

							.texto {
								// height: 100px;
								color: #fff;
								text-align: center;
								font-family: $Roboto-Bold;
								position: relative;
								width: 100%;
								height: auto;
								top: 0;
								left: 0;
								z-index: 2;
								letter-spacing: 0.025em;

								font-size: 18px;
								line-height: 20px;
								padding-top: 10px;
								padding-bottom: 10px;
								//Celualres y tablets
								@media (min-width: 414px) {
									//*1.30;
									font-size: 18px * 1.3;
									line-height: 20px * 1.3;
									padding-top: 10px * 1.3;
									padding-bottom: 10px * 1.3;
								}
								@media (min-width: 768px) {
									//*2.4;
									font-size: 18px * 2.4;
									line-height: 20px * 2.4;
									padding-top: 10px * 2.4;
									padding-bottom: 10px * 2.4;
								}

								@media (min-width: 992px) {
									//*.825;
									font-size: 60px * 0.825;
									line-height: 80px * 0.825;
									padding-top: 47px * 0.825;
									padding-bottom: 44px * 0.825;
								}
								@media (min-width: 1200px) {
									//
									font-size: 60px;
									line-height: 80px;
									padding-top: 47px;
									padding-bottom: 44px;
								}
								@media (min-width: 2400px) {
									//*2;
									font-size: 60px * 2;
									line-height: 80px * 2;
									padding-top: 47px * 2;
									padding-bottom: 44px * 2;
								}
							}
						}

						.contenedor_video {
							overflow: hidden;
							width: 100%;
							height: 100%;
							z-index: 1;
							position: absolute;
							left: 0;
							right: 0;

							video {
								height: 100%;
								width: 177.77777778vh; /* 100 * 16 / 9 */
								min-width: 100%;
								min-height: 56.25vw; /* 100 * 9 / 16 */

								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%); /* % of current element */
								//width: 100%;
								//height: 100%;
							}
						}
					}
				}
			}
			.carousel-control {
				.icon-left,
				.icon-right {
					position: absolute;
					top: 50%;
					z-index: 5;
					display: inline-block;
					transform: translateY(-50%);

					width: 20px;
					height: 20px;

					//Celualres y tablets
					@media (min-width: 414px) {
						//*1.30;
						width: 20px * 1.3;
						height: 20px * 1.3;
					}
					@media (min-width: 768px) {
						//*2.4;
						width: 20px * 2.4;
						height: 20px * 2.4;
					}

					@media (min-width: 992px) {
						//*.825;
						width: 46px * 0.825;
						height: 46px * 0.825;
					}
					@media (min-width: 1200px) {
						//
						width: 46px;
						height: 46px;
					}
					@media (min-width: 2400px) {
						//*2;
						width: 46px * 2;
						height: 46px * 2;
					}
				}
				.icon-left {
					left: 0;
				}
				.icon-right {
					right: 0;
				}
			}
		}
	} //.seccion_1
	.seccion_2 {
		h1 {
			font-family: $Roboto-Bold;
			line-height: 1.2;
			color: #393d42;
			text-align: center;

			font-size: 30px;
			padding-top: 20px;
			padding-bottom: 20px;

			//Celualres y tablets
			@media (min-width: 414px) {
				//*1.30;
				font-size: 30px * 1.3;
				padding-top: 20px * 1.3;
				padding-bottom: 20px * 1.3;
			}
			@media (min-width: 768px) {
				//*2.4;
				font-size: 30px * 2.4;
				padding-top: 20px * 2.4;
				padding-bottom: 20px * 2.4;
			}

			@media (min-width: 992px) {
				//*.825;
				font-size: 60px * 0.825;
				padding-top: 55px * 0.825;
				padding-bottom: 46px * 0.825;
			}
			@media (min-width: 1200px) {
				//
				font-size: 60px;
				padding-top: 55px;
				padding-bottom: 46px;
			}
			@media (min-width: 2400px) {
				//*2;
				font-size: 60px * 2;
				padding-top: 55px * 2;
				padding-bottom: 46px * 2;
			}

			span {
				color: $main-color;
			}
		}
		.texto {
			color: $black-color;
			line-height: 2;
			font-family: $Roboto-Light;
			letter-spacing: 0.025em;
			text-align: justify;

			font-size: 13px;

			//Celualres y tablets
			@media (min-width: 414px) {
				font-size: 13px * 1.3;
			}
			@media (min-width: 768px) {
				font-size: 13px * 2.4;
			}

			@media (min-width: 992px) {
				font-size: 18px * 0.825;
			}
			@media (min-width: 1200px) {
				font-size: 18px;
			}
			@media (min-width: 2400px) {
				font-size: 18px * 2;
			}

			span {
				color: $main-color;
				font-family: Roboto-Regular;
			}

			.bold {
				color: $main-color;
				font-family: $Roboto-Bold;
				font-size: 200%;
				text-align: center;
			}
		}
		.imagen_servicios {
			width: 100%;

			margin-top: 20px;
			margin-bottom: 20px;

			//Celualres y tablets
			@media (min-width: 414px) {
				//*1.30;
				margin-top: 20px * 1.3;
				margin-bottom: 20px * 1.3;
			}
			@media (min-width: 768px) {
				//*2.4;
				margin-top: 20px * 2.4;
				margin-bottom: 20px * 2.4;
			}

			@media (min-width: 992px) {
				//*.825;
				margin-top: 50px * 0.825;
				margin-bottom: 30px * 0.825;
			}
			@media (min-width: 1200px) {
				//
				margin-top: 50px;
				margin-bottom: 30px;
			}
			@media (min-width: 2400px) {
				//*2;
				margin-top: 50px * 2;
				margin-bottom: 30px * 2;
			}
		}
	} //.seccion_2
	.seccion_3 {
		margin-bottom: 20px;

		//Celualres y tablets
		@media (min-width: 414px) {
			margin-bottom: 20px * 1.3;
		}
		@media (min-width: 768px) {
			margin-bottom: 20px * 2.4;
		}

		@media (min-width: 992px) {
			margin-bottom: 30px * 0.825;
		}
		@media (min-width: 1200px) {
			margin-bottom: 30px;
		}
		@media (min-width: 2400px) {
			margin-bottom: 30px * 2;
		}
		.cabecera {
			background-image: url(/assets/img/home/nuestros_servicios.jpg);
			width: 100%;
			background-size: 100% auto;
			background-position: 50% 30%;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;

			height: 120px;
			margin-top: 30px;
			margin-bottom: 20px;

			//Celualres y tablets
			@media (min-width: 414px) {
				//*1.30;
				height: 120px * 1.3;
				margin-top: 30px * 1.3;
				margin-bottom: 20px * 1.3;
			}
			@media (min-width: 768px) {
				//*2.4;
				height: 120px * 2.4;
				margin-top: 30px * 2.4;
				margin-bottom: 20px * 2.4;
			}

			@media (min-width: 992px) {
				//*.825;
				height: 180px * 0.825;
				margin-top: 60px * 0.825;
				margin-bottom: 50px * 0.825;
			}
			@media (min-width: 1200px) {
				//
				height: 180px;
				margin-top: 60px;
				margin-bottom: 50px;
			}
			@media (min-width: 2400px) {
				//*2;
				height: 180px * 2;
				margin-top: 60px * 2;
				margin-bottom: 50px * 2;
			}

			&::before {
				content: "";
				width: 100%;
				height: 100%;
				background-color: $main-color;
				mix-blend-mode: multiply;
				transform: translateZ(0);
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0.6;
			}
			h1 {
				color: #fff;
				line-height: 1;
				text-align: center;
				font-family: $Roboto-Bold;
				position: relative;
				width: 100%;
				height: auto;
				top: 0;
				left: 0;
				z-index: 2;
				margin-top: 0;
				margin-bottom: 0;

				font-size: 30px;
				//Celualres y tablets
				@media (min-width: 414px) {
					font-size: 30px * 1.3;
				}
				@media (min-width: 768px) {
					font-size: 30px * 2.4;
				}

				@media (min-width: 992px) {
					font-size: 60px * 0.825;
				}
				@media (min-width: 1200px) {
					font-size: 60px;
				}
				@media (min-width: 2400px) {
					font-size: 60px * 2;
				}
			}
		}

		.contenedor_servicios {
			background-color: $main-color;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;

			padding-left: 15px;
			padding-right: 15px;

			//Celualres y tablets
			@media (min-width: 414px) {
				//*1.30;
			}
			@media (min-width: 768px) {
				//
				padding-left: 15px * 2.4;
				padding-right: 15px * 2.4;
			}

			@media (min-width: 992px) {
				//*.825;
				padding-left: 60px * 0.825;
				padding-right: 60px * 0.825;
			}
			@media (min-width: 1200px) {
				//
				padding-left: 60px;
				padding-right: 60px;
			}
			@media (min-width: 2400px) {
				//*2;
				padding-left: 60px * 2;
				padding-right: 60px * 2;
			}

			h2 {
				width: 100%;
				color: $white-color;
				font-family: $Roboto-Light;
				text-align: center;
				margin-top: 0;
				margin-bottom: 0;

				font-size: 25px;
				line-height: 30px;
				padding-top: 5px;
				padding-bottom: 5px;

				//Celualres y tablets
				@media (min-width: 414px) {
					//*1.30;
					font-size: 25px * 1.3;
					line-height: 30px * 1.3;
					padding-top: 5px * 1.3;
					padding-bottom: 5px * 1.3;
				}
				@media (min-width: 768px) {
					//*2.4;
					font-size: 25px * 2.4;
					line-height: 30px * 2.4;
					padding-top: 5px * 2.4;
					padding-bottom: 5px * 2.4;
				}

				@media (min-width: 992px) {
					//*.825;
					font-size: 40px * 0.825;
					line-height: 60px * 0.825;
					padding-top: 7px * 0.825;
					padding-bottom: 7px * 0.825;
				}
				@media (min-width: 1200px) {
					//
					font-size: 40px;
					line-height: 60px;
					padding-top: 7px;
					padding-bottom: 7px;
				}
				@media (min-width: 2400px) {
					//*2;
					font-size: 40px * 2;
					line-height: 60px * 2;
					padding-top: 7px * 2;
					padding-bottom: 7px * 2;
				}
			}
			.servicio {
				width: calc(100%);
				cursor: pointer;
				position: relative;
				height: 290px;
				margin-bottom: 15px;

				//Celualres y tablets
				@media (min-width: 414px) {
					//*1.30;
					height: 290px * 1.3;
					margin-bottom: 15px * 1.3;
				}
				@media (min-width: 768px) {
					//*2.4;
					height: 250px * 2.4;
					margin-bottom: 15px * 2.4;
				}
				@media (min-width: 992px) {
					//*.825;
					height: 400px * 0.825;
					margin-bottom: 0px * 0.825;
				}
				@media (min-width: 1200px) {
					//
					height: 400px;
					margin-bottom: 0px;
				}
				@media (min-width: 2400px) {
					//*2;
					height: 400px * 2;
					margin-bottom: 0px * 2;
				}

				.imagen {
					width: calc(100%);
					background-color: #fff;
					background-size: cover;
					background-position: 50% 50%;
					//height: 150px;
					height: calc(100%);
					margin-bottom: 15px;

					position: absolute;
					top: 0;
					left: 0;

					//Celualres y tablets
					@media (min-width: 414px) {
						//*1.30;
					}
					@media (min-width: 768px) {
						//*2.4;
					}

					@media (min-width: 992px) {
						//*.825;
					}
					@media (min-width: 1200px) {
						//
					}
					@media (min-width: 2400px) {
						//*2;
						//height: 200px * 2;
						//margin-bottom: 36px * 2;
					}
				}
				.descripcion {
					background-color: #fff;
					font-family: $Roboto-Regular;
					line-height: 1.2;
					color: #000;
					text-align: center;
					color: $second-color;
					display: flex;
					align-items: center;

					top: 10px;
					left: 10px;
					width: calc(100% - 20px);
					height: calc(100% - 20px);

					font-size: 13px;
					padding-left: 10px;
					padding-right: 10px;
					margin-bottom: 20px;

					position: absolute;

					opacity: 0;
					transition: opacity 1s;

					//Celualres y tablets
					@media (min-width: 414px) {
						//*1.30;
						top: 13px;
						left: 13px;
						width: calc(100% - 26px);
						height: calc(100% - 26px);

						font-size: 13px * 1.3;
						padding-left: 10px * 1.3;
						padding-right: 10px * 1.3;
						margin-bottom: 20px * 1.3;
					}
					@media (min-width: 768px) {
						//*2.4;
						top: 10px * 2.4;
						left: 10px * 2.4;
						width: calc(100% - (20px * 2.4));
						height: calc(100% - (20px * 2.4));

						font-size: 10px * 2.4;
						padding-left: 10px * 2.4;
						padding-right: 10px * 2.4;
						margin-bottom: 20px * 2.4;
					}

					@media (min-width: 992px) {
						//*.825;

						top: 15px;
						left: 15px;
						width: calc(100% - 30px);
						height: calc(100% - 30px);

						font-size: 16px * 0.825;
						padding-left: 15px * 0.825;
						padding-right: 15px * 0.825;
						margin-bottom: 0;
					}
					@media (min-width: 1200px) {
						//
						top: 15px;
						left: 15px;
						width: calc(100% - 30px);
						height: calc(100% - 30px);
						font-size: 16px;
						padding-left: 15px;
						padding-right: 15px;
					}
					@media (min-width: 2400px) {
						//*2;

						top: 30px;
						left: 30px;
						width: calc(100% - 60px);
						height: calc(100% - 60px);
						font-size: 16px * 2;
						padding-left: 15px * 2;
						padding-right: 15px * 2;
					}
					div {
						//text-align: left;
						width: 100%;
					}
					ul {
						padding-left: 1em;
						li {
							text-align: left;
						}
					}
				}

				&:hover {
					.descripcion {
						opacity: 0.9;
					}
				}
			}
			.contenedor_boton {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 0px;
				margin-bottom: 20px;

				//Celualres y tablets
				@media (min-width: 414px) {
					//*1.30;

					margin-bottom: 20px * 1.3;
				}
				@media (min-width: 768px) {
					//*2.4;

					margin-bottom: 20px * 2.4;
				}

				@media (min-width: 992px) {
					//*.825;
					margin-top: 20px * 0.825;
					margin-bottom: 20px * 0.825;
				}
				@media (min-width: 1200px) {
					//
					margin-top: 20px;
					margin-bottom: 20px;
				}
				@media (min-width: 2400px) {
					//*2;
					margin-top: 20px * 2;
					margin-bottom: 20px * 2;
				}

				.btn_ver_todos {
					display: block;
					background-color: $main-color;
					font-family: $Roboto-Regular;
					display: flex;
					justify-content: center;
					align-items: center;
					color: $white-color;
					text-decoration: none;
					width: 104px;
					height: 30px;
					border-radius: 14px;
					border: 2px solid #fff;
					font-size: 18px;

					//Celualres y tablets
					@media (min-width: 414px) {
						//*1.30;
					}
					@media (min-width: 768px) {
						//*2.4;
						width: 104px * 2.4;
						height: 30px * 2.4;
						border-radius: 14px * 2.4;
						font-size: 18px * 2.4;
						border: 4px solid #fff;
					}

					@media (min-width: 992px) {
						//*.825;
						width: 104px * 0.825;
						height: 30px * 0.825;
						border-radius: 14px * 0.825;
						font-size: 18px * 0.825;
						border: 2px solid #fff;
					}
					@media (min-width: 1200px) {
						//
						width: 104px;
						height: 30px;
						border-radius: 14px;
						border: 2px solid #fff;
						font-size: 18px;
					}
					@media (min-width: 2400px) {
						//*2;
						width: 104px * 2;
						height: 30px * 2;
						border-radius: 14px * 2;
						font-size: 18px * 2;
						border: 4px solid #fff;
					}
				}
			}
		}
	} //.seccion_3
	.seccion_4 {
		//background-color: $main-color;
		//background-image: url(/assets/img/home/seccion_4_fondo_1.jpg);
		background-size: cover;
		background-position: 50% 50%;
		position: relative;

		/*
		&::before {
			content: "";
			width: 100%;
			height: 100%;
			background-color: $second-color;
			mix-blend-mode: multiply;
			transform: translateZ(0);
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0.7;
		}
		*/

		.cabecera {
			background-image: url(/assets/img/home/seccion_4_cabecera_1.jpg);
			width: 100%;
			background-size: 100% auto;
			background-position: 50% 30%;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;

			height: 120px;
			margin-top: 30px;
			margin-bottom: 20px;

			//Celualres y tablets
			@media (min-width: 414px) {
				//*1.30;
				height: 120px * 1.3;
				margin-top: 30px * 1.3;
				margin-bottom: 20px * 1.3;
			}
			@media (min-width: 768px) {
				//*2.4;
				height: 120px * 2.4;
				margin-top: 30px * 2.4;
				margin-bottom: 20px * 2.4;
			}

			@media (min-width: 992px) {
				//*.825;
				height: 180px * 0.825;
				margin-top: 60px * 0.825;
				margin-bottom: 50px * 0.825;
			}
			@media (min-width: 1200px) {
				//
				height: 180px;
				margin-top: 60px;
				margin-bottom: 50px;
			}
			@media (min-width: 2400px) {
				//*2;
				height: 180px * 2;
				margin-top: 60px * 2;
				margin-bottom: 50px * 2;
			}

			&::before {
				content: "";
				width: 100%;
				height: 100%;
				background-color: $main-color;
				mix-blend-mode: multiply;
				transform: translateZ(0);
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0.6;
			}
			h1 {
				color: #fff;
				line-height: 1;
				text-align: center;
				font-family: $Roboto-Bold;
				position: relative;
				width: 100%;
				height: auto;
				top: 0;
				left: 0;
				z-index: 2;
				margin-top: 0;
				margin-bottom: 0;

				font-size: 30px;
				//Celualres y tablets
				@media (min-width: 414px) {
					font-size: 30px * 1.3;
				}
				@media (min-width: 768px) {
					font-size: 30px * 2.4;
				}

				@media (min-width: 992px) {
					font-size: 60px * 0.825;
				}
				@media (min-width: 1200px) {
					font-size: 60px;
				}
				@media (min-width: 2400px) {
					font-size: 60px * 2;
				}
			}
		}

		.contenedor_proyectos {
			background-color: $main-color;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;

			padding-left: 20px;
			padding-right: 20px;

			//Celualres y tablets
			@media (min-width: 414px) {
				//*1.30;
				padding-left: 20px * 1.3;
				padding-right: 20px * 1.3;
			}
			@media (min-width: 768px) {
				//*2.4;
				padding-left: 20px * 2.4;
				padding-right: 20px * 2.4;
			}

			@media (min-width: 992px) {
				//*.825;
				padding-left: 60px * 0.825;
				padding-right: 60px * 0.825;
			}
			@media (min-width: 1200px) {
				//
				padding-left: 60px;
				padding-right: 60px;
			}
			@media (min-width: 2400px) {
				//*2;
				padding-left: 60px * 2;
				padding-right: 60px * 2;
			}
			h2 {
				width: 100%;
				color: $white-color;
				font-family: $Roboto-Light;
				text-align: center;
				margin-top: 0;
				margin-bottom: 0;

				font-size: 25px;
				line-height: 30px;
				padding-top: 5px;
				padding-bottom: 5px;

				//Celualres y tablets
				@media (min-width: 414px) {
					//*1.30;
					font-size: 25px * 1.3;
					line-height: 30px * 1.3;
					padding-top: 5px * 1.3;
					padding-bottom: 5px * 1.3;
				}
				@media (min-width: 768px) {
					//*2.4;
					font-size: 25px * 2.4;
					line-height: 30px * 2.4;
					padding-top: 5px * 2.4;
					padding-bottom: 5px * 2.4;
				}

				@media (min-width: 992px) {
					//*.825;
					font-size: 40px * 0.825;
					line-height: 60px * 0.825;
					padding-top: 7px * 0.825;
					padding-bottom: 11px * 0.825;
				}
				@media (min-width: 1200px) {
					//
					font-size: 40px;
					line-height: 60px;
					padding-top: 7px;
					padding-bottom: 11px;
				}
				@media (min-width: 2400px) {
					//*2;
					font-size: 40px * 2;
					line-height: 60px * 2;
					padding-top: 7px * 2;
					padding-bottom: 11px * 2;
				}
			}
			.servicio {
				width: calc(100%);
				cursor: pointer;
				position: relative;
				height: 290px;
				margin-bottom: 15px;

				//Celualres y tablets
				@media (min-width: 414px) {
					//*1.30;
					height: 290px * 1.3;
					margin-bottom: 15px * 1.3;
				}
				@media (min-width: 768px) {
					//*2.4;
					height: 250px * 2.4;
					margin-bottom: 15px * 2.4;
				}
				@media (min-width: 992px) {
					//*.825;
					height: 400px * 0.825;
					margin-bottom: 0px * 0.825;
				}
				@media (min-width: 1200px) {
					//
					height: 400px;
					margin-bottom: 0px;
				}
				@media (min-width: 2400px) {
					//*2;
					height: 400px * 2;
					margin-bottom: 0px * 2;
				}

				.imagen {
					width: 100%;
					background-color: #fff;
					background-size: cover;
					background-position: 50% 50%;
					height: calc(100%);
					margin-bottom: 15px;

					position: absolute;
					top: 0;
					left: 0;

					//Celualres y tablets
					@media (min-width: 414px) {
						//*1.30;
						//height: 150px * 1.3;
					}
					@media (min-width: 768px) {
						//*2.4;
						//height: 292px;
						//margin-bottom: 15px * 2.4;
					}
					@media (min-width: 992px) {
						//height: 200px * 0.825;
						//margin-bottom: 0;
					}
					@media (min-width: 1200px) {
						//height: 200px;
					}
					@media (min-width: 2400px) {
						//height: 200px * 2;
					}
				}
				/*
				.descripcion {
					width: 100%;
					background-color: #fff;
					font-family: $Roboto-Regular;
					line-height: 1.2;
					color: #000;
					text-align: justify;
					color: $second-color;
					display: flex;
					align-items: center;
					height: 200px;
					font-size: 16px;
					padding-left: 20px;
					padding-right: 20px;
					border: 1px solid $main-color;
					@media (min-width: 992px) {
						//*.825;
						height: 200px * 0.825;
						font-size: 16px * 0.825;
						padding-left: 20px * 0.825;
						padding-right: 20px * 0.825;
						border: 1px solid $main-color;
					}
					@media (min-width: 1200px) {
						//
						height: 200px;
						font-size: 16px;
						padding-left: 20px;
						padding-right: 20px;
						border: 1px solid $main-color;
					}
					@media (min-width: 2400px) {
						//*2;
						height: 200px * 2;
						font-size: 16px * 2;
						padding-left: 20px * 2;
						padding-right: 20px * 2;
						border: 2px solid $main-color;
					}
				}
				*/
			}
			.contenedor_boton {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 0px;
				margin-bottom: 20px;

				//Celualres y tablets
				@media (min-width: 414px) {
					//*1.30;

					margin-bottom: 20px * 1.3;
				}
				@media (min-width: 768px) {
					//*2.4;

					margin-bottom: 20px * 2.4;
				}
				@media (min-width: 992px) {
					//*.825;
					margin-top: 60px * 0.825;
					margin-bottom: 60px * 0.825;
				}
				@media (min-width: 1200px) {
					//
					margin-top: 60px;
					margin-bottom: 60px;
				}
				@media (min-width: 2400px) {
					//*2;
					margin-top: 60px * 2;
					margin-bottom: 60px * 2;
				}

				.btn_ver_todos {
					display: block;
					background-color: $main-color;
					font-family: $Roboto-Regular;
					display: flex;
					justify-content: center;
					align-items: center;
					color: $white-color;
					text-decoration: none;
					width: 104px;
					height: 30px;
					border-radius: 14px;
					font-size: 18px;
					border: 2px solid #fff;

					//Celualres y tablets
					@media (min-width: 414px) {
						//*1.30;
					}
					@media (min-width: 768px) {
						//*2.4;
						width: 104px * 2.4;
						height: 30px * 2.4;
						border-radius: 14px * 2.4;
						font-size: 18px * 2.4;
						border: 4px solid #fff;
					}

					@media (min-width: 992px) {
						//*.825;
						width: 104px * 0.825;
						height: 30px * 0.825;
						border-radius: 14px * 0.825;
						font-size: 18px * 0.825;
						border: 2px solid #fff;
					}
					@media (min-width: 1200px) {
						//
						width: 104px;
						height: 30px;
						border-radius: 14px;
						font-size: 18px;
						border: 2px solid #fff;
					}
					@media (min-width: 2400px) {
						//*2;
						width: 104px * 2;
						height: 30px * 2;
						border-radius: 14px * 2;
						font-size: 18px * 2;
						border: 4px solid #fff;
					}
				}
			}
		}
	} //.seccion_4

	.seccion_5 {
		margin-top: 30px;

		//Celualres y tablets
		@media (min-width: 414px) {
			margin-top: 30px * 1.3;
		}
		@media (min-width: 768px) {
			margin-top: 30px * 2.4;
		}

		@media (min-width: 992px) {
			margin-top: 63px * 0.825;
		}
		@media (min-width: 1200px) {
			margin-top: 63px;
		}
		@media (min-width: 2400px) {
			margin-top: 63px * 2;
		}

		h1 {
			font-family: $Roboto-Black;
			line-height: 1.2;
			color: $second-color;
			width: 100%;
			margin-top: 0;
			text-align: center;

			font-size: 25px;
			padding-top: 5px;
			margin-bottom: 25px;
			border-bottom: 1px solid $main-color;

			//Celualres y tablets
			@media (min-width: 414px) {
				//*1.30;
				font-size: 25px * 1.3;
				padding-top: 5px * 1.3;
				margin-bottom: 25px * 1.3;
				border-bottom: 1px solid $main-color;
			}
			@media (min-width: 768px) {
				//*2.4;
				font-size: 25px * 2.4;
				padding-top: 5px * 2.4;
				margin-bottom: 25px * 2.4;
				border-bottom: 2px solid $main-color;
			}

			@media (min-width: 992px) {
				//*.825;
				font-size: 36px * 0.825;
				padding-top: 5px * 0.825;
				margin-bottom: 55px * 0.825;
				border-bottom: 1px solid $main-color;
			}
			@media (min-width: 1200px) {
				//
				font-size: 36px;
				padding-top: 5px;
				margin-bottom: 55px;
				border-bottom: 1px solid $main-color;
			}
			@media (min-width: 2400px) {
				//*2;
				font-size: 36px * 2;
				padding-top: 5px * 2;
				margin-bottom: 55px * 2;
				border-bottom: 2px solid $main-color;
			}
		}
		.contenedor_datos {
			display: flex;
			flex-wrap: wrap;
			width: 100%;

			.contenedor_dato {
				display: flex;
				flex-direction: row;
				margin-bottom: 20px;
				width: 45%;

				//Celualres y tablets
				@media (min-width: 414px) {
					//*1.30;
					margin-bottom: 20px * 1.3;
				}
				@media (min-width: 768px) {
					//*2.4;
					margin-bottom: 20px * 2.4;
				}

				@media (min-width: 992px) {
					width: 47%;
					//*.825;
					margin-bottom: 36px * 0.825;
				}
				@media (min-width: 1200px) {
					//
					margin-bottom: 36px;
				}
				@media (min-width: 2400px) {
					margin-bottom: 36px * 2;
				}

				&.contenedor_dato_largo {
					width: 55%;
					@media (min-width: 992px) {
						width: 53%;
					}
				}

				.icon {
					width: 30px;
					height: 30px;
					//Celualres y tablets
					@media (min-width: 414px) {
						//*1.30;
						width: 30px * 1.3;
						height: 30px * 1.3;
					}
					@media (min-width: 768px) {
						//*2.4;
						width: 30px * 2.4;
						height: 30px * 2.4;
					}

					@media (min-width: 992px) {
						//*.825;
						width: 51px * 0.825;
						height: 51px * 0.825;
					}
					@media (min-width: 1200px) {
						//
						width: 51px;
						height: 51px;
					}
					@media (min-width: 2400px) {
						//*2;
						width: 51px * 2;
						height: 51px * 2;
					}

					&.icon-telefono {
						background-image: url(/assets/img/home/icon-telefono.svg);
						background-size: 100% auto;
						background-repeat: no-repeat;
					}
					&.icon-mail {
						background-image: url(/assets/img/home/icon-mail.svg);
						background-size: 100% auto;
						background-repeat: no-repeat;
					}
					&.icon-ubicacion {
						background-image: url(/assets/img/home/icon-ubicacion.svg);
						background-size: 100% auto;
						background-repeat: no-repeat;
					}
					&.icon-horario {
						background-image: url(/assets/img/home/icon-horario.svg);
						background-size: 100% auto;
						background-repeat: no-repeat;
					}
				}

				.informacion {
					width: calc(100% - 30px);
					padding-left: 9px;

					//Celualres y tablets
					@media (min-width: 414px) {
						//*1.30;
						width: calc(100% - 30px * 1.3);
						padding-left: 9px * 1.3;
					}
					@media (min-width: 768px) {
						//*2.4;
						width: calc(100% - 30px * 2.4);
						padding-left: 9px * 2.4;
					}

					@media (min-width: 992px) {
						//*.825;
						width: calc(100% - 51px * 0.825);
						padding-left: 21px * 0.825;
					}
					@media (min-width: 1200px) {
						//
						width: calc(100% - 51px);
						padding-left: 21px;
					}
					@media (min-width: 2400px) {
						//*2;
						width: calc(100% - 51px * 2);
						padding-left: 21px * 2;
					}

					.nombre {
						font-family: $Roboto-Medium;
						font-size: 10px;
						line-height: 1.2;
						color: #000000;

						//Celualres y tablets
						@media (min-width: 414px) {
							font-size: 10px * 1.3;
						}
						@media (min-width: 768px) {
							font-size: 10px * 2.4;
						}

						@media (min-width: 992px) {
							font-size: 18px * 0.825;
						}
						@media (min-width: 1200px) {
							font-size: 18px;
						}
						@media (min-width: 2400px) {
							font-size: 18px * 2;
						}
					}

					.dato {
						font-family: $Roboto-Light;
						line-height: 1.2;
						color: #000;
						font-size: 10px;

						//Celualres y tablets
						@media (min-width: 414px) {
							font-size: 10px * 1.3;
						}
						@media (min-width: 768px) {
							font-size: 10px * 2.4;
						}

						@media (min-width: 992px) {
							font-size: 18px * 0.825;
						}
						@media (min-width: 1200px) {
							font-size: 18px;
						}
						@media (min-width: 2400px) {
							font-size: 18px * 2;
						}
						a {
							font-family: $Roboto-Light;
							color: #000;
						}
					}
				}
			}
		}
		.imagen_contacto {
			width: 100%;
			height: 200px;
			border: 1px solid #000;
			overflow: hidden;
			width: 100%;
			position: relative;

			video {
				width: 157%;
				height: auto;
				min-width: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%); /* % of current element */
				//width: 100%;
				//height: 100%;
			}

			@media (min-width: 414px) {
				height: 200px * 1.3;
			}

			@media (min-width: 768px) {
				//*2.4;
				border: 2px solid #000;
				height: 200px * 2.4;
			}

			@media (min-width: 992px) {
				//*.825;
				width: 457px * 0.825;
				height: 396px * 0.825;
				margin-left: 59px * 0.825;
				border: 1px solid #000;
			}
			@media (min-width: 1200px) {
				//
				width: 457px;
				height: 396px;
				margin-left: 59px;
				border: 1px solid #000;
			}
			@media (min-width: 2400px) {
				//*2;
				width: 457px * 2;
				height: 396px * 2;
				margin-left: 59px * 2;
				border: 2px solid #000;
			}
		}
	} //.seccion_5
}
