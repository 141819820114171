.seleccionar_idioma {
	//height: calc(100vh - 80px); //Desactivado al quitar navbar

	height: calc(100vh);
	min-height: 600px;

	//Celualres y tablets
	@media (min-width: 414px) {
		//*1.30;
		//Desactivado al quitar navbarheight: calc(100vh - 80px * 1.3);
		min-height: 600px * 1.3;
	}
	@media (min-width: 768px) {
		//Desactivado al quitar navbarheight: calc(100vh - 80px * 2.4);
		min-height: 600px * 2.4;
		//
	}

	@media (min-width: 992px) {
		//;
		//Desactivado al quitar navbarheight: calc(100vh - 122px * 0.825);
		min-height: 600px * 0.825;
	}
	@media (min-width: 1200px) {
		//Desactivado al quitar navbarheight: calc(100vh - 122px);
		min-height: 720px;
	}
	@media (min-width: 2400px) {
		//*2;
		//Desactivado al quitar navbarheight: calc(100vh - 122px * 2);
		min-height: 600px * 2;
	}
	.punto_central {
		//position: relative;
		width: 100%;

		@media (min-width: 992px) {
			height: 140px * 0.825;
		}
		@media (min-width: 1200px) {
			//
			height: 140px;
		}
		@media (min-width: 2400px) {
			height: 140px * 2;
		}
	}
	.contenedor_imagen {
		background-color: white;
		border-radius: 50%;
		width: 290px;
		height: 290px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 10px auto 20px;
		z-index: 10;
		@media (min-width: 768px) {
			width: 290px * 1.2;
			height: 290px * 1.2;
		}
		@media (min-width: 992px) {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: initial;

			//*.825;
			//max-width: initial;

			width: calc(36vw);
			height: calc(36vw);
			transform: translateY(calc(-50% + (70px * 0.825))) translateX(calc(-50% + ((33.3vw /*+ 30px*/) / 2)));
		}
		@media (min-width: 1200px) {
			//
			width: calc(36vw);
			height: calc(36vw);
			transform: translateY(calc(-50% + 70px)) translateX(calc(-50% + ((33.3vw /*+ 30px*/) / 2)));
		}
		@media (min-width: 2400px) {
			//*2;
			width: calc(36vw);
			height: calc(36vw);
			//transform: translateY(calc(-50% + (70px * 2))) translateX(calc(-50% + 15vw + 50px));
			transform: translateY(calc(-50% + (70px * 2))) translateX(calc(-50% + ((33.3vw /*+ 30px*/) / 2)));
		}
	}
	.logo_inicio {
		max-width: 70%;
		//margin: 5px auto 20px;
		display: block;
		//Celualres y tablets
		@media (min-width: 414px) {
			//*1.30;
			//	max-width: 130px * 1.3;
			//margin: 5px * 1.3 auto 20px * 1.3;
		}
		@media (min-width: 768px) {
			//*2.4;
			//	max-width: 130px * 2.4;
			//margin: 5px * 2.4 auto 20px * 2.4;
		}
		@media (min-width: 992px) {
			//*.825;
			//max-width: initial;
			width: 100%;
		}
		@media (min-width: 1200px) {
			//
		}
		@media (min-width: 2400px) {
			//*2;
		}
	}

	.seccion_principal {
		background-color: #000;
		height: 100%;
		background-image: url(/assets/img/seleccion_idioma/fondo.png);
		background-size: cover;
		background-position: 50% 100%;
		position: relative;

		.overlay {
			/*
			background-color: rgba($main-color, 0.7);
			mix-blend-mode: multiply;
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 1;
			*/
		}
		.container-fluid {
			position: relative;
			z-index: 2;

			@media (min-width: 992px) {
				//padding-top: calc((100vh - 390px * 0.825) / 2);
				padding-top: calc((100vh) / 2 - (70px * 0.825));
			}
			@media (min-width: 1200px) {
				padding-top: calc((100vh) / 2 - 70px);
			}
			@media (min-width: 2400px) {
				//padding-top: calc((100vh - 390px * 2) / 2);
				padding-top: calc((100vh) / 2 - (70px * 2));
			}

			h1 {
				color: #fff;
				text-align: center;
				font-family: $Roboto-Black;
				font-size: 23px;
				margin-top: 20px;
				margin-bottom: 20px;
				line-height: 1;
				color: white;
				background-color: $second-color;
				padding: 10px;

				@media (min-width: 414px) {
					//*1.30;
					font-size: 30px * 1.3;
					margin-top: 20px * 1.3;
					margin-bottom: 20px * 1.3;
				}
				@media (min-width: 768px) {
					//*2.4;
					font-size: 30px * 2.4;
					margin-top: 20px * 2.4;
					margin-bottom: 20px * 2.4;
				}

				@media (min-width: 992px) {
					//*.825;
					font-size: 35px * 0.825;
					padding-top: 44px * 0.825;
					padding-bottom: 44px * 0.825;
					margin: 0;
				}
				@media (min-width: 1200px) {
					font-size: 35px;
					padding-top: 44px;
					padding-bottom: 44px;
				}
				@media (min-width: 2400px) {
					//*2;
					font-size: 35px * 2;
					padding-top: 44px * 2;
					padding-bottom: 44px * 2;
				}
			}

			.selector {
				background-color: $second-color;
				margin-bottom: 20px;

				@media (min-width: 992px) {
					margin-bottom: 0px;
				}

				a {
					position: relative;
					display: block;
					text-decoration: none;
				}
			}

			.cuadro {
				bottom: 0px;
				width: 100%;

				@media (min-width: 992px) {
					position: absolute;
				}

				.contenido {
					background-color: $second-color;
					width: 100%;
					z-index: 3;
					position: relative;

					.lbl {
						display: flex;
						position: absolute;
						left: 0;
						right: 0;
						margin: auto;
						//background-color: rgba($main-color, 0.7);

						font-family: $Roboto-Regular;
						color: #fff;
						align-items: center;
						justify-content: center;

						font-size: 16px;
						width: 104px;
						height: 25px;
						bottom: 10px;

						//Celualres y tablets
						@media (min-width: 414px) {
							//*1.30;
							font-size: 16px * 1.3;
							width: 104px * 1.3;
							height: 25px * 1.3;
							bottom: 10px * 1.3;
						}
						@media (min-width: 768px) {
							//*2.4;
							font-size: 16px * 2.4;
							width: 104px * 2.4;
							height: 25px * 2.4;
							bottom: 10px;
						}

						@media (min-width: 992px) {
							//*.825;
							font-size: 23.33px * 0.825;
							width: 226px * 0.825;
							height: 52px * 0.825 * 1.5;
							bottom: initial;
						}
						@media (min-width: 1200px) {
							font-size: 35px;
							width: 226px;
							height: 52px * 1.5;
						}
						@media (min-width: 2400px) {
							//*2;
							font-size: 23.33px * 2;
							width: 226px * 2;
							height: 52px * 2 * 1.5;
						}
					}
				}

				.fondo {
					position: absolute;
					width: 100%;
					height: 100%;
					z-index: 1;
					background-color: rgba($main-color, 0.9);
				}
			}

			.recuadro {
				width: 100%;
				background-color: rgba($second-color, 0.7);
				color: #fff;
				font-family: $Roboto-Regular;
				display: flex;
				justify-content: center;
				align-items: center;

				font-size: 18px;
				height: 50px;
				margin-top: 30px;

				//Celualres y tablets
				@media (min-width: 414px) {
					//*1.30;
					font-size: 18px * 1.3;
					height: 50px * 1.3;
					margin-top: 30px * 1.3;
				}
				@media (min-width: 768px) {
					//*2.4;
					font-size: 18px * 2.4;
					height: 50px * 2.4;
					margin-top: 30px * 2.4;
				}

				@media (min-width: 992px) {
					//*.825;
					font-size: 24px * 0.825;
					height: 70px * 0.825;
					margin-top: 45px * 0.825;
				}
				@media (min-width: 1200px) {
					font-size: 24px;
					height: 70px;
					margin-top: 45px;
				}
				@media (min-width: 2400px) {
					//*2;
					font-size: 24px * 2;
					height: 70px * 2;
					margin-top: 45px * 2;
				}
			}
		}
	}
}
