.logo_boxnave{
	width: 100px;
	background-color: #fff;
	border-radius: 9px;
	padding: 7px;
	box-shadow: 0px 0px 5px #666;
	position: relative;
	top: -1px;
	margin-left: 3px;
	margin-right: 25px;
}

.desarrollado{
	color: white;
	margin-top: 10px;
	width: 100%;
	
	@media (min-width: 768px) {
		margin-top: 20px;
	}
	@media (min-width: 992px) {
		//position: absolute;
		text-align: right;
		//bottom: 20px;
		//right: 0;
		margin-top: 15px;
	}

	a{
		color: white;
		text-decoration: none;
		font-size: 12px;
		font-weight: 400;
		@media (min-width: 1200px) {
			font-size: 14px;
		}
	}
}