.servicios {
	//height: calc(100vh - 80px); //Desactivado al quitar navbar

	min-height: 600px;

	//Celualres y tablets
	@media (min-width: 414px) {
		//*1.30;
		//Desactivado al quitar navbarheight: calc(100vh - 80px * 1.3);
		min-height: 600px * 1.3;
	}
	@media (min-width: 768px) {
		//Desactivado al quitar navbarheight: calc(100vh - 80px * 2.4);
		min-height: 600px * 2.4;
		//
	}

	@media (min-width: 992px) {
		//;
		//Desactivado al quitar navbarheight: calc(100vh - 122px * 0.825);
		min-height: 600px * 0.825;
	}
	@media (min-width: 1200px) {
		//Desactivado al quitar navbarheight: calc(100vh - 122px);
		min-height: 720px;
	}
	@media (min-width: 2400px) {
		//*2;
		//Desactivado al quitar navbarheight: calc(100vh - 122px * 2);
		min-height: 600px * 2;
	}

	.seccion_principal {
		background-color: #000;
		height: 100%;
		background-image: url(/assets/img/servicios/fondo.png);
		background-size: cover;
		background-position: 50% 50%;
		position: relative;

		min-height: 400px;

		@media (min-width: 992px) {
			min-height: 800px * 0.825;
		}
		@media (min-width: 1200px) {
			//
			min-height: 800px;
		}
		@media (min-width: 2400px) {
			min-height: 800px * 2;
		}

		.overlay {
			/*
			background-color: rgba($main-color, 0.7);
			mix-blend-mode: multiply;
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 1;
			*/
		}
		.container {
			position: relative;
			z-index: 2;
			padding-bottom: 100px;

			a {
				display: block;
			}

			@media (min-width: 992px) {
				padding-bottom: 0px;
				padding-top: 100px * 0.825;
			}
			@media (min-width: 1200px) {
				//padding-top: calc((100vh - 390px) / 2);
				padding-top: 100px;
			}
			@media (min-width: 2400px) {
				padding-top: 100px * 2;
			}

			h1 {
				color: #fff;
				text-align: center;
				font-family: $Roboto-Black;

				font-size: 35px;
				margin-bottom: 30px;

				@media (min-width: 414px) {
					//*1.30;
					font-size: 35px * 1.3;
					margin-bottom: 30px * 1.3;
				}
				@media (min-width: 768px) {
					//*2.4;
					font-size: 35px * 2.4;
					margin-bottom: 30px * 2.4;
				}

				@media (min-width: 992px) {
					//*.825;
					font-size: 85px * 0.825;
					margin-bottom: 30px * 0.825;
				}
				@media (min-width: 1200px) {
					//
					font-size: 85px;
					margin-bottom: 30px;
				}
				@media (min-width: 2400px) {
					//*2;
					font-size: 85px * 2;
					margin-bottom: 30px * 2;
				}
			}

			h2 {
				color: #fff;
				text-align: center;
				font-family: $Roboto-Black;
				font-size: 23px;
				margin-top: 20px;
				margin-bottom: 20px;

				margin-left: auto;
				margin-right: auto;
				display: flex;

				color: white;
				background-color: $main-color;
				padding: 10px;

				align-items: center;
				justify-content: center;
				border-radius: 12px;

				@media (min-width: 414px) {
					//*1.30;
					font-size: 30px * 1.3;
					margin-top: 20px * 1.3;
					margin-bottom: 20px * 1.3;
				}
				@media (min-width: 768px) {
					//*2.4;
					font-size: 30px * 2.4;
					margin-top: 20px * 2.4;
					margin-bottom: 20px * 2.4;
				}

				@media (min-width: 992px) {
					//*.825;
					font-size: 35px * 0.825;
					margin-top: 0;
					margin-bottom: 44px * 0.825;

					width: 360px * 0.825;
					height: 80px * 0.825;
					border-radius: 20px * 0.825;
				}
				@media (min-width: 1200px) {
					font-size: 35px;
					//margin-top: 41px;
					margin-bottom: 44px;

					width: 360px;
					height: 80px;
					border-radius: 20px;
				}
				@media (min-width: 2400px) {
					//*2;
					font-size: 35px * 2;
					//margin-top: 41px * 2;
					margin-bottom: 44px * 2;

					width: 360px * 2;
					height: 80px * 2;
					border-radius: 20px * 2;
				}
			}

			.cuadro {
				position: relative;
				width: 141px;
				height: 141px;
				//margin-top: 26px;

				//Celualres y tablets
				@media (min-width: 414px) {
					//*1.30;
					width: 141px * 1.3;
					height: 141px * 1.3;
					//	margin-top: 26px * 1.3;
				}
				@media (min-width: 768px) {
					//*2.4;
					width: 141px * 2.4;
					height: 141px * 2.4;
					//	margin-top: 26px * 2.4;
				}

				@media (min-width: 992px) {
					//*.825;
					width: 287px * 0.825;
					height: 287px * 0.825;
					margin-bottom: 20px * 0.825;
				}
				@media (min-width: 1200px) {
					//
					width: 287px;
					height: 287px;
					margin-bottom: 20px;
				}
				@media (min-width: 2400px) {
					//*2;
					width: 287px * 2;
					height: 287px * 2;
					margin-bottom: 20px * 2;
				}

				&.cuadro_es {
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
				}
				&.cuadro_en {
					right: 0;
					left: 0;
					margin-left: auto;
					margin-right: auto;
				}

				.contenido {
					background-color: $second-color;
					width: 100%;
					height: 100%;
					z-index: 3;
					position: relative;
					background-position: 50% 50%;
					background-size: cover;
					border-radius: 50%;
				}
			}

			.recuadro {
				width: 100%;
				background-color: rgba($second-color, 0.7);
				color: #fff;
				font-family: $Roboto-Regular;
				display: flex;
				justify-content: center;
				align-items: center;

				font-size: 18px;
				height: 50px;
				margin-top: 30px;

				//Celualres y tablets
				@media (min-width: 414px) {
					//*1.30;
					font-size: 18px * 1.3;
					height: 50px * 1.3;
					margin-top: 30px * 1.3;
				}
				@media (min-width: 768px) {
					//*2.4;
					font-size: 18px * 2.4;
					height: 50px * 2.4;
					margin-top: 30px * 2.4;
				}

				@media (min-width: 992px) {
					//*.825;
					font-size: 24px * 0.825;
					height: 70px * 0.825;
					margin-top: 45px * 0.825;
				}
				@media (min-width: 1200px) {
					font-size: 24px;
					height: 70px;
					margin-top: 45px;
				}
				@media (min-width: 2400px) {
					//*2;
					font-size: 24px * 2;
					height: 70px * 2;
					margin-top: 45px * 2;
				}
			}
		}
	}
}
