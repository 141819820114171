footer {
	width: 100%;
	//height: 453px;

	background: rgb(0, 0, 0);
	/*
	background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 50%, rgba(65, 93, 54, 1) 100%);
	background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 50%, rgba(65, 93, 54, 1) 100%);
	background: linear-gradient(0deg, rgba(0, 0, 0, 1) 50%, rgba(65, 93, 54, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#415d36",GradientType=1);
	*/

	//background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 50%, rgba(65, 93, 54, 1) 100%);
	//background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 50%, rgba(65, 93, 54, 1) 100%);
	background: linear-gradient(0deg, #d5d5d5 50%, rgba(65, 93, 54, 1) 100%);
	background: linear-gradient(180deg, #fff 0%, #d5d5d5 40%, rgba(65, 93, 54, 1) 98%);
	//filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#415d36",GradientType=1);

	position: relative;

	padding-top: 20px;
	padding-bottom: 6px;
	//Celualres y tablets
	@media (min-width: 414px) {
		//*1.30;
	}
	@media (min-width: 768px) {
		//*2.4;
	}

	@media (min-width: 992px) {
		//*.825;
		padding-top: 64px * 0.825;
		padding-bottom: 6px * 0.825;
	}
	@media (min-width: 1200px) {
		//
		padding-top: 64px;
		padding-bottom: 6px;
	}
	@media (min-width: 2400px) {
		//*2;
		padding-top: 64px * 2;
		padding-bottom: 6px * 2;
	}

	.logo {
		background-image: url(/assets/img/seleccion_idioma/logo.svg);
		background-size: 100% auto;
		background-repeat: no-repeat;
		position: relative;

		width: 163px;
		height: 150px;
		margin-top: 0px;
		margin-bottom: 8px;

		margin-left: auto;
		margin-right: auto;

		//Celualres y tablets
		@media (min-width: 414px) {
			//*1.30;
			width: 163px * 1.3;
			height: 150px * 1.3;

			margin-bottom: 8px * 1.3;
		}
		@media (min-width: 768px) {
			//*2.4;
			width: 163px * 2.4;
			height: 150px * 2.4;

			margin-bottom: 8px * 2.4;
		}

		@media (min-width: 992px) {
			//*.825;
			left: -7px * 0.825;
			width: 163px * 0.825;
			height: 150px * 0.825;
			//margin-top: 45px * 0.825;
			margin-bottom: 8px * 0.825;

			margin-left: initial;
			margin-right: initial;
		}
		@media (min-width: 1200px) {
			//
			left: -7px;
			width: 163px;
			height: 150px;
			//margin-top: 45px;
			margin-bottom: 8px;
		}
		@media (min-width: 2400px) {
			//*2;
			left: -7px * 2;
			width: 163px * 2;
			height: 150px * 2;
			//margin-top: 45px * 2;
			margin-bottom: 8px * 2;
		}
	}
	.direccion {
		font-family: $Roboto-Light;
		line-height: 1.2;
		color: #fff;
		color: #000;

		font-size: 16px;
		margin-bottom: 30px;
		text-align: center;

		//Celualres y tablets
		@media (min-width: 414px) {
			//
			font-size: 16px * 1.3;
			margin-bottom: 30px * 1.3;
			text-align: center;
		}
		@media (min-width: 768px) {
			//*2.4;
			font-size: 16px * 2.4;
			margin-bottom: 30px * 2.4;
		}

		@media (min-width: 992px) {
			//*.825;
			text-align: left;
			font-size: 18px * 0.825;
			margin-bottom: 54px * 0.825;
		}
		@media (min-width: 1200px) {
			//
			font-size: 18px;
			margin-bottom: 54px;
		}
		@media (min-width: 2400px) {
			//*2;
			font-size: 18px * 2;
			margin-bottom: 54px * 2;
		}
	}

	h2 {
		margin-top: 0;
		font-family: $Roboto-Bold;
		line-height: 1.2;
		letter-spacing: -0.064em;
		color: #fff;
		color: #000;
		margin-bottom: 10px;
		font-size: 16px;
		text-align: center;
		margin-top: 20px;

		//Celualres y tablets
		@media (min-width: 414px) {
			//*1.30;
			margin-bottom: 10px * 1.3;
			font-size: 16px * 1.3;
			margin-top: 20px * 1.3;
		}
		@media (min-width: 768px) {
			//*2.4;
			margin-bottom: 10px * 2.4;
			font-size: 16px * 2.4;
			margin-top: 20px * 2.4;
		}

		@media (min-width: 992px) {
			//*.825;
			margin-top: 0px;
			text-align: left;
			margin-bottom: 26px * 0.825;
			font-size: 21px * 0.825;
		}
		@media (min-width: 1200px) {
			//
			margin-bottom: 26px;
			font-size: 21px;
		}
		@media (min-width: 2400px) {
			//*2;
			margin-bottom: 26px * 2;
			font-size: 21px * 2;
		}
	}

	.contenido_centrado {
		//text-align: left;
		text-align: center;
		//Celualres y tablets
		@media (min-width: 414px) {
			//*1.30;
		}
		@media (min-width: 768px) {
			//*2.4;
		}
		@media (min-width: 992px) {
			//*.825;
			h2 {
				text-align: center;
			}
		}
	}

	ul.footer_menu {
		list-style: none;
		padding: 0;
		text-align: center;

		@media (min-width: 992px) {
			text-align: left;
		}

		.footer_menu_item {
			//se replica estilo es .dato
			color: #fff;
			color: #000;
			font-family: $Roboto-Thin;
			font-size: 16px;
			line-height: 1.5;

			//Celualres y tablets
			@media (min-width: 414px) {
				font-size: 16px * 1.3;
			}
			@media (min-width: 768px) {
				font-size: 16px * 2.4;
			}

			@media (min-width: 992px) {
				//*.825;
				font-size: 18px * 0.825;
				line-height: 40px * 0.825;
			}
			@media (min-width: 1200px) {
				//
				font-size: 18px;
				line-height: 40px;
			}
			@media (min-width: 2400px) {
				//*2;
				font-size: 18px * 2;
				line-height: 40px * 2;
			}
		}
	}
	.dato {
		//se replica estilo es .footer_menu_item
		text-align: center;

		.number {
			display: inline-block;
			vertical-align: middle;
			color: #fff;
			color: #000;
			font-family: $Roboto-Light;
			text-decoration: none;

			margin-left: 15px;
			margin-right: 7px;
			font-size: 16px;
			line-height: 1.5;
			//Celualres y tablets
			@media (min-width: 414px) {
				font-size: 16px * 1.3;
			}
			@media (min-width: 768px) {
				font-size: 16px * 2.4;
			}

			@media (min-width: 992px) {
				//*.825;
				margin-left: 15px * 0.825;
				margin-right: 7px * 0.825;
				font-size: 18px * 0.825;
				line-height: 40px * 0.825;
			}
			@media (min-width: 1200px) {
				//
				margin-left: 15px;
				margin-right: 7px;
				font-size: 18px;
				line-height: 40px;
			}
			@media (min-width: 2400px) {
				//*2;
				margin-left: 15px * 2;
				margin-right: 7px * 2;
				font-size: 18px * 2;
				line-height: 40px * 2;
			}
		}
		a {
			font-size: 0; //Remove espacio es blanco
		}
	}

	.icon {
		background-repeat: no-repeat;
		display: inline-block;
		vertical-align: middle;

		width: 28px * 1.4;
		height: 28px * 1.4;
		margin: 0 7px * 1.4;

		@media (min-width: 992px) {
			//*.825;
			width: 28px * 0.825 * 1.4;
			height: 28px * 0.825 * 1.4;
			margin: 0 7px * 0.825 * 1.4;
		}
		@media (min-width: 1200px) {
			//
			width: 28px * 1.4;
			height: 28px * 1.4;
			margin: 0 7px * 1.4;
		}
		@media (min-width: 2400px) {
			//*2;
			width: 28px * 2 * 1.4;
			height: 28px * 2 * 1.4;
			margin: 0 7px * 2 * 1.4;
		}

		&.icon-telefono {
			background-image: url(/assets/img/footer/icon-telefono.svg);
		}
		&.icon-instagram {
			background-image: url(/assets/img/footer/icon-instagram.svg);
		}
		&.icon-facebook {
			background-image: url(/assets/img/footer/icon-facebook.svg);
		}
		&.icon-twitter {
			background-image: url(/assets/img/footer/icon-twitter.svg);
		}
		&.icon-linkedin {
			background-image: url(/assets/img/footer/icon-linkedin.svg);
		}
	}

	.copyright {
		font-family: $Roboto-Thin;
		color: #fff;
		//color: #000;
		font-size: 10px;
		line-height: 1.5;
		text-align: center;
		margin-top: 20px;
		//Celualres y tablets
		@media (min-width: 414px) {
			font-size: 10px * 1.3;
		}
		@media (min-width: 768px) {
			font-size: 10px * 2.4;
		}

		@media (min-width: 992px) {
			//*.825;
			margin-top: 0px;
			text-align: left;
			font-size: 14px * 0.825;
			line-height: 40px * 0.825;
		}
		@media (min-width: 1200px) {
			//
			font-size: 14px;
			line-height: 40px;
		}
		@media (min-width: 2400px) {
			//*2;
			font-size: 14px * 2;
			line-height: 40px * 2;
		}
	}
	.legal_links_footer {
		text-align: center;
		margin-top: 10px;
		margin-bottom: 10px;

		//Celualres y tablets
		@media (min-width: 414px) {
			//*1.30;
			margin-top: 10px * 1.3;
			margin-bottom: 10px * 1.3;
		}
		@media (min-width: 768px) {
			//*2.4;
			margin-top: 10px * 2.4;
			margin-bottom: 10px * 2.4;
		}

		@media (min-width: 992px) {
			//*.825;
			margin-top: 0px;
			margin-bottom: 0px;
			text-align: right;
		}
		a {
			font-family: $Roboto-Thin;
			color: #fff;
			//color: #000;
			line-height: 1.5;
			font-size: 10px;
			margin-left: 10px;

			//Celualres y tablets
			@media (min-width: 414px) {
				//*1.30;
				font-size: 10px * 1.3;
				margin-left: 10px * 1.3;
			}
			@media (min-width: 768px) {
				//*2.4;
				font-size: 10px * 2.4;
				margin-left: 10px * 2.4;
			}

			@media (min-width: 992px) {
				//*.825;
				font-size: 14px * 0.825;
				line-height: 40px * 0.825;
				margin-left: 40px * 0.825;
			}
			@media (min-width: 1200px) {
				//
				font-size: 14px;
				line-height: 40px;
				margin-left: 40px;
			}
			@media (min-width: 2400px) {
				//*2;
				font-size: 14px * 2;
				line-height: 40px * 2;
				margin-left: 40px * 2;
			}
		}
	}
}
